import i18n from "i18next";
import { BackendChargePoint, ChargePoint } from "@interface/chargersInterface";
import { BackendChargeStatus, ConnectorStatus } from "@interface/chargerStatusInterface";
import { BackendSchedule } from "@interface/chargerScheduleInterface";
import { decorateStatusWithSchedule } from "@model/scheduleModel";
import { getChargerImageByModel } from "@services/chargerDetailsService";

const DEFAULT_CONNECTOR_ID = "1";
export const CHARGE_POINT_MAIN_CONTROLLER = "0";

export const parseChargePoints = (
	BackendData: BackendChargePoint[],
	StatusData: BackendChargeStatus[],
	ScheduleData: BackendSchedule[]
): ChargePoint[] => {
	return BackendData.map((chargePoint: BackendChargePoint) => {
		const status = StatusData.find((el: BackendChargeStatus) => el.chargerId === chargePoint.externalId);
		const statusWithSchedule = status ? decorateStatusWithSchedule(ScheduleData, status, chargePoint.externalId) : undefined;

		return parseChargePoint(chargePoint, statusWithSchedule);
	});
};

export const parseChargePoint = (chargePoint: BackendChargePoint, status?: BackendChargeStatus): ChargePoint => {
	const mainConnector = status?.connectorStatuses?.find(
		(connector) => connector.connectorId === DEFAULT_CONNECTOR_ID
	);
	const connectors = status?.connectorStatuses
		? status.connectorStatuses.filter((connector) => connector.connectorId !== CHARGE_POINT_MAIN_CONTROLLER).map((connector, index) => ({
			...connector,
			label: i18n.t("chargerDetails.socketConnectorId", {
				connectorId: index + 1
			})
		}))
		: [];

	return {
		id: chargePoint.id,
		externalId: chargePoint.externalId,
		brand: chargePoint.brand,
		model: chargePoint.model,
		maxChargingPowerKw: chargePoint.maxChargingPowerKw,
		status: chargePoint.status,
		chargerGroup: chargePoint.chargerGroup,
		groupLabel: chargePoint.chargerGroup ? chargePoint.chargerGroup.name : i18n.t("groups.ungrouped"),
		connected: status?.connected || false,
		connectors,
		connectorStatus: mainConnector?.status || ConnectorStatus.UNAVAILABLE,
		ownership: chargePoint.ownership,
		image: getChargerImageByModel(chargePoint.brand, chargePoint.model),
		address: chargePoint.address
	};
};
