import { UserStatus } from "@services/statusService";
import { Group } from "@interface/groupInterface";

export interface BackendUser {
	id: string;
	firstName: string;
	lastName: string;
	status: UserStatus;
	email: string;
	creationDate: string;
	userGroup: Group | null;
}

export interface User {
	id: string;
	firstName: string;
	lastName: string;
	fullName: string;
	status: UserStatus;
	email: string;
	creationDate: string;
	groupLabel: string;
	group: Group | null;
}

export interface UserPayload {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	userGroup: Group | null;
}

export interface UserPayloadWrapper {
	userPayload: UserPayload;
	currentUserId?: string;
}

export interface UserCharger {
	id?: string;
	externalId: string;
	customerExternalId: string;
	fullName: string;
	email: string;
	role: UserChargerRole;
}

export interface UserChargerUpdatePayload {
	id?: string;
	externalId: string;
	customerExternalId: string;
	fullName: string;
	email: string;
	role: string;
}

export interface UserChargerCreatePayload {
	externalId: string;
	customerExternalId: string;
	fullName: string;
	email: string;
}

export enum UserChargerRole {
	OWNER = "OWNER",
	USER = "USER"
}
