
import { FC } from "react";
import { Loader } from "@app/components/Loader/Loader";
import styles from "../../Table.module.scss";

export const TableLoader: FC = () => {
	return (
		<div className={styles.loadingTableContainer}>
			<Loader />
		</div>
	);
};
