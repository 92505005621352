import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@components/Modal/Modal";
import styles from "./ConfirmationModal.module.scss";
import { Button } from "@components/Button/Button";

interface ConfirmationModalProps {
	confirmationMessage?: string;
	action?: (event) => void;
	isOpen: boolean;
	onClose: () => void;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (
	{
		confirmationMessage,
		action,
		isOpen,
		onClose
	}
) => {

	const { t } = useTranslation();

	const actionWrapper = (event) => {
		if (action) {
			action(event);
		}

		onClose();
	};

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			Header={t("common.confirm")}
			Content={
				<div data-testid="confirmation-modal" className={styles.confirmMessage}>
					{confirmationMessage || t("common.sure")}

					<footer className={styles.buttons}>
						<Button color="primary" buttonType="solid" onClick={actionWrapper} data-testid="confirm-button">
							{t("common.confirm")}
						</Button>

						<Button color="default" buttonType="solid" onClick={onClose} data-testid="cancel-button">
							{t("common.cancel")}
						</Button>
					</footer>
				</div>
			} />
	);
};
