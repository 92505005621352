import { FC } from "react";
import { Link } from "react-router-dom";
import { MetersComponent, MetersComponentProps } from "@pages/ChargerDetailsPage/components/ChargerDetails/MetersComponent";
import styles from "./ChargingCardDetails.module.scss";

export enum ChargingCardDetailsTileType {
	PARAMETER = "label",
	TOGGLE = "toggle",
	CHARGED_METER = "chargedMeter",
	LINK = "link",
}

interface ChargingCardDetailsTileProps {
	type: ChargingCardDetailsTileType;
	label: string;
	value?: string | MetersComponentProps;
}

export const ChargingCardDetailsTile: FC<ChargingCardDetailsTileProps> = ({ type, label, value }) => {
	const renderTileByType = (_type: ChargingCardDetailsTileType) => {
		switch (_type) {
			case ChargingCardDetailsTileType.CHARGED_METER:
				return renderChargedMeterTile(value as MetersComponentProps);
			case ChargingCardDetailsTileType.LINK:
				return renderLinkTile();
			case ChargingCardDetailsTileType.PARAMETER:
			default:
				return renderParameterTile();
		}
	};

	const renderParameterTile = () => {
		return (
			<>
				<div className={styles.label}>{label}</div>
				<div className={styles.value}>{value}</div>
			</>
		);
	};

	const renderChargedMeterTile = (_value: MetersComponentProps) => {
		return (
			<>
				<div className={styles.label}>{label}</div>
				<MetersComponent chargerId={_value?.chargerId} />
			</>
		);
	};

	const renderLinkTile = () => {
		return (
			<>
				<div className={styles.label}>{label}</div>
				<div className={styles.value}>
					<Link to="#">{value}</Link>
				</div>
			</>
		);
	};

	return (
		<div className={styles.tile} data-testid={"charging-card-details-tile"}>
			{renderTileByType(type)}
		</div>
	);
};
