import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { accountApi } from "@api/accountApi";
import NotificationService from "@services/notificationService";
import { ConfirmAccountForm } from "@pages/ConfirmAccountPage/ConfirmAccountForm";
import { ConfirmAccountFormSuccessful } from "@pages/ConfirmAccountPage/ConfirmAccountSuccessful";
import { Loader } from "@components/Loader/Loader";
import { Logo } from "@components/Icons/Icons";
import styles from "./ConfirmAccount.module.scss";

export const ConfirmAccountPage = () => {
	const { t } = useTranslation();
	const params: { activationToken } = useParams();
	const [validated, setValidated] = useState<boolean>(false);
	const { mutate: checkToken, error: checkTokenError, isLoading: isTokenLoading } = useMutation(accountApi.checkActivationToken);
	const { mutate: activateAccount, isLoading: isActiveAccountLoading } = useMutation(accountApi.activate(params.activationToken), {
		onSuccess: () => {
			NotificationService.displaySuccess(t("confirmationPage.accountConfirmedSucess"));
			setValidated(true);
		},
		onError: () => {
			NotificationService.displayError(t("confirmationPage.confirationError"));
		}
	});

	const onSubmit = ({ email, password }) => {
		activateAccount({
			email, password
		});
	};

	useEffect(() => {
		checkToken(params.activationToken);
	}, []);

	if (isTokenLoading) {
		return <div className={styles.container}>
			<Loader />
		</div>;
	}

	if (checkTokenError) {
		return <div className={styles.container} data-testid="token-error-container">
			<Logo />
			<div className={styles.header}>
				<h2>{t("confirmationPage.linkInvalid")}</h2>
			</div>
		</div>;
	}

	return (
		<div className={styles.container}>
			{
				isActiveAccountLoading &&
				<Loader absolute />
			}
			<Logo />
			{!validated && <ConfirmAccountForm onSubmit={onSubmit} isLoading={isActiveAccountLoading} />}
			{validated && <ConfirmAccountFormSuccessful />}
		</div>
	);
};
