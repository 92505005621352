import React, { FC } from "react";
import styles from "./DownloadFileButton.module.scss";
import { DownloadIcon } from "@components/Icons/Icons";

interface DownloadButtonProps {
	label: string;
	endpoint: string;
}

export const DownloadFileButton: FC<DownloadButtonProps> = (
	{
		label,
		endpoint
	}
) => {

	const url = process.env.REACT_APP_GATEWAY_BASE_URL + endpoint;

	return <a data-testid="download-link" className={styles.marginRight} href={url} rel="noreferrer">
		<DownloadIcon className={styles.downloadIcon} />
		{label}
	</a>;
};
