import { RootState } from "@app/store";
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
	user: string | null;
};

const _setUser: CaseReducer<State, PayloadAction<State>> = (state, action) => {
	state.user = action.payload.user;
};
const _clearUser: CaseReducer<State> = (state) => {
	state.user = null;
};

export const slice = createSlice({
	name: "userReducer",
	initialState: {
		user: null
	} as State,
	reducers: {
		setUser: _setUser,
		clearUser: _clearUser
	}
});

export const { setUser, clearUser } = slice.actions;

export const getUser = (state: RootState) => state.user.user;

export default slice.reducer;
