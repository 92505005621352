import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { accountApi } from "@app/api/accountApi";
import { Loader } from "@app/components/Loader/Loader";
import NotificationService from "@app/services/notificationService";
import { clearUser, setUser } from "@reducers/userReducer";
import { Button } from "@components/Button/Button";
import { Checkbox } from "@components/Form/Checkbox/Checkbox";
import { TextField } from "@components/Form/TextField/TextField";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { Logo } from "@components/Icons/Icons";
import styles from "./LoginPage.module.scss";

const schema = yup
	.object({
		username: yup.string().required(),
		password: yup.string().required()
	})
	.required();

export const LoginPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();

	const { mutate, isLoading } = useMutation(accountApi.login, {
		onSuccess: (data, credential) => {
			dispatch(
				setUser({
					user: credential.username
				})
			);
			history.push(Route.CHARGERS);
		},
		onError: () => {
			NotificationService.displayError(t("messages.loginError"));
		}
	});

	const { control, handleSubmit } = useForm({
		defaultValues: {
			username: "",
			password: "",
			remember: false
		},
		resolver: yupResolver(schema)
	});

	useEffect(() => {
		dispatch(clearUser());
	}, []);

	const onSubmit = ({ username, password }) => {
		mutate({ username, password });
	};

	return (
		<div className={styles.container}>
			<Logo />
			<form onSubmit={handleSubmit(onSubmit)} data-testid="login-form">
				<div className={styles.loginContainer}>
					{isLoading && <Loader absolute />}

					<header className={styles.header}>
						<h2>
							<strong>{t("loginPage.header")}</strong>
						</h2>
					</header>

					<div className={styles.inputRow}>
						<Controller
							name="username"
							control={control}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<TextField
									label={t("loginPage.username")}
									placeholder={t("loginPage.username")}
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									error={error}
									autoFocus
								/>
							)}
						/>
					</div>
					<div className={styles.inputRow}>
						<Controller
							name="password"
							control={control}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<TextField
									type="password"
									label={t("loginPage.password")}
									placeholder={t("loginPage.password")}
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									error={error}
								/>
							)}
						/>
					</div>
					<div>
						<Controller
							name="remember"
							control={control}
							render={({ field: { onChange, onBlur, value } }) => (
								<Checkbox
									label={t("loginPage.rememberMe")}
									onChange={onChange}
									onBlur={onBlur}
									checked={value}
								/>
							)}
						/>
					</div>
					<footer className={styles.footer}>
						<Link to={Route.PASSWORD_RESET}>{t("loginPage.passwordForgotten")}?</Link>
						<Button color="primary" buttonType="solid" disabled={isLoading}>
							{t("loginPage.logIn")}
						</Button>
					</footer>
				</div>
			</form>
		</div>
	);
};
