import React, { FC, MouseEventHandler, ReactNode, useState } from "react";
import cs from "classnames";
import { Link } from "react-router-dom";
import styles from "./Button.module.scss";
import { useModal } from "@components/Modal/Modal";
import { ConfirmationModal } from "@components/ConfirmationModal/ConfirmationModal";

type ButtonColor = "default" | "primary" | "secondary" | "danger";

export interface ButtonProps {
	Icon?: FC;
	children: ReactNode;
	color?: ButtonColor;
	buttonType?: "solid" | "outline";
	to?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	confirmationMessage?: string;
}

export const Button: FC<ButtonProps> = (
	{
		Icon,
		color = "default",
		buttonType = "solid",
		to,
		children,
		onClick = undefined,
		disabled = false,
		confirmationMessage,
		...rest
	}) => {

	const { openModal, onClose: closeModal, ...modalProps } = useModal();
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

	const openConfirmationModal = () => {
		setIsConfirmationModalOpen(true);
		openModal();
	};

	const closeConfirmationModal = () => {
		setIsConfirmationModalOpen(false);
		closeModal();
	};

	const onClickAction = confirmationMessage ? openConfirmationModal : onClick;

	if (to) {
		return (
			<Link to={to} {...rest}>
				<button className={cs(styles.btn, styles[color], styles[buttonType])}>
					{Icon}
					{children}
				</button>
			</Link>
		);
	}
	return (
		<>
			<button className={cs(styles.btn, styles[color], styles[buttonType])} onClick={onClickAction} {...rest}
					disabled={disabled}>
				{Icon}
				{children}
			</button>

			{isConfirmationModalOpen
				&& <ConfirmationModal {...modalProps} confirmationMessage={confirmationMessage}
									onClose={closeConfirmationModal}
									action={(event) => onClick && onClick(event.target)} />}
		</>
	);
};
