import { GroupRow, SingleRow } from "./TableRows";
import { FC, PropsWithChildren, ReactElement, ReactNode, useEffect, useMemo } from "react";
import {
	Row,
	SortingRule,
	TableOptions,
	useExpanded,
	useFilters,
	useGlobalFilter,
	useGroupBy,
	usePagination,
	useSortBy,
	useTable
} from "react-table";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { URL_SEARCH_PARAMS } from "@interface/urlSearchInterface";
import { Container } from "../Container/Container";
import { DefaultFilter } from "./components/DefaultFilter/DefaultFilter";
import { GlobalFilter } from "./components/GlobalFilter/GlobalFilter";
import { Pagination } from "./components/Pagination/Pagination";
import { Sort } from "./components/Sort/Sort";
import { defaultExpanded, getFilters, onSortChange, parseUrlFilters } from "./utils";
import styles from "./Table.module.scss";

interface TableProps<T extends Record<string, unknown>> extends TableOptions<T> {
	filters?: string[];
	groupBy?: string;
	ExpandComponent?: FC<Row<T>>;
	CustomRowRenderer?: FC<{
		page: Row<T>[];
		prepareRow: (row: Row<T>) => void;
		sortBy: SortingRule<T>[];
	}>;
	HeaderComponent?: ReactNode;
	showGlobalSearch?: boolean;
	filtersStyles?: string;
	globalFilterLabel?: string;
	hiddenColumns?: string[];
	noInternalPadding?: boolean;
	fixedPageSize?: number;
	blockPageSizeChanging?: boolean;
	disableUrlHandling?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Table<T extends Record<any, any>>(props: PropsWithChildren<TableProps<T>>): ReactElement {
	const {
		columns,
		data,
		filters,
		groupBy,
		CustomRowRenderer,
		showGlobalSearch = true,
		filtersStyles = styles.filters,
		HeaderComponent,
		globalFilterLabel,
		hiddenColumns,
		noInternalPadding,
		fixedPageSize,
		blockPageSizeChanging,
		disableUrlHandling
	} = props;

	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const INITIAL_PAGE_SIZE = fixedPageSize || 10;

	const defaultColumn = useMemo(
		() => ({
			Filter: DefaultFilter,
			Sort: Sort
		}),
		[]
	);
	const defaultData = useMemo(() => data ? data : [], [data]);
	const dataTotal = useMemo(() => data ? data.length : 0, [data]);

	const _defaultExpanded = useMemo(defaultExpanded(groupBy, data), [data, groupBy]);

	const initialState = useMemo(() => {

		const searchParams = new URLSearchParams(location.search);

		const parsedFilters = parseUrlFilters(filters, searchParams);

		const parsedSortBy = searchParams?.get(URL_SEARCH_PARAMS.SORT_BY);
		const parsedSortDir = searchParams?.get(URL_SEARCH_PARAMS.SORT_DIR);
		const parsedGlobalSearch = searchParams?.get(URL_SEARCH_PARAMS.GLOBAL_SEARCH) || "";
		const parsedPageIndex = searchParams?.get(URL_SEARCH_PARAMS.PAGE_INDEX);
		const parsedPageSize = searchParams?.get(URL_SEARCH_PARAMS.PAGE_SIZE);
		const collapsed = searchParams?.get(URL_SEARCH_PARAMS.COLLAPSED);

		const expanded = collapsed ?
			collapsed.split(",").reduce<Record<string, boolean>>((obj, key) => ({ ...obj, [key]: false }), _defaultExpanded) :
			_defaultExpanded;

		return {
			groupBy: groupBy ? [groupBy] : [],
			expanded,
			globalFilter: parsedGlobalSearch,
			filters: parsedFilters || [],
			sortBy: parsedSortBy ? [{ id: parsedSortBy, desc: parsedSortDir === URL_SEARCH_PARAMS.SORT_DIR_DESC }] : [],
			pageIndex: parsedPageIndex ? Number(parsedPageIndex) : 0,
			pageSize: parsedPageSize ? Number(parsedPageSize) : INITIAL_PAGE_SIZE,
			hiddenColumns: hiddenColumns || []
		};
	}, []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		columns: _columns,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		rows,

		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		toggleGroupBy
	} = useTable<T>(
		{
			columns,
			data: defaultData,
			autoResetExpanded: false,
			autoResetFilters: false,
			autoResetGlobalFilter: false,
			autoResetHiddenColumns: false,
			autoResetPage: !disableUrlHandling,
			autoResetSortBy: false,
			defaultColumn,
			disableMultiSort: true,
			initialState
		},
		useFilters,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useExpanded,
		usePagination
	);

	const { pageIndex, pageSize, globalFilter, sortBy, groupBy: tableGroupBy } = state;
	const _filters = useMemo(getFilters(filters, _columns), [filters, _columns]);

	useEffect(() => gotoPage(0), [sortBy]);
	useEffect(onSortChange(groupBy, sortBy, _columns, tableGroupBy, toggleGroupBy), [sortBy, groupBy, data]);
	useEffect(() => {

		if (disableUrlHandling) {
			return;
		}

		const currentUrlParams = new URLSearchParams(location.search);
		const newUrl = new URLSearchParams();
		const collapsed = Object.keys(_defaultExpanded).filter(collapsedKey => {
			return !state.expanded[collapsedKey];
		});

		state.filters.forEach((filter) => {
			newUrl.set(`filters[${filter.id}]`, filter.value);
		});

		state.sortBy.forEach((sort) => {
			newUrl.set(URL_SEARCH_PARAMS.SORT_BY, sort.id);
			newUrl.set(URL_SEARCH_PARAMS.SORT_DIR, sort.desc ? URL_SEARCH_PARAMS.SORT_DIR_DESC : URL_SEARCH_PARAMS.SORT_DIR_ASC);
		});

		if (state.globalFilter) {
			newUrl.set(URL_SEARCH_PARAMS.GLOBAL_SEARCH, state.globalFilter);
		}

		if (state.pageIndex) {
			newUrl.set(URL_SEARCH_PARAMS.PAGE_INDEX, String(state.pageIndex));
		}

		if (state.pageSize !== INITIAL_PAGE_SIZE) {
			newUrl.set(URL_SEARCH_PARAMS.PAGE_SIZE, String(state.pageSize));
		}

		if (collapsed.length !== 0) {
			newUrl.set(URL_SEARCH_PARAMS.COLLAPSED, collapsed.join(","));
		}

		if (currentUrlParams.toString() !== newUrl.toString()) {
			history.push(`${window.location.pathname}?${newUrl.toString()}`);
		}

		if (currentUrlParams.get(URL_SEARCH_PARAMS.SCROLL_POS)) {
			setTimeout(() => {
				window.scrollTo(0, Number(currentUrlParams.get(URL_SEARCH_PARAMS.SCROLL_POS)));
			}, 1);
		}
	}, [state]);

	return (
		<div>
			<Container noInternalPadding={noInternalPadding}>
				<div className={styles.filtersContainer}>
					<div className={filtersStyles}>
						<div data-testid="table-filters">
							{_filters?.map((f, index) => (
								<div key={index}>{f?.render("Filter")}</div>
							))}
						</div>
						{showGlobalSearch && (
							<div className={styles.globalFilter}>
								<GlobalFilter
									preGlobalFilteredRows={preGlobalFilteredRows}
									globalFilter={globalFilter}
									setGlobalFilter={setGlobalFilter}
									globalFilterLabel={globalFilterLabel}
								/>
							</div>
						)}
					</div>
					{HeaderComponent}
				</div>
			</Container>
			<Container noInternalPadding={noInternalPadding}>
				<table {...getTableProps()} className={styles.table} data-testid="table">
					<thead>
						{headerGroups.map((headerGroup, index) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={index}>
								{headerGroup.headers
									.filter((column) => column.id !== groupBy)
									.map((column, i) =>
										column.isGrouped ? null : (
											<th {...column.getHeaderProps(column.getSortByToggleProps())}
												data-testid="header-column" key={"header-column-" + i}>
												<div>
													<div>
														<strong>{column.render("Header")}</strong>
													</div>
													<div className={styles.sortIcons}>{column.render("Sort")}</div>
												</div>
											</th>
										)
									)}
							</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()} data-testid="table-body">
						{CustomRowRenderer && CustomRowRenderer({ page, prepareRow, sortBy })}
						{!CustomRowRenderer && (
							<>
								{page.map((row, index) => {
									prepareRow(row);
									const { id, cells, isGrouped } = row;
									if (isGrouped) {
										return (
											<GroupRow
												key={id}
												row={row}
												cells={cells}
												sortBy={sortBy}
												rowIndex={index}
												groupBy={groupBy}
											/>
										);
									}

									return (
										<SingleRow<T>
											row={row}
											key={id}
											cells={cells}
											sortBy={sortBy}
											groupBy={groupBy}
											rowIndex={index}
										/>
									);
								})}
							</>
						)}
					</tbody>
				</table>
				{(!data || data.length === 0) && <div className={styles.noDataContainer}>{t("common.noData")}</div>}
			</Container>
			<Pagination
				{...{
					canPreviousPage,
					canNextPage,
					pageOptions,
					pageCount,
					gotoPage,
					nextPage,
					previousPage,
					setPageSize,
					pageIndex,
					pageSize,
					total: dataTotal,
					rows,
					noInternalPadding,
					blockPageSizeChanging
				}}
			/>
		</div>
	);
}
