import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import appReducer from "./reducers/appReducer";
import chargerPointsReducer from "./reducers/chargerPointsReducer";
import { persistReducer, persistStore, createMigrate, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { migrations } from "./reducers/migrations";

export const rootReducer = combineReducers({
	user: userReducer,
	app: appReducer,
	chargerPoints: chargerPointsReducer
});

const persistConfig = {
	key: "root",
	version: 2,
	migrate: createMigrate(migrations, { debug: false }),
	storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
