import { Select, SelectProps } from "antd";
import { useMemo } from "react";
import styles from "./Multiselect.module.scss";
import inputStyles from "@components/Form/TextField/TextField.module.scss";

const { Option } = Select;

export interface MultiselectOptionProps {
	value: string;
	label: string;
	key?: string;
}

interface MultiselectProps extends SelectProps<string[]> {
	values: MultiselectOptionProps[];
	options: MultiselectOptionProps[];
	label?: string
}

export const Multiselect = ({
	values,
	options,
	label: inputLabel,
	...restProps
}: MultiselectProps) => {

	const filteredOptions = useMemo(() => {
		return options
			.filter(({ value }) => values.findIndex(v => v.value === value) === -1);
	}, [options, values]);

	const filteredValues = useMemo(() => {
		return values.map(({ value }) => value);
	}, [values]);

	return (
		<div>
			{
				inputLabel &&
				<div className={inputStyles.label}>
					{inputLabel}
				</div>
			}
			<Select
				className={styles.root}
				mode="multiple"
				optionFilterProp="label"
				value={filteredValues}
				autoClearSearchValue={true}
				labelInValue={false}
				virtual={false}
				{...restProps}
			>
				{filteredOptions.map(({ label, value }) => (
					<Option key={value} value={value} label={label} data-testid="multiselect-option">
						{label}
					</Option>
				))}
			</Select>
		</div >

	);
};
