import moment from "moment";
import {
	BackendSchedule,
	ConnectorSchedule,
	SchedulePayload,
	SchedulePreferenceType
} from "@interface/chargerScheduleInterface";
import { BackendChargeStatus, ConnectorStatuses } from "@interface/chargerStatusInterface";
import { ScheduleForm } from "@modals/ScheduleChargingModal/scheduleChargingModalInterface";

export const parseSchedule = (backendSchedule: BackendSchedule): ConnectorSchedule => {
	return {
		chargerId: backendSchedule.chargerId,
		connectorId: `${backendSchedule.connectorId}`,
		startTime: backendSchedule.startTime,
		endTime: backendSchedule.endTime,
		enabled: backendSchedule.enabled
	};
};

export const buildSaveSchedulePayload = (scheduleForm: ScheduleForm): SchedulePayload => {
	return {
		chargerId: scheduleForm.chargerId,
		connectorId: Number(scheduleForm.connectorId),
		schedulePreferenceType: SchedulePreferenceType.DAILY,
		startTime: (moment(scheduleForm.startTime).format("HH:mm")),
		endTime: (moment(scheduleForm.endTime).format("HH:mm")),
		enabled: scheduleForm.enabled
	};
};

export const decorateStatusWithSchedule = (scheduleData: BackendSchedule[], status: BackendChargeStatus, chargerId): BackendChargeStatus => {
	return {
		...status,
		connectorStatuses: status?.connectorStatuses?.map((item: ConnectorStatuses) => {
			return {
				...item,
				schedule: getScheduleByConnectorId(scheduleData, item.connectorId, chargerId)
			};
		})
	};
};

export const getScheduleByConnectorId = (scheduleData: BackendSchedule[], connectorId: string, chargerId: string): ConnectorSchedule | undefined => {
	const foundSchedule = scheduleData.find((schedule) =>
		schedule.connectorId === Number(connectorId) && schedule.chargerId === chargerId
	);

	return foundSchedule ? parseSchedule(foundSchedule) : undefined;
};
