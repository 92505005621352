import { chargerGroupsApi } from "@app/api/chargerGroupsApi";
import { idTagsGroupsApi } from "@app/api/idTagsGroupsApi";
import { BackendGroup, Group, GroupsModalProps, GroupTypes } from "@app/interface/groupInterface";
import { PenIcon } from "@components/Icons/Icons";
import { useModal } from "@components/Modal/Modal";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Select } from "../Form/Select/Select";
import styles from "./GroupSelect.module.scss";

interface GroupSelectProps {
	type: GroupTypes
	value: Group | null;
	onChange: (group: Group| null) => void;
	GroupsManagementComponent: FC<GroupsModalProps>;
	isLoading?: boolean;
}

export const GroupSelect: FC<GroupSelectProps> = (
	{
		type,
		value,
		onChange,
		isLoading,
		GroupsManagementComponent
	}
) => {
	const { t } = useTranslation();
	const { openModal, ...groupModalProps } = useModal();
	const getRequestUrl = () => ({
		[GroupTypes.CHARGER]: chargerGroupsApi.getGroups,
		[GroupTypes.RFID]: idTagsGroupsApi.getGroups
	}[type]);

	const { data: groups, isLoading: isGroupsLoading } = useQuery<BackendGroup[]>(
		["groups", type],
		getRequestUrl()
	);

	const _isLoading = isGroupsLoading || isLoading;

	const handleChange = useCallback((option) => {
		if (option.value !== "") {
			onChange({ id: option.value, name: option.label });
		} else {
			onChange(null);
		}
	}, []);

	const parsedSelectOptions = useMemo(() => {
		if (!groups) {
			return [];
		}
		return groups.map((option) => ({
			value: option.id,
			label: option.name
		}));
	}, [groups]);

	return (
		<>
			<Select
				value={value?.name}
				placeholder={t("groups.ungrouped")}
				onChange={handleChange}
				options={parsedSelectOptions}
				isLoading={_isLoading}
				Footer={
					<div className={styles.button} onClick={openModal} data-testid="manage-button">
						<div>
							<PenIcon width={15} height={15} />
						</div>
						<div>{t("groups.manageGroups")}</div>
					</div>
				}
			/>
			<GroupsManagementComponent {...groupModalProps}/>
		</>
	);
};
