import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CellProps, ColumnInstance } from "react-table";
import ReactTooltip from "react-tooltip";
import { idTagsApi } from "@api/idTagsApi";
import { IdTag } from "@interface/idTagsInterface";
import { IdTagsGroupManagementModal } from "@modals/GroupManagementModal/IdTagsGroupManagementModal";
import { parseIdTagsCards } from "@app/model/idTagsModel";
import { Button } from "@components/Button/Button";
import { Container } from "@components/Container/Container";
import { CircleIcon, PlusIcon } from "@components/Icons/Icons";
import { useModal } from "@components/Modal/Modal";
import { DataError } from "@components/Table/components/DataError/DataError";
import { Legend } from "@components/Table/components/Legend/Legend";
import { SelectFilter } from "@components/Table/components/SelectFilter/SelectFilter";
import { Table } from "@components/Table/Table";
import { capitalizeFirstLetter, nameSort } from "@components/Table/utils";
import NotificationService from "@services/notificationService";
import { NameCell, StatusCell } from "./ChargingCardsTableCells";
import { AddCardModal } from "@modals/AddCardModal/AddCardModal";
import StatusService from "@services/statusService";
import { TableLoader } from "@app/components/Table/components/TableLoader/TableLoader";
import styles from "./ChargingCardsTable.module.scss";

export const ChargingCardsTable = () => {
	const { t } = useTranslation();
	const { openModal: openGroupsModal, ...groupModalProps } = useModal();
	const { openModal: openNewCardModal, ...newCardModalProps } = useModal();

	const { data, isLoading, isError, refetch } = useQuery("idtags", idTagsApi.getIdTags, {
		select: (_data) => parseIdTagsCards(_data),
		retry: false,
		onError: () => {
			NotificationService.displayError(t("messages.serverConnectionError"));
		}
	});
	const _nameSort = useMemo(nameSort, []);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	if (isLoading) {
		return (
			<TableLoader />
		);
	}

	if (isError) {
		return <DataError refetch={refetch} />;
	}

	return (
		<div>
			<Table<IdTag>
				filtersStyles={styles.customFilters}
				data={data || []}
				globalFilterLabel={t("idtagsTable.searchPlaceholder")}
				HeaderComponent={
					<div className={styles.headerRight}>
						<div className={styles.newGroupContainer}>
							<Button color="primary" buttonType="outline" onClick={openGroupsModal} >
								{t("idtagsTable.groups")}
							</Button>
						</div>
						<div className={styles.newCardContainer}>
							<Button color="primary" onClick={openNewCardModal} data-testid="add-card-button">
								<PlusIcon />
								{t("idtagsTable.addChargingCard")}
							</Button>
						</div>
					</div>
				}
				columns={[
					{
						Header: <Trans t={t}>groups.group</Trans>,
						accessor: "groupLabel",
						filter: "exact",
						Filter: SelectFilter,
						Cell: ({ value }) => value
					},
					{
						Header: <Trans t={t}>idtagsTable.chargingCard</Trans>,
						accessor: "externalId",
						filter: "includes",
						sortType: _nameSort,
						Cell: ({
							row: {
								original: { externalId }
							}
						}: CellProps<IdTag>) => (<NameCell externalId={externalId} />)
					},
					{
						id: "token",
						accessor: "token",
						filter: "includes"
					},
					{
						Header: <Trans t={t}>idtagsTable.state</Trans>,
						accessor: "status",
						filter: "exact",
						Filter: ({ column }: { column: ColumnInstance }) => {
							return (
								<SelectFilter
									column={{ ...column, Header: <Trans t={t}>idtagsTable.status</Trans> }}
									labelParser={capitalizeFirstLetter}
								/>
							);
						},
						Cell: ({ cell: { value } }: CellProps<IdTag>) => <StatusCell status={StatusService.getChargingCardStatus(value)} />
					}
				]}
				filters={["groupLabel", "status"]}
				groupBy="groupLabel"
				hiddenColumns={["token"]}
			/>
			<Container>
				<Legend
					elements={[
						{ Component: <CircleIcon className={styles.primaryIcon} />, label: t("idtags.active") },
						{ Component: <CircleIcon className={styles.dangerIcon} />, label: t("idtags.deactivated") }
					]}
				/>
			</Container>
			<AddCardModal {...newCardModalProps} />
			<IdTagsGroupManagementModal {...groupModalProps} />
		</div>
	);
};
