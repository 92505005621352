import { ConnectorStatus } from "./chargerStatusInterface";
import { BackendGroup, Group } from "./groupInterface";
import { ConnectorSchedule } from "@interface/chargerScheduleInterface";

export interface BackendChargePoint {
	id: string;
	brand: string;
	model: string;
	maxChargingPowerKw: number;
	powerExchangeArea: string;
	externalId: string;
	type: string;
	ownership: ChargePointOwnership;
	tenant: string;
	smartChargingProgrammeEnrolled: boolean;
	idtags: IDTag[];
	authListVersion: number;
	maxChargingRateLimit: number;
	minChargingRateLimit: number;
	chargingRateLimit: number;
	chargingRateUnitType: string;
	currentType: CurrentType;
	useLocalLoadBalancing: boolean;
	status: ChargePointStatus;
	notes: string | null;
	address?: string | null;
	chargerGroup: BackendGroup | null;
}

export interface ChargePoint {
	id: string;
	externalId: string;
	brand: string;
	model: string;
	maxChargingPowerKw: number;
	connected: boolean;
	chargerGroup: Group | null;
	groupLabel: string | null;
	connectors: Connector[];
	connectorStatus: ConnectorStatus;
	status: ChargePointStatus;
	ownership: ChargePointOwnership;
	image: string;
	address?: string | null;
}

interface IDTag {
	token: string;
	externalId: string;
}

export enum CurrentType {
	AC = "AC",
	DC = "DC",
}

export enum ChargePointStatus {
	OPERATIONAL = "OPERATIONAL",
}

export enum ChargePointOwnership {
	PRIVATE = "PRIVATE",
	SEMI_PRIVATE = "SEMI_PRIVATE",
}

export interface OwnershipPayload {
	ownership: ChargePointOwnership;
}

export interface Connector {
	connectorId: string;
	status: ConnectorStatus;
	errorCode: string;
	timestamp: string;
	schedule?: ConnectorSchedule;
	label: string;
}

export interface Measurement {
	chargerId: string;
	connectorId: number;
	meterValues: BackendMeterValues | UnusedBackendMeterValues;
}

export interface BackendMeterValues {
	EnergyActiveImportRegisterOutlet: BackendMeterValueData;
	PowerActiveImportOutlet: BackendMeterValueData;
}

export interface UnusedBackendMeterValues {
	[UnknownReading: string]: BackendMeterValueData;
}

export interface BackendMeterValueData {
	context: string;
	format: string;
	location: string;
	measurand: string;
	timestamp: string;
	unit: string;
	value: string;
	transactionId?: number;
}

export interface ChargerAddressForm {
	address?: string;
}
