import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import styles from "./NotAuthorised.module.scss";
import { Logo } from "@components/Icons/Icons";

export const NotAuthorised: FC = () => {
	const { t } = useTranslation();

	return (
		<div
			className={styles.unauthorizedContainer}
			data-testid="not-authorised-container"
		>
			<Logo />
			<div className={styles.header}>
				<h2>{t("loginPage.notAuthorised")}</h2>
			</div>
			<div className={styles.link}>
				<Link to={Route.LOGIN}>{t("loginPage.logIn")}</Link>
			</div>
		</div>
	);
};
