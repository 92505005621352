import { Group } from "./groupInterface";

export enum IdTagStatus {
	ACTIVE = "ACTIVE",
	DEACTIVATED = "DEACTIVATED",
}

export interface BackendIdTag {
	token: string;
	externalId: string;
	idtagGroup: Group | null;
	status: IdTagStatus;
}

export interface IdTag {
	token: string;
	externalId: string;
	status: IdTagStatus;
	group: Group | null;
	groupLabel: string;
	totalCharged: number;
}

export interface IdTagPayloadWrapper {
	idTagPayload: IdTagPayload;
	currentExternalId?: string;
}
export interface IdTagPayload {
	externalId: string;
	token: string;
	status: string;
	idtagGroup: Group | null;
}
