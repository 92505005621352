import React, { FC, useState } from "react";
import { Dropdown } from "@components/Dropdown/Dropdown";
import { DownloadFileButton } from "@components/DownloadFileButton/DownloadFileButton";
import { useTranslation } from "react-i18next";
import styles from "./DownloadReportDropdown.module.scss";
import { DownloadIcon } from "@components/Icons/Icons";
import { OnOutsideClick } from "@components/OnOutsideClick/OnOutsideClick";

export enum ReportType {
	PDF = "PDF", CSV = "CSV"
}

interface DownloadReportDropdownProps {
	label?: string;
	endpoint: string;
	reportTypes: ReportType[];
}

export const DownloadReportDropdown: FC<DownloadReportDropdownProps> = (
	{
		label,
		endpoint,
		reportTypes
	}
) => {

	const { t } = useTranslation();

	const [isOpen, setOpen] = useState<boolean>(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className={styles.inline} data-testid="download-report-dropdown">
			<OnOutsideClick onOutsideClick={handleClose} active={isOpen} className={styles.container}>
				<div onClick={handleOpen}>
					<strong>{label ? label : <DownloadIcon />}</strong>

					{isOpen && (
						<Dropdown>
							{reportTypes.map((reportType) => (
								<li key={reportType} className={styles.reportOption}>
									<DownloadFileButton label={t(`report.${reportType}`)}
														endpoint={`${endpoint}&reportType=${reportType}`} />
								</li>
							))}
						</Dropdown>
					)}
				</div>
			</OnOutsideClick>
		</div>);
};
