import { FC } from "react";
import { idTagsGroupsApi } from "@app/api/idTagsGroupsApi";
import { GroupsModalProps, GroupTypes } from "@app/interface/groupInterface";
import { GroupManagementModal } from "./GroupManagementModal";

export const IdTagsGroupManagementModal: FC<GroupsModalProps> = ({ isOpen, onClose }) => {
	return (
		<GroupManagementModal
			isOpen={isOpen}
			onClose={onClose}
			groupKey={GroupTypes.RFID}
			getGroups={idTagsGroupsApi.getGroups}
			updateGroup={idTagsGroupsApi.updateGroups}
			removeGroup={idTagsGroupsApi.removeGroups}
			addGroup={idTagsGroupsApi.addGroups}
		/>
	);
};
