import { FC, ReactChild, ReactElement, useCallback, useMemo, useState } from "react";
import cs from "classnames";
import { CaretDownIcon } from "@app/components/Icons/Icons";
import { Loader } from "@app/components/Loader/Loader";
import { OnOutsideClick } from "@app/components/OnOutsideClick/OnOutsideClick";
import styles from "./Select.module.scss";
import { FieldError } from "react-hook-form";
import { ErrorLabel } from "@components/Form/ErrorLabel/ErrorLabel";
export interface SelectProps {
	label?: ReactChild;
	value?: string;
	labelParser?: (value: string) => string;
	onChange: (option) => void;
	placeholder?: string | Element;
	options: Array<{ value: string; label: string }>;
	theme?: "light" | "dark";
	isLoading?: boolean;
	Footer?: ReactElement;
	error?: FieldError;
}

export const Select: FC<SelectProps> = ({ label, value, onChange, options, placeholder, isLoading, Footer, theme,
											labelParser = (value) => value, error
}) => {
	const [isOpen, setOpen] = useState<boolean>(false);

	const toggleOpen = useCallback(() => {
		setOpen(!isOpen);
	}, [isOpen]);

	const handleClick = useCallback(
		(option) => () => {
			setOpen(false);
			onChange(option);
		},
		[]
	);

	const _options = useMemo(() => {
		if (placeholder) {
			return [
				{
					value: "",
					label: placeholder
				},
				...options
			];
		} else {
			return options;
		}
	}, [options, placeholder]);

	return (
		<OnOutsideClick className={styles.fullWidth} onOutsideClick={() => setOpen(false)} active={isOpen}>
			<div
				className={cs(styles.container, {
					[styles.open]: isOpen,
					[styles.error]: !!error
				})}
			>
				{label && <label>{label}</label>}
				<div className={styles.selectContainer}>
					<div
						className={cs(styles.select, {
							[styles.darkSelect]: theme === "dark"
						})}
						onClick={toggleOpen}
						data-testid="select-input"
					>
						<div data-testid="select-value">{labelParser(value || "") || placeholder}</div>
						<CaretDownIcon />
						{isLoading && (
							<div className={styles.loaderContainer}>
								<Loader />
							</div>
						)}
					</div>
					{isOpen && (
						<div className={styles.list}>
							<ul data-testid="select-list">
								{_options.map((option, index) => (
									<li key={index} onClick={handleClick(option)}>
										{option.label}
									</li>
								))}
							</ul>
							{Footer}
						</div>
					)}
				</div>

				{error && <ErrorLabel error={error.message} />}
			</div>
		</OnOutsideClick>
	);
};
