import { FC } from "react";
import { GroupsModalProps, GroupTypes } from "@app/interface/groupInterface";
import { GroupManagementModal } from "./GroupManagementModal";
import { usersGroupsApi } from "@api/usersGroupsApi";

export const UsersGroupManagementModal: FC<GroupsModalProps> = ({ isOpen, onClose }) => {
	return (
		<GroupManagementModal
			isOpen={isOpen}
			onClose={onClose}
			groupKey={GroupTypes.USER}
			getGroups={usersGroupsApi.getGroups}
			updateGroup={usersGroupsApi.updateGroups}
			removeGroup={usersGroupsApi.removeGroups}
			addGroup={usersGroupsApi.addGroups}
		/>
	);
};
