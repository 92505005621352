import React, { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { chargePointsApi } from "@api/chargePointsApi";
import { ChargePoint } from "@interface/chargersInterface";
import NotificationService from "@services/notificationService";
import { GroupSelect } from "@components/GroupSelect/GroupSelect";
import { SocketSelector } from "@app/components/SocketSelector/SocketSelector";
import { GroupTypes } from "@app/interface/groupInterface";
import { ChargersGroupManagementModal } from "@modals/GroupManagementModal/ChargersGroupManagementModal";
import styles from "./ChargerSummary.module.scss";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@components/Form/TextField/TextField";
import { EditActionIcons } from "@components/EditActionIcons/EditActionIcons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18n from "i18next";

interface ChargerInfoProps {
	charger: ChargePoint;
	activeConnectorId?: string;
	handleConnectorSocketClick: (connectorId: string) => void;
}

const chargerAddressSchema = yup
	.object({
		address: yup.string()
			.max(128, () => i18n.t("chargerDetails.addressMaxLengthError"))
	});

export const ChargerInfo: FC<ChargerInfoProps> = ({ charger, activeConnectorId, handleConnectorSocketClick }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const updateChargersData = (updatedChargerPoint: ChargePoint) => {
		queryClient.setQueryData<ChargePoint[] | undefined>("chargePoints", (data) => {
			if (data) {
				return data.map((chargerPoint) => {
					if (chargerPoint.id === charger.id) {
						return updatedChargerPoint;
					}
					return chargerPoint;
				});
			}
			return data;
		});
	};

	const { mutateAsync: assignGroup, isLoading: assignGroupLoading } = useMutation(
		chargePointsApi.assignChargePointGroup,
		{
			onSuccess: (updatedChargerPoint) => {
				NotificationService.displaySuccess(t("messages.chargerGroupUpdated"));
				queryClient.setQueryData(["activeCharger", charger.externalId], () => updatedChargerPoint);
				updateChargersData(updatedChargerPoint);
			}
		}
	);

	const { mutateAsync: unassignGroup } = useMutation(chargePointsApi.unassignChargePointGroup, {
		onSuccess: (updatedChargerPoint) => {
			NotificationService.displaySuccess(t("messages.chargerGroupUnassigned"));
			queryClient.setQueryData(["activeCharger", charger.externalId], () => updatedChargerPoint);
			updateChargersData(updatedChargerPoint);
		}
	});

	const onGroupChange = (el) => {
		if (el === null) {
			unassignGroup({ chargerId: charger.id });
		} else {
			assignGroup({ chargerId: charger.id, chargerGroupId: el.id });
		}
	};

	const { control, reset: resetAddressForm, handleSubmit } = useForm({
		defaultValues: { address: charger.address || "" },
		resolver: yupResolver(chargerAddressSchema)
	});

	const submitAddress = (form) => {
		chargePointsApi.setChargePointAddress({ chargerId: charger.externalId, payload: form }).then((updatedChargerPoint) => {
			resetAddressForm(form);
			queryClient.setQueryData(["activeCharger", charger.externalId], () => updatedChargerPoint);
			NotificationService.displaySuccess(t("common.successSaveMessage"));
		});
	};

	return (
		<div className={styles.info}>
			<div className={styles.title}>
				<div className={styles.label}>{charger.externalId}</div>
				<SocketSelector
					activeConnectorId={activeConnectorId}
					connectors={charger.connectors}
					setActiveConnectorId={handleConnectorSocketClick}
				/>
			</div>
			<div className={styles.chargerGroup}>
				<div className={styles.label}>{t("groups.chargerGroup")}:</div>
				<div>
					<GroupSelect
						type={GroupTypes.CHARGER}
						value={charger.chargerGroup}
						onChange={onGroupChange}
						isLoading={assignGroupLoading}
						GroupsManagementComponent={ChargersGroupManagementModal}
					/>
				</div>
			</div>
			<div className={styles.chargerAddress}>
				<div className={styles.label}>{t("chargerDetails.address")}:</div>
				<div className={styles.chargerAddressController}>
					<Controller
						name="address"
						control={control}
						render={({ field: { onChange, value }, fieldState: { error, isDirty } }) => (
							<>
								<TextField
									type="text"
									data-testid="charger-address-input"
									value={value}
									error={error}
									onChange={onChange}
								/>

								<EditActionIcons showIcons={isDirty}
												iconNameTestPrefix="charger-address" save={handleSubmit(submitAddress)}
												rollback={() => resetAddressForm()}/>
							</>
						)}
					/>
				</div>
			</div>
			<div className={styles.link}>
				<span className={styles.text}>{t("chargerDetails.chargingRateSelection")} »</span>
			</div>
		</div>
	);
};
