import moment from "moment";
import ChargePointActionsService from "@services/chargePointActionsService";
import { ScheduleForm } from "@modals/ScheduleChargingModal/scheduleChargingModalInterface";
import { ConnectorSchedule } from "@interface/chargerScheduleInterface";
import { ConnectorStatus } from "@interface/chargerStatusInterface";
import { ChargePoint, Connector } from "@app/interface/chargersInterface";

const getTime = (time, timePickerFormat) => time ? moment(time, timePickerFormat) : null;

export const getInitialSchedule = (chargerId, connectorId, schedule, timePickerFormat) => {
	return {
		chargerId: chargerId,
		connectorId: connectorId,
		startTime: getTime(schedule?.startTime, timePickerFormat),
		endTime: getTime(schedule?.endTime, timePickerFormat),
		enabled: ChargePointActionsService.getInitialEnableState(schedule)
	};
};

export const updateScheduleForm = (scheduleForm: ScheduleForm, key, value) => {
	return {
		...scheduleForm,
		[key]: value
	};
};

export const isFormModified = (scheduleForm: ScheduleForm, schedule: ConnectorSchedule | undefined) => {
	return scheduleForm.enabled !== schedule?.enabled ||
		moment(scheduleForm.startTime).format("HH:mm") !== schedule?.startTime ||
		moment(scheduleForm.endTime).format("HH:mm") !== schedule?.endTime;
};

export const requiresStop = (status: ConnectorStatus) => status === ConnectorStatus.CHARGING;

export const isClearChargingProfile = (charger: ChargePoint, connectorId: Connector["connectorId"], schedulesById?: ConnectorSchedule): boolean => {
	const connector = charger.connectors.find((_connector) => _connector.connectorId === connectorId);
	return schedulesById?.enabled === true && connector?.status === ConnectorStatus.SUSPENDEDEV;
};
