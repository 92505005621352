import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReactTooltip from "react-tooltip";
import { Switch, TimePicker } from "antd";
import cs from "classnames";
import { ConnectorSchedule } from "@interface/chargerScheduleInterface";
import { CentralSystemAction } from "@interface/centralSystemInterface";
import { schedulerApi } from "@api/schedulerApi";
import { centralSystemApi } from "@api/centralSystemActionsApi";
import ChargePointActionsService from "@services/chargePointActionsService";
import { ScheduleChargingModalProps, ScheduleForm } from "@modals/ScheduleChargingModal/scheduleChargingModalInterface";
import { getInitialSchedule, isClearChargingProfile, isFormModified, updateScheduleForm } from "@modals/ScheduleChargingModal/scheduleChargingModalService";
import { Button } from "@components/Button/Button";
import { CaretDownIcon, TooltipIcon } from "@components/Icons/Icons";
import { Modal } from "@components/Modal/Modal";
import { SocketSelector } from "@components/SocketSelector/SocketSelector";
import styles from "./ScheduleChargingModal.module.scss";
import NotificationService from "@app/services/notificationService";

export const ScheduleChargingModal: FC<ScheduleChargingModalProps> = (
	{
		isOpen,
		onClose,
		charger,
		connectorId: initialConnectorId
	}
) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const minuteStep = 10;
	const timePickerFormat = "HH:mm";

	const [connectorId, setConnectorId] = useState(initialConnectorId);

	const { data: schedulesById, isLoading: schedulesLoader } = useQuery(
		["scheduleData", charger.externalId, connectorId],
		schedulerApi.getScheduleSettings,
		{
			select: (_data): ConnectorSchedule =>
				_data.filter((item) => item.chargerId === charger.externalId).find(item => item.connectorId === Number(connectorId))
		}
	);
	const [scheduleForm, setScheduleForm] = useState<ScheduleForm>(
		getInitialSchedule(charger.externalId, connectorId, schedulesById, timePickerFormat)
	);

	useEffect(() => {
		setScheduleForm(getInitialSchedule(charger.externalId, connectorId, schedulesById, timePickerFormat));
	}, [isOpen, connectorId, schedulesById]);

	const clearChargingProfile = () => {
		if (isClearChargingProfile(charger, connectorId, schedulesById)) {
			clearProfile({
				chargerId: charger.externalId,
				action: CentralSystemAction.Stop,
				connectorId: connectorId
			});
		} else {
			updateData();
		}
	};

	const updateData = () => {
		queryClient.refetchQueries("scheduleData");
		onClose();
	};

	const { mutateAsync: postSaveSchedule, isLoading: postScheduleLoading } =
		useMutation(ChargePointActionsService.postSaveSchedule, { onSuccess: updateData });

	const { mutateAsync: removeScheduleById, isLoading: removeScheduleByIdLoading } =
		useMutation(schedulerApi.deleteScheduleSettingsById, { onSuccess: clearChargingProfile });

	const { mutateAsync: clearProfile, isLoading: clearProfileLoading } =
		useMutation(centralSystemApi.clearChargingProfile, {
			onSuccess: updateData,
			onError: () => {
				NotificationService.displayError(t("messages.scheduleClearProfileError"));
			}
		});

	const handleFormChange = (field, newValue) => {
		setScheduleForm(updateScheduleForm(scheduleForm, field, newValue));
	};

	const saveSchedule = () => {
		postSaveSchedule({ charger, connectorId, scheduleForm });
	};

	const deleteSchedule = () => {
		removeScheduleById({ chargerId: charger.externalId, connectorId });
	};

	const isSaveButtonEnabled = () => (scheduleForm.startTime && scheduleForm.endTime) && isFormModified(scheduleForm, schedulesById);

	const isEnabledSwitchEnabled = () => scheduleForm.startTime && scheduleForm.endTime;

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			showLoader={postScheduleLoading || removeScheduleByIdLoading || clearProfileLoading || schedulesLoader}
			Header={t("scheduleModal.header")}
			Content={
				<>
					<div className={styles.scheduleContent} data-testid="schedule-modal">
						<div className={cs(styles.section, styles.enableScheduleSection)}>

							<SocketSelector connectors={charger.connectors} setActiveConnectorId={id => setConnectorId(id)} activeConnectorId={connectorId} />

							<div className={styles.enableScheduleLabel}>
								{t("scheduleModal.scheduledCharging")}
								<TooltipIcon
									data-for="main"
									data-tip={t("chargerDetails.socketConnectorId", { connectorId })}
								/>
							</div>

							<Switch
								checked={scheduleForm.enabled}
								onClick={(newValue) => handleFormChange("enabled", newValue)}
								disabled={!isEnabledSwitchEnabled()}
								data-testid={"enabled_switch"}

							/>
						</div>
						<div className={styles.divider} />
						<div className={styles.section}>
							<div className={styles.label}>{t("scheduleModal.startTime")}</div>
							<TimePicker
								className={styles.timePicker}
								placeholder={t("scheduleModal.selectTime")}
								format={timePickerFormat}
								value={scheduleForm.startTime}
								onChange={(newValue) => handleFormChange("startTime", newValue)}
								onSelect={(newValue) => handleFormChange("startTime", newValue)}
								minuteStep={minuteStep}
								suffixIcon={<CaretDownIcon />}
								clearIcon={<div />}
								data-testid={"timepicker_start"}
							/>
						</div>
						<div className={styles.section}>
							<div className={styles.label}>{t("scheduleModal.endTime")}</div>
							<TimePicker
								className={styles.timePicker}
								placeholder={t("scheduleModal.selectTime")}
								format={timePickerFormat}
								value={scheduleForm.endTime}
								onChange={(newValue) => handleFormChange("endTime", newValue)}
								onSelect={(newValue) => handleFormChange("endTime", newValue)}
								minuteStep={minuteStep}
								suffixIcon={<CaretDownIcon />}
								clearIcon={<div />}
								data-testid={"timepicker_end"}
							/>
						</div>
					</div>
					<ReactTooltip
						id="main"
						place="top"
						type="dark"
						effect="solid"
						multiline={true}
					/>
				</>
			}
			Footer={
				<>
					<Button buttonType="outline" onClick={deleteSchedule} disabled={!schedulesById} data-testid="schedule-remove-button">
						{t("scheduleModal.deleteSchedule")}
					</Button>
					<Button color="primary" onClick={saveSchedule} disabled={!isSaveButtonEnabled()} data-testid="schedule-save-button">
						{t("common.save")}
					</Button>
				</>
			}
		/>
	);
};
