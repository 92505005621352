import { Group } from "@app/interface/groupInterface";
import { endpoints } from "./endpoints";
import { GATEWAY_API } from "./http-common";

export const chargerGroupsApi = {
	getGroups: () => GATEWAY_API.get(endpoints.groups.chargersGroups).then((response) => response.data),
	updateGroups: (data: Group) =>
		GATEWAY_API.put(endpoints.groups.chargersGroups, data).then((response) => response.data),
	removeGroups: (groupId: Group["id"]) =>
		GATEWAY_API.delete(endpoints.groups.removeChargersGroup(groupId)).then((response) => response.data),
	addGroups: (data: { name: Group["name"] }) =>
		GATEWAY_API.post(endpoints.groups.chargersGroups, data).then((response) => response.data)
};
