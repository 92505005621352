import { ConnectorSchedule } from "@interface/chargerScheduleInterface";

export interface BackendChargeStatus {
	chargerId: string;
	connected: boolean;
	connectorStatuses?: ConnectorStatuses[];
}

export interface ConnectorStatuses {
	connectorId: string;
	status: ConnectorStatus;
	errorCode: string;
	timestamp: string;
	schedule?: ConnectorSchedule;
}

enum OcppConnectorStatus {
	AVAILABLE = "Available",
	PREPARING = "Preparing",
	CHARGING = "Charging",
	SUSPENDEDEVSE = "SuspendedEVSE",
	SUSPENDEDEV = "SuspendedEV",
	FINISHING = "Finishing",
	RESERVED = "Reserved",
	UNAVAILABLE = "Unavailable",
	FAULTED = "Faulted",
	OCCUPIED = "Occupied",
}

enum CustomConnectorStatus {
	UPDATING = "Updating",
}

export const ConnectorStatus = { ...OcppConnectorStatus, ...CustomConnectorStatus };
export type ConnectorStatus = OcppConnectorStatus | CustomConnectorStatus
