import axios from "axios";
import { store } from "@app/store";
import { clearUser } from "@reducers/userReducer";

export const GATEWAY_API = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_GATEWAY_BASE_URL
});

GATEWAY_API.interceptors.request.use(
	(config) => {
		checkUserSession();
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

GATEWAY_API.interceptors.response.use(
	response => response,
	error => {
		if (error.response.status == 401) {
			store.dispatch(clearUser());
		}

		return Promise.reject(error);
	}
);

export const checkUserSession = () => {
	const username = store.getState().user;

	if (!username) {
		store.dispatch(clearUser());
	}
};
