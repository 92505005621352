import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChargingCardStatus } from "@services/statusService";
import { Tooltip } from "@components/Tooltip/Tooltip";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { StatusDot } from "@components/StatusDot/StatusDot";
import { TableLink } from "@components/Table/components/TableLink/TableLink";
import styles from "./ChargingCardsTable.module.scss";

interface IdTagLabelProps {
	externalId: string;
}

export const NameCell: FC<IdTagLabelProps> = ({ externalId }) => {

	return (
		<div className={styles.chargerId} data-testid="charger-name-col">
			<TableLink to={`${Route.CHARGING_CARD_DETAILS}/${externalId}`}>{externalId}</TableLink>
		</div>
	);
};

interface StatusCellProps {
	status: ChargingCardStatus;
}

const LabelStatuses = {
	[ChargingCardStatus.ACTIVE]: "idtags.active",
	[ChargingCardStatus.DEACTIVATED]: "idtags.deactivated"
};

export const StatusCell: FC<StatusCellProps> = ({ status }) => {
	const { t } = useTranslation();

	return (
		<div>
			<Tooltip title={t(LabelStatuses[status])}>
				<StatusDot status={status} />
			</Tooltip>
		</div>
	);
};
