import { FC } from "react";
import cs from "classnames";
import { CellProps } from "react-table";
import { ChargePoint, Connector } from "@interface/chargersInterface";
import StatusService from "@services/statusService";
import { ActionsCell, MaxChargingCell, NameCell, StatusCell } from "./ChargerTableCells";
import styles from "./ChargersTable.module.scss";

interface ConnectorsRowProps {
	index: number;
	rowIndex: number;
	original: ChargePoint;
	connector: Connector;
	openScheduleModal: (chargerId, connectorId) => void;
}

export const SingleConnectorRow: FC<ConnectorsRowProps> = ({ index, rowIndex, original, connector, openScheduleModal }) => {
	return (
		<tr
			className={cs({
				[styles.odd]: rowIndex % 2 === 0
			})}
		>
			{index === 0 && (
				<td rowSpan={original.connectors.length}>
					{NameCell({
						cell: {
							value: original.externalId
						}
					} as CellProps<ChargePoint>)}
				</td>
			)}
			<td>
				<MaxChargingCell chargingPower={original.maxChargingPowerKw} />
			</td>
			<td>
				<StatusCell status={StatusService.getActionsCellStatus(connector.status, original.connected)} />
			</td>
			<td>
				<ActionsCell
					status={StatusService.getActionsCellStatus(connector.status, original.connected)}
					connectorId={connector.connectorId}
					charger={original}
					schedule={connector.schedule}
					openScheduleModal={openScheduleModal}
				/>
			</td>
		</tr>
	);
};
export const ConnectorsRows = ({
	chargePoint,
	rowIndex,
	openScheduleModal
}: {
	chargePoint: ChargePoint;
	rowIndex: number;
	openScheduleModal;
}) => {
	return chargePoint.connectors.map((connector, index) => {
		return (
			<SingleConnectorRow
				key={index}
				index={index}
				rowIndex={rowIndex + index}
				connector={connector}
				original={chargePoint}
				openScheduleModal={openScheduleModal}
			/>
		);
	});
};
