import { FC, useEffect, useRef } from "react";

interface Props {
	onOutsideClick: () => void,
	active: boolean,
	className?: string,
}
export const OnOutsideClick: FC<Props> = ({ onOutsideClick, active, className, children }) => {
	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!active) {
			return;
		}
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onOutsideClick();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, active]);

	return <div ref={ref} className={className} data-testid="outside-click-container">{children}</div>;
};
