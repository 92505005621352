import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { PenIcon } from "@components/Icons/Icons";
import { Button } from "@components/Button/Button";
import { StatusDot } from "@components/StatusDot/StatusDot";
import { User } from "@interface/userInterface";
import { UserStatus } from "@services/statusService";
import styles from "./UserSummary.module.scss";

interface UserActionsProps {
	user: User;
}

export const UserActions: FC<UserActionsProps> = ({ user }) => {
	const { t } = useTranslation();

	const handleToggleUser = () => {
		// TODO: missing endpoint to change user status.
		console.log(user.status);
	};

	const manageUserLabel = useMemo(() => {
		return user.status === UserStatus.ACTIVE ? t("userDetails.deactivateUser") : t("userDetails.activateUser");
	}, [user]);

	const statusLabel = useMemo(() => {
		return {
			[UserStatus.ACTIVATION_PENDING]: t("users.pending"),
			[UserStatus.ACTIVE]: t("users.active"),
			[UserStatus.INACTIVE]: t("users.inactive")
		}[user.status];
	}, [user]);

	return (
		<>
			<div className={styles.actions}>
				<div className={styles.indicators}>
					<div className={cs(styles.indicator, styles.status)}>
						<StatusDot status={user.status} />
						{statusLabel}
					</div>
				</div>
				<div className={styles.manageUser}>
					<Button color="primary" buttonType="solid" onClick={handleToggleUser}>
						<PenIcon />
						<span>{manageUserLabel}</span>
					</Button>
				</div>
			</div>
		</>
	);
};
