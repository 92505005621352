export interface TariffManagementModalState {
	active: boolean
}

export interface TariffManagementModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export interface TariffManagementForm {
	id?: string;
	price?: string;
	unitOfPower: UnitOfPower;
	denomination: Denomination;
	startDate?: string;
	endDate?: string;
}

export enum UnitOfPower {
	KW = "KW"
}

export enum Denomination {
	EUROCENT = "EUROCENT"
}

export interface UpdateTariffRequest {
	price?: string
}
