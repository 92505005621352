import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { idTagsApi } from "@api/idTagsApi";
import NotificationService from "@services/notificationService";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { Container } from "@components/Container/Container";
import { Layout } from "@components/Layout/Layout";
import { TableLoader } from "@app/components/Table/components/TableLoader/TableLoader";
import { parseIdTagCard } from "@model/idTagsModel";
import { ChargingCardDetails } from "@pages/ChargingCardDetailsPage/components/ChargingCardDetails/ChargingCardDetails";
import { ChargingCardNavigation } from "@pages/ChargingCardDetailsPage/components/ChargingCardNavigation/ChargingCardNavigation";
import { ChargingCardSummary } from "@pages/ChargingCardDetailsPage/components/ChargingCardSummary/ChargingCardSummary";

export const ChargingCardDetailsPage = () => {
	const history = useHistory();
	const param: { cardId: string } = useParams();
	const { t } = useTranslation();

	const { data: chargingCard, isLoading } = useQuery(["idtag", param.cardId], () => idTagsApi.getIdTag(param.cardId), {
		select: parseIdTagCard,
		onError: () => {
			NotificationService.displayError(t("messages.serverConnectionError"));
			history.push(Route.CHARGING_CARDS);
		}
	});

	const showLoader = () => {
		return (
			<TableLoader />
		);
	};

	return (
		<Layout>
			<Container>
				{isLoading && showLoader()}
				{chargingCard &&
					<>
						<ChargingCardNavigation />
						<ChargingCardSummary chargingCard={chargingCard} />
						<ChargingCardDetails chargingCard={chargingCard} />
					</>
				}
			</Container>
		</Layout>
	);
};
