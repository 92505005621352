import { useTranslation } from "react-i18next";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { Button } from "@components/Button/Button";
import styles from "./ConfirmAccount.module.scss";

export const ConfirmAccountFormSuccessful = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.confirmSuccessfulContainer} data-testid="create-account-success">
			<header className={styles.header}>
				<h2>
					<strong>{t("loginPage.accountCreationSuccessful")}</strong>
				</h2>
			</header>
			<div className={styles.loginButtonContainer}>
				<Button color="primary" buttonType="solid" to={Route.LOGIN}>
					{t("loginPage.logIn")}
				</Button>
			</div>
		</div>
	);
};
