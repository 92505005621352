import React, { FC } from "react";
import styles from "./EditActionIcons.module.scss";
import { CloseIcon, TickIcon } from "@components/Icons/Icons";

export interface EditActionIconsProps {
	showIcons: boolean;
	iconNameTestPrefix: string;
	save: () => void;
	rollback: () => void;
}

export const EditActionIcons: FC<EditActionIconsProps> = (
	{
		showIcons,
		iconNameTestPrefix,
		save,
		rollback
	}) => {
	return (<>{showIcons && (
		<>
			<div className={styles.primaryIcon} onClick={save}>
				<TickIcon data-testid={`${iconNameTestPrefix}-save-icon`}/>
			</div>

			<div onClick={rollback}>
				<CloseIcon data-testid={`${iconNameTestPrefix}-rollback-icon`}/>
			</div>
		</>
	)}</>);
};
