import { FC, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearUser, getUser } from "@reducers/userReducer";
import { OnOutsideClick } from "@components/OnOutsideClick/OnOutsideClick";
import { Dropdown } from "@components/Dropdown/Dropdown";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { LanguageSwitcher } from "@components/LanguageSwitcher/LanguageSwitcher";
import { Container } from "@components/Container/Container";
import { CaretDownIcon, Logo } from "@components/Icons/Icons";
import styles from "./Header.module.scss";
import NotificationService from "@services/notificationService";
import { accountApi } from "@api/accountApi";
import { store } from "@app/store";

export const Header: FC = () => {
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState<boolean>(false);
	const user = useSelector(getUser);

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const logout = () => {
		return accountApi.logout().then(
			() => {
				store.dispatch(clearUser());
				localStorage.clear();
			},
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	};

	return (
		<div className={styles.container} data-testid="layout-header">
			<Container>
				<div className={styles.background} />
				<header className={styles.header}>
					<div className={styles.logo} data-testid="app-logo">
						<Logo />
					</div>
					<div className={styles.rightContainer}>
						<div>
							{`${t("navigation.businessCustomerService")},`}
							<OnOutsideClick onOutsideClick={handleClose} active={isOpen}>
								<span className={styles.userContainer} onClick={handleOpen} data-testid="header-menu">
									<strong>{user}</strong>
									<div className={styles.icon}>
										<CaretDownIcon />
									</div>
									{!!isOpen && (
										<Dropdown>
											<li>
												<Link onClick={logout} to={Route.HOME}>
													<span>{t("loginPage.logOut")}</span>
												</Link>
											</li>
										</Dropdown>
									)}
								</span>
							</OnOutsideClick>
						</div>
						<div>
							<LanguageSwitcher />
						</div>
					</div>
				</header>
			</Container>
		</div>
	);
};
