import React, { FC, useState } from "react";
import { PenIcon } from "@components/Icons/Icons";
import styles from "./TariffManagementModal.module.scss";
import { TextField } from "@components/Form/TextField/TextField";
import { EditActionIcons } from "@components/EditActionIcons/EditActionIcons";
import { chargingTariffApi } from "@api/chargingTariffApi";
import { yupResolver } from "@hookform/resolvers/yup/";
import { Controller, useForm } from "react-hook-form";
import { chargingTariffPriceSchema } from "@modals/TariffManagementModal/TariffManagementModal";

interface PriceCellProps {
	id?: string;
	price?: string;
	setLastHistoricalPriceSave: (Date) => void;
}

export const PriceCell: FC<PriceCellProps> = ({ id, price, setLastHistoricalPriceSave }) => {

	const [enteredPrice, setEnteredPrice] = useState<string | undefined>(price);
	const [isPenIconShown, setIsPenIconShown] = useState<boolean>(false);
	const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

	const { control, handleSubmit } = useForm({
		defaultValues: {
			"price": enteredPrice
		},
		resolver: yupResolver(chargingTariffPriceSchema)
	});

	const submitHistoryTariff = (form) => {
		chargingTariffApi.updateTariff(id, form).then(() => {
			closeEditMode();
			setLastHistoricalPriceSave(new Date());
		});
	};

	const rollbackChanges = () => {
		setEnteredPrice(price);
		closeEditMode();
	};

	const openEditMode = () => {
		setIsInEditMode(true);
		setIsPenIconShown(false);
	};

	const closeEditMode = () => {
		setIsInEditMode(false);
		setIsPenIconShown(false);
	};

	const handlePenIcon = (shouldBeShown) => {
		if (isInEditMode) {
			return;
		}

		setIsPenIconShown(shouldBeShown);
	};

	return <div className={styles.historyPriceCell}
				onMouseEnter={() => handlePenIcon(true)}
				onMouseLeave={() => handlePenIcon(false)}>
		{isInEditMode ?

			<form onSubmit={handleSubmit(submitHistoryTariff)}>
				<Controller
					name="price"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
					<div className={styles.historyPriceInput}>
						<div>
							<TextField
								type="number"
								data-testid={`tariff-price-history-input-${id}`}
								value={value}
								error={error}
								onChange={onChange}/>
						</div>

						<EditActionIcons showIcons={true}
										iconNameTestPrefix={`tariff-price-history-${id}`}
										save={handleSubmit(submitHistoryTariff)}
										rollback={rollbackChanges}/>
					</div>
					)}/>
			</form>:
			<div onClick={openEditMode} data-testid={`tariff-price-history-price-text-${id}`}>
				{price}
				{isPenIconShown && <span className={styles.penIcon} data-testid={`tariff-price-history-pen-icon-${id}`}><PenIcon/></span>}
			</div>}
	</div>;
};
