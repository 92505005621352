import { FC } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Button } from "@app/components/Button/Button";
import { GroupTags } from "@app/components/GroupTags/GroupTags";
import { DataError } from "@app/components/Table/components/DataError/DataError";
import { BackendGroup, Group, GroupsModalProps, GroupTypes } from "@app/interface/groupInterface";
import { parseGroups } from "@app/model/groupModel";
import { Modal } from "@components/Modal/Modal";
import styles from "./GroupManagementModal.module.scss";

interface GroupManagementModalProps extends GroupsModalProps {
	groupKey: GroupTypes;
	getGroups: () => Promise<BackendGroup[]>;
	updateGroup: (data: Group) => Promise<BackendGroup>;
	removeGroup: (groupId: Group["id"]) => Promise<null>;
	addGroup: (data: { name: Group["name"] }) => Promise<BackendGroup>;
	onRemoved?: (groupId: string) => void;
	onUpdated?: (groupId: BackendGroup) => void;
}

export const GroupManagementModal: FC<GroupManagementModalProps> = ({
	isOpen,
	onClose,
	groupKey,
	getGroups: _getGroups,
	updateGroup: _updateGroup,
	removeGroup: _removeGroup,
	addGroup: _addGroup,
	onRemoved: _onRemoved,
	onUpdated: _onUpdated
}) => {
	const { t } = useTranslation();

	const {
		data: defaultGroups,
		isLoading,
		isError,
		refetch
	} = useQuery(["groups", groupKey], _getGroups, {
		enabled: isOpen,
		select: parseGroups,
		retry: false,
		keepPreviousData: true
	});

	const { mutateAsync: updateGroups, isLoading: updateLoading } = useMutation(_updateGroup, {
		onSuccess: (group: BackendGroup) => {
			if (_onUpdated && group) {
				_onUpdated(group);
			}
		}
	});

	const { mutateAsync: removeGroups, isLoading: removeLoading } = useMutation(_removeGroup, {
		onSuccess: (_, removedGroupId) => {
			if (_onRemoved && removedGroupId) {
				_onRemoved(removedGroupId);
			}
		}
	});

	const { mutateAsync: addGroups, isLoading: addLoading } = useMutation(_addGroup);

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			Header={t("groups.groupManagement")}
			Content={
				<>
					{isError && <DataError refetch={refetch} />}
					<GroupTags
						groupKey={groupKey}
						defaultGroups={defaultGroups}
						addGroups={addGroups}
						removeGroups={removeGroups}
						updateGroups={updateGroups}
						isLoading={isLoading || updateLoading || removeLoading || addLoading}
						isError={isError}
					/>
				</>
			}
			Footer={
				!isError && (
					<>
						<div className={styles.footer}>
							<Button buttonType="outline" onClick={onClose}>
								{t("groups.cancelChanges")}
							</Button>
						</div>
					</>
				)
			}
		/>
	);
};
