import { GATEWAY_API } from "@api/http-common";
import { endpoints } from "@api/endpoints";
import { UserChargerCreatePayload, UserChargerUpdatePayload } from "@interface/userInterface";

export const userChargersApi = {
	getUserChargers: () => GATEWAY_API.get(endpoints.userChargers.userChargers).then(response => response.data),
	createUserCharger: (data: UserChargerCreatePayload) => GATEWAY_API.post(endpoints.userChargers.userChargers, data).then(response => response.data),
	updateUserCharger: (userChargerId, data: UserChargerUpdatePayload) => GATEWAY_API.put(endpoints.userChargers.userCharger(userChargerId), data)
		.then(response => response.data),
	deleteUserCharger: (userChargerId) => GATEWAY_API.delete(endpoints.userChargers.userCharger(userChargerId)).then(response => response.data)
};
