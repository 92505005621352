import { Button } from "@app/components/Button/Button";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./DataError.module.scss";

export const DataError: FC<{ refetch?: () => void }> = ({ refetch }) => {
	const { t } = useTranslation();
	return (
		<div className={styles.container} data-testid="data-error-container">
			<div>{t("messages.errorDataRetrieve")}</div>
			<div>{t("messages.errorContact")}</div>

			{refetch && (
				<div>
					<Button color="primary" onClick={refetch} data-testid="data-error-refetch-button">
						{t("common.tryAgain")}
					</Button>
				</div>
			)}
		</div>
	);
};
