import { Group, GroupTypes } from "@app/interface/groupInterface";
import NotificationService from "@app/services/notificationService";
import cs from "classnames";
import { ChangeEvent, FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { PlusIcon } from "../Icons/Icons";
import { Loader } from "../Loader/Loader";
import { GroupInput } from "./components/GroupInput";
import styles from "./GroupTags.module.scss";

const ICONS_SIZE = 15;

export interface GroupTagsProps {
	isLoading: boolean;
	isError?: boolean;
	defaultGroups?: Group[];
	groupKey: GroupTypes;

	addGroups: (data: { name: Group["name"] }) => Promise<Group>;
	updateGroups: (data: Group) => Promise<Group>;
	removeGroups: (groupId: Group["id"]) => Promise<null>;
}

interface GroupState {
	index: number;
	id?: string;
	type: "EDIT" | "NEW";
	name: string;
}

export const GroupTags: FC<GroupTagsProps> = ({
	isLoading,
	isError,
	groupKey,
	defaultGroups,
	addGroups,
	updateGroups,
	removeGroups
}) => {
	const { t } = useTranslation();
	const [groupState, setGroupsState] = useState<null | GroupState>(null);
	const queryClient = useQueryClient();

	const [groups, setGroups] = useState<Group[]>([]);

	const removeGroup = async (group) => {
		if (group.id) {
			try {
				await removeGroups(group.id);
				queryClient.setQueryData<Group[] | undefined>(["groups", groupKey], (_g) => {
					return _g?.filter(({ id }) => group.id !== id);
				});
			} catch (e) {
				NotificationService.displayError(t("messages.removeGroupError"));
			}
		} else {
			setGroups(groups.filter(({ id }) => group.id !== id));
		}
		setGroupsState(null);
	};

	const handleApply = async () => {
		if (groupState?.type === "NEW") {
			try {
				const newGroup = await addGroups({
					name: groupState.name
				});

				queryClient.setQueryData(["groups", groupKey], [...groups.filter(({ id }) => !!id), newGroup]);

				setGroupsState(null);
			} catch (e) {
				NotificationService.displayError(t("messages.addNewGroupError"));
			}
		}
		if (groupState?.type === "EDIT" && groupState.id) {
			try {
				const updatedGroup = await updateGroups({
					id: groupState.id,
					name: groupState.name
				});
				queryClient.setQueryData(
					["groups", groupKey],
					groups.map((g) => (g.id === updatedGroup.id ? updatedGroup : g))
				);

				setGroupsState(null);
			} catch (e) {
				NotificationService.displayError(t("messages.updateGroupError"));
			}
		}
	};

	const handleAddNew = () => {
		const newIndex = groups.length;
		setGroups([...groups, { name: "" }]);
		setGroupsState({
			index: newIndex,
			name: "",
			type: "NEW"
		});
	};
	useMemo(() => {
		setGroups(defaultGroups || []);
	}, [defaultGroups]);

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setGroupsState({
			...groupState as GroupState, name: e.target.value
		});
	};
	const handleEdit = (index: number, group: Group) => setGroupsState({
		index,
		id: group.id,
		name: group.name,
		type: "EDIT"
	});
	const onNewClick = () => !groupState && handleAddNew();
	return (
		<div className={styles.container} data-testid="group-tags">
			{isLoading && (
				<div className={styles.loaderContainer}>
					<Loader />
				</div>
			)}
			{!isError && (
				<ul data-testid="groups-list">
					{groups.map((group, index) => {
						const isActive = !!groupState && groupState.index === index;
						return (
							<li key={index} data-testid="group-element">
								<label
									className={cs({
										[styles.new]: isActive
									})}
								>
									<GroupInput
										value={isActive ? groupState.name : group.name}
										onChange={handleInputChange}
										isEditing={isActive}
										isDisabled={!!groupState}
										handleEdit={() => handleEdit(index, group)}
										handleRemove={() => removeGroup(group)}
										groupAccept={handleApply}
									/>
								</label>
							</li>
						);
					})}
					<li>
						<div
							className={cs(styles.addIcon, {
								[styles.disabled]: !!groupState
							})}
							onClick={onNewClick}
							data-testid="add-group-button"
						>
							<PlusIcon width={ICONS_SIZE} height={ICONS_SIZE} />
						</div>
					</li>
				</ul>
			)}
		</div>
	);
};
