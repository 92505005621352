import { FC, useMemo } from "react";
import cs from "classnames";
import { Container } from "@app/components/Container/Container";
import { CaretLeftIcon, CaretRightIcon } from "@app/components/Icons/Icons";
import styles from "./Pagination.module.scss";
import { useTranslation } from "react-i18next";

interface PaginationProps {
	gotoPage: (page: number) => void;
	previousPage: () => void;
	nextPage: () => void;
	canPreviousPage: boolean;
	canNextPage: boolean;
	pageIndex: number;
	setPageSize: (size: number) => void;
	pageCount: number;
	pageSize: number;
	total: number;
	rows;
	noInternalPadding?: boolean;
	blockPageSizeChanging?: boolean;
}

export const Pagination: FC<PaginationProps> = ({
	gotoPage,
	previousPage,
	nextPage,
	canPreviousPage,
	canNextPage,
	pageIndex,
	pageSize,
	setPageSize,
	pageCount,
	total,
	rows,
	noInternalPadding,
	blockPageSizeChanging
}) => {
	const VISIBLE_ITEMS = [10, 20, 30, 40, 50];
	const ADJACENTS = 1;
	const PADDING = 1;

	const { t } = useTranslation();

	const currentPage = useMemo(() => {
		let truePage = Math.max(1, pageIndex + 1);
		truePage = Math.min(truePage, pageCount);
		return truePage;
	}, [pageIndex, pageCount]);

	const head = useMemo(() => {
		const headPages: number[] = [];
		for (let i = 1; i < Math.min(1 + PADDING, currentPage - ADJACENTS); i++) {
			headPages.push(i);
		}
		return headPages;
	}, [currentPage, ADJACENTS, PADDING]);

	const body = useMemo(() => {
		const bodyPages: number[] = [];
		for (let i = Math.max(1, currentPage - ADJACENTS); i <= Math.min(pageCount, currentPage + ADJACENTS); i++) {
			bodyPages.push(i);
		}
		return bodyPages;
	}, [currentPage, pageCount, ADJACENTS]);

	const tail = useMemo(() => {
		const tailPages: number[] = [];
		for (let i = Math.max(pageCount - PADDING + 1, currentPage + 1 + ADJACENTS); i <= pageCount; i++) {
			tailPages.push(i);
		}
		return tailPages;
	}, [currentPage, pageCount, ADJACENTS, PADDING]);

	const leftEllipsis = useMemo(() => currentPage - ADJACENTS > 1 + PADDING, [currentPage, ADJACENTS, PADDING]);

	const rightEllipsis = useMemo(
		() => currentPage + ADJACENTS < pageCount - PADDING,
		[currentPage, pageCount, ADJACENTS, PADDING]
	);

	const renderExtremes = (item) => (
		<span key={item} onClick={() => gotoPage(item - 1)} className={cs(styles.number)}>
			{item}
		</span>
	);

	const getNumberOfShownItems = useMemo(() => {
		const visibleRows = rows.slice((pageIndex * pageSize), (pageIndex * pageSize) + pageSize);
		const numberOfVisibleIdTags = visibleRows.filter((row) => !row.isGrouped)?.length;

		return numberOfVisibleIdTags;
	}, [pageIndex, pageSize, rows]);

	return (
		<div className={styles.container} data-testid="table-pagination">
			<Container noInternalPadding={noInternalPadding}>
				<div className={styles.content}>
					{!blockPageSizeChanging && (
					<div>
						<span>
							{t("common.showingItems", {
								value: getNumberOfShownItems,
								total
							})}
						</span>
					</div>
					)}
					<div className={styles.rightContainer}>
						{!blockPageSizeChanging && (
						<div>
							<span>{t("chargersTable.show")}</span>
							<select
								data-testid="table-pagination-page-size"
								value={pageSize}
								onChange={(e) => {
									setPageSize(Number(e.target.value));
								}}
							>
								{VISIBLE_ITEMS.map((_pageSize) => (
									<option key={_pageSize} value={_pageSize}>
										{_pageSize}
									</option>
								))}
							</select>
							<span>{t("common.itemsOnPage")}</span>
						</div>
						)}

						<div className={styles.controls}>
							<button
								onClick={previousPage}
								disabled={!canPreviousPage}
								className={styles.arrowIcon}
								data-testid="table-pagination-prev-button"
							>
								<CaretLeftIcon width={10} />
							</button>
							<div data-testid="table-pagination-numbers">
								{head.map(renderExtremes)}
								{leftEllipsis && <span>...</span>}
								{body.map((item) => (
									<span
										key={item}
										onClick={() => gotoPage(item - 1)}
										className={cs(styles.number, {
											[styles.active]: item - 1 === pageIndex
										})}
									>
										{item}
									</span>
								))}
								{rightEllipsis && <span>...</span>}
								{tail.map(renderExtremes)}
							</div>
							<button
								onClick={nextPage}
								disabled={!canNextPage}
								className={styles.arrowIcon}
								data-testid="table-pagination-next-button"
							>
								<CaretRightIcon width={10}/>
							</button>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};
