import { ConnectorStatus } from "@interface/chargerStatusInterface";
import { IdTagStatus } from "@interface/idTagsInterface";

export enum ActionsCellStatus {
	UNAVAILABLE = "Unavailable",
	AVAILABLE = "Available",
	CHARGING = "Charging",
	LOADING = "Loading",
	OCCUPIED = "Occupied",
	SUSPENDEDEV = "SuspendedEV",
}

export enum ChargingCardStatus {
	ACTIVE = "Active",
	DEACTIVATED = "Deactivated",
}

export enum UserStatus {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
	ACTIVATION_PENDING = "ACTIVATION_PENDING",
}

const StatusService = {
	getActionsCellStatus: (status: ConnectorStatus, isChargerConnected): ActionsCellStatus => {
		const isAvailable = () => {
			return isChargerConnected && (
				status === ConnectorStatus.AVAILABLE ||
				status === ConnectorStatus.PREPARING
			);
		};

		const isSuspendedEV = () => status === ConnectorStatus.SUSPENDEDEV || status === ConnectorStatus.SUSPENDEDEVSE;

		const isCharging = () => status === ConnectorStatus.CHARGING;

		const isLoading = () => status === ConnectorStatus.UPDATING;

		const isDisabled = () => {
			return !isChargerConnected ||
				status === ConnectorStatus.UNAVAILABLE ||
				status === ConnectorStatus.FAULTED;
		};

		if (isAvailable()) {
			return ActionsCellStatus.AVAILABLE;
		}
		if (isSuspendedEV()) {
			return ActionsCellStatus.SUSPENDEDEV;
		}
		if (isCharging()) {
			return ActionsCellStatus.CHARGING;
		}
		if (isLoading()) {
			return ActionsCellStatus.LOADING;
		}
		if (isDisabled()) {
			return ActionsCellStatus.UNAVAILABLE;
		}

		return ActionsCellStatus.UNAVAILABLE;
	},
	getChargingCardStatus: (status: IdTagStatus): ChargingCardStatus => {
		if (status === IdTagStatus.ACTIVE) {
			return ChargingCardStatus.ACTIVE;
		}

		return ChargingCardStatus.DEACTIVATED;
	}

};

export default StatusService;
