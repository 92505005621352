import { BackendGroup, Group } from "@interface/groupInterface";

export const parseGroups = (BackendGroups: BackendGroup[]): Group[] => {
	return BackendGroups.map((group) => {
		return {
			id: group.id,
			name: group.name
		};
	});
};
