import { FC, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import styles from "./Tooltip.module.scss";

export const Tooltip: FC<{ title: string }> = ({ children, title }) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	});

	return (
		<div data-for="tooltip" data-tip={title} data-testid="tooltip-container" className={styles.container}>
			{children}
		</div>
	);
};
