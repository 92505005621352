import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@components/Tooltip/Tooltip";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { TableLink } from "@components/Table/components/TableLink/TableLink";
import styles from "./UsersTable.module.scss";
import { CircleIcon } from "@app/components/Icons/Icons";
import { UserStatus } from "@app/services/statusService";

interface NameProps {
	fullName: string;
	id: string;
}

export const NameCell: FC<NameProps> = ({ fullName, id }) => {
	return (
		<div className={styles.chargerId} data-testid="user-name-col">
			<TableLink to={`${Route.USER_DETAILS}/${id}`}>{fullName}</TableLink>
		</div>
	);
};

interface StatusCellProps {
	status: UserStatus;
}

const StatusData = {
	[UserStatus.ACTIVE]: { t: "users.active", className: styles.primaryIcon },
	[UserStatus.INACTIVE]: { t: "users.inactive", className: styles.dangerIcon },
	[UserStatus.ACTIVATION_PENDING]: { t: "users.pending", className: styles.pendingIcon }
};

export const StatusCell: FC<StatusCellProps> = ({ status }) => {
	const { t } = useTranslation();

	const tooltipLabel = t(StatusData[status].t);
	const statusClassName = StatusData[status].className;
	return (
		<div>
			<Tooltip title={tooltipLabel}>
				<CircleIcon className={statusClassName} />
			</Tooltip>
		</div>
	);
};
