import { FC } from "react";
import cs from "classnames";
import { Connector } from "@app/interface/chargersInterface";
import styles from "./SocketSelector.module.scss";

interface SocketSelectorProps {
	connectors: Connector[],
	activeConnectorId?: string,
	setActiveConnectorId: (conectorId: string) => void
}

export const SocketSelector: FC<SocketSelectorProps> = ({ connectors, activeConnectorId, setActiveConnectorId }) => {

	if (connectors.length <= 1 || !activeConnectorId) {
		return null;
	}

	return <div className={styles.connectorSelectContainer} data-testid="socket-selector-container">
		{
			connectors.map((connector) => (
				<button
					key={connector.connectorId}
					className={cs(styles.socket, { [styles.active]: activeConnectorId === connector.connectorId })}
					onClick={() => setActiveConnectorId(connector.connectorId)}
					data-testid="socket-button"
				>
					{connector.label}
				</button>
			))
		}
	</div>;
};
