import { FC } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { idTagsApi } from "@api/idTagsApi";
import { IdTag } from "@interface/idTagsInterface";
import { Modal } from "@components/Modal/Modal";
import IdTagService from "@services/idTagService";
import NotificationService from "@services/notificationService";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { AddCardForm } from "./AddCardForm";

interface AddCardModalProps {
	isOpen: boolean;
	onClose: () => void;
	chargingCard?: IdTag;
}

export const AddCardModal: FC<AddCardModalProps> = ({ isOpen, onClose, chargingCard }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const addSuccessCallback = (data) => {
		onClose();
		IdTagService.addIdTagQuery(data);
	};

	const editSuccessCallback = (data) => {
		history.replace(`${Route.CHARGING_CARD_DETAILS}/${data.externalId}`);
		onClose();
		IdTagService.updateIdTagQuery(data);
	};

	const addErrorCallback = () => {
		NotificationService.displayError(t("messages.addNewCardError"));
	};

	const editErrorCallback = () => {
		NotificationService.displayError(t("messages.editCardError"));
	};

	const addEditMode = chargingCard ? {
		request: idTagsApi.updateIdTag,
		successCallback: editSuccessCallback,
		errorCallback: editErrorCallback
	} : {
		request: idTagsApi.createIdTag,
		successCallback: addSuccessCallback,
		errorCallback: addErrorCallback
	};

	const { mutate: action, isLoading } = useMutation(addEditMode.request, {
		onSuccess: (data) => {
			addEditMode.successCallback(data);
		},
		onError: () => {
			addEditMode.errorCallback();
		}
	});

	const getHeader = (editMode) => {
		return editMode ? t("idtagsAddModal.manageCard") : t("idtagsAddModal.addCard");
	};

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			Header={getHeader(!!chargingCard)}
			showLoader={isLoading}
			Content={
				<AddCardForm
					action={action}
					chargingCard={chargingCard}
				/>
			}
		/>
	);
};
