import { toast } from "react-toastify";

export enum NotificationLevel {
	success = "success",
	info = "info",
	warning = "warning",
	error = "error",
}

const NotificationService = {
	displaySuccess: (message: string) => {
		NotificationService.displayMessage(NotificationLevel.success, message);
	},
	displayInfo: (message: string) => {
		NotificationService.displayMessage(NotificationLevel.info, message);
	},
	displayWarning: (message: string) => {
		NotificationService.displayMessage(NotificationLevel.warning, message);
	},
	displayError: (message: string) => {
		NotificationService.displayMessage(NotificationLevel.error, message);
	},
	displayMessage: (level: NotificationLevel, message: string) => {
		toast(message, {
			type: level,
			position: "top-center",
			theme: "colored"
		});
	}
};

export default NotificationService;
