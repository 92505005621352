import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { chargePointsApi } from "@api/chargePointsApi";
import { IdTag } from "@interface/idTagsInterface";
import NotificationService from "@services/notificationService";
import IdTagService from "@services/idTagService";
import ChargePointActionsService from "@services/chargePointActionsService";
import { Button } from "@components/Button/Button";
import { Multiselect } from "@components/Form/Multiselect/Multiselect";
import { Modal } from "@components/Modal/Modal";
import styles from "./ManageRfidChargersModal.module.scss";
import { localListApi } from "@api/localListApi";

interface ManageRfidChargersModalProps {
	chargingCard: IdTag;
	isOpen: boolean;
	onClose: () => void;
}

export const ManageRfidChargersModal: FC<ManageRfidChargersModalProps> = ({ chargingCard, isOpen, onClose }) => {
	const { t } = useTranslation();

	const { data: chargepointsList, isFetched } = useQuery("chargePoints", chargePointsApi.getChargePoints, {
		select: IdTagService.parseIdtagChargersOptions
	});

	const { data: values } = useQuery("chargePoints", chargePointsApi.getChargePoints, {
		select: chargePoint => IdTagService.parseIdtagChargers(chargingCard.externalId, chargePoint)
	});

	const { mutate: handleAdd, isLoading: isAddingLoading } = useMutation(localListApi.assignCharger, {
		onSuccess: (_, { chargerId }) => {
			ChargePointActionsService.addIdtagFromCharger(chargerId, chargingCard);
		},
		onError: () => {
			NotificationService.displayError(t("messages.rfidAssignChargerError"));
		}
	});

	const { mutate: handleRemove, isLoading: isRemoveLoading } = useMutation(localListApi.removeCharger, {
		onSuccess: (_, { chargerId }) => {
			ChargePointActionsService.removeIdtagFromCharger(chargerId, chargingCard);
		},
		onError: () => {
			NotificationService.displayError(t("messages.rfidRemoveChargerError"));
		}
	});

	const onSelect = (chargerId) => handleAdd({
		chargerId: chargerId,
		chargingCardId: chargingCard.externalId
	});

	const onDeselect = (chargerId) => handleRemove({
		chargerId: chargerId,
		chargingCardId: chargingCard.externalId
	});

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			Header={t("manageRfidChargersModal.manageChargepoints")}
			showLoader={!isFetched || isAddingLoading || isRemoveLoading}
			Content={
				<div data-testid="manage-rfid-chargers-modal-content">
					<Multiselect
						label={t("manageRfidChargersModal.chargerId")}
						placeholder={t("manageRfidChargersModal.selectUnlinkedChargers")}
						onSelect={onSelect}
						onDeselect={onDeselect}
						values={values || []}
						options={chargepointsList || []}
						data-testid="chargers-multiselect"
					/>
					<div className={styles.footer}>
						<Button
							color="primary"
							onClick={onClose}
							data-testid="card-close-button"
						>
							{t("common.close")}
						</Button>
					</div>
				</div>

			}
		/>
	);
};
