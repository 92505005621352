import { centralSystemApi } from "@api/centralSystemActionsApi";
import { schedulerApi } from "@api/schedulerApi";
import { BackendChargeStatus, ConnectorStatus } from "@interface/chargerStatusInterface";
import { BackendChargePoint, ChargePoint } from "@interface/chargersInterface";
import { IdTag } from "@interface/idTagsInterface";
import { CentralSystemAction } from "@interface/centralSystemInterface";
import NotificationService from "@services/notificationService";
import { BackendSchedule, ConnectorSchedule } from "@interface/chargerScheduleInterface";
import { buildSaveSchedulePayload } from "@model/scheduleModel";
import { requiresStop } from "@modals/ScheduleChargingModal/scheduleChargingModalService";
import config from "@app/config/config";
import queryClient from "../../queryClient";

const startTransaction = ({ chargerId, connectorId }) => {
	return centralSystemApi.triggerCentralSystemAction(chargerId, CentralSystemAction.Start, connectorId).then(
		(response) => {
			if (response.status) {
				updatechargePointsStatusQueryData(chargerId, connectorId);
			}
		},
		(error: Error) => {
			NotificationService.displayError(error.message);
		}
	);
};

const stopTransaction = ({ chargerId, connectorId }) => {
	return centralSystemApi.triggerCentralSystemAction(chargerId, CentralSystemAction.Stop, connectorId).then(
		(response) => {
			if (response.status) {
				updatechargePointsStatusQueryData(chargerId, connectorId);
			}
		},
		(error: Error) => {
			NotificationService.displayError(error.message);
		}
	);
};

const postSaveSchedule = ({ charger, connectorId, scheduleForm }) => {
	if (requiresStop(charger.connectors[Number(connectorId) - 1].status)) {
		return stopTransaction({
			chargerId: charger.externalId,
			connectorId: Number(connectorId)
		}).then(
			() => {
				setTimeout(() => {
					return schedulerApi.postScheduleSettings(buildSaveSchedulePayload(scheduleForm)).then(
						() => {
							startTransaction({ chargerId: charger.externalId, connectorId });
						},
						(error: Error) => {
							NotificationService.displayError(error.message);
						}
					);
				}, config.transactionDelayAfterStop);
			});
	} else {
		return schedulerApi.postScheduleSettings(buildSaveSchedulePayload(scheduleForm)).then(
			() => {
				startTransaction({ chargerId: charger.externalId, connectorId });
			},
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	}
};

const resetCharger = (chargerId: string, connectorId = 0) => {
	return centralSystemApi.triggerCentralSystemAction(chargerId, CentralSystemAction.HardReset, connectorId);
};

const updatechargePointsStatusQueryData = (chargerId, connectorId) => {
	queryClient.setQueryData<BackendChargeStatus[]>("chargePointsStatus", (chargePointsStatus) => {
		return updateConnectorStatus(chargerId, Number(connectorId), chargePointsStatus);
	});
};

const updateConnectorStatus = (chargerId: string, connectorId: number, chargePointsStatus: BackendChargeStatus[] = []) => {
	return chargePointsStatus.map((chargePointStatus: BackendChargeStatus) => {
		if (chargePointStatus.chargerId === chargerId && chargePointStatus.connectorStatuses) {
			return updateConnectorStatusById(chargePointStatus, connectorId);
		}

		return chargePointStatus;
	});
};

const updateConnectorStatusById = (chargePointStatus: BackendChargeStatus, connectorId) => {
	const newStatus = ConnectorStatus.UPDATING;

	return {
		...chargePointStatus,
		connectorStatuses: chargePointStatus?.connectorStatuses?.map((connector) => {
			return connector.connectorId === `${connectorId}` ? { ...connector, status: newStatus } : connector;
		})
	};
};

const updateActiveChargerPointStatusQueryData = (connectorId: number) => {
	queryClient.setQueryData<BackendChargeStatus | undefined>("activeChargerPointStatus", (data) => {
		if (data) {
			return ChargePointActionsService.updateConnectorStatusById(data, connectorId);
		}
		return data;
	});
};

const getInitialEnableState = (schedule: ConnectorSchedule | undefined): boolean => schedule?.enabled ? true : false;

const toggleEnableScheduler = (chargerId, connectorId, enabled) => {
	queryClient.setQueryData<BackendSchedule[] | undefined>("scheduleData", (scheduleData) => {
		return scheduleData ? updateEnableStateById(scheduleData, chargerId, Number(connectorId), enabled) : [];
	});
};

const updateEnableStateById = (scheduleData: BackendSchedule[], chargerId, connectorId, enabled) => {
	return scheduleData.map((schedule) =>
		schedule.chargerId === chargerId && schedule.connectorId === connectorId ? { ...schedule, enabled } : schedule
	);
};

const toggleOwnership = (updatedChargePoint: ChargePoint) => {
	queryClient.setQueryData<ChargePoint | undefined>(
		["activeCharger", updatedChargePoint.externalId],
		(activeCharger) => {
			return activeCharger ? updateChargePointsOwnershipById(activeCharger, updatedChargePoint) : undefined;
		}
	);
};

const updateChargePointsOwnershipById = (activeCharger: ChargePoint, updatedChargePoint: ChargePoint) => {
	return { ...activeCharger, ownership: updatedChargePoint.ownership };
};
const addIdtagFromCharger = (chargepointLabel: string, chargingCard: IdTag) => {
	queryClient.setQueryData<BackendChargePoint[] | undefined>("chargePoints", (data) => {
		if (!data) {
			return data;
		}
		return data.map(cp => cp.externalId === chargepointLabel ? ({
			...cp,
			idtags: [...cp.idtags, {
				externalId: chargingCard.externalId,
				idtagGroup: chargingCard.group,
				status: chargingCard.status,
				token: chargingCard.token
			}]
		}) : cp);
	});
};
const removeIdtagFromCharger = (chargepointLabel: string, chargingCard: IdTag) => {
	queryClient.setQueryData<BackendChargePoint[] | undefined>("chargePoints", (data) => {
		if (!data) {
			return data;
		}
		return data.map(cp => cp.externalId === chargepointLabel ? ({
			...cp,
			idtags: cp.idtags.filter(idtag => idtag.externalId !== chargingCard.externalId)
		}) : cp);
	});
};

const ChargePointActionsService = {
	startTransaction,
	stopTransaction,
	postSaveSchedule,
	resetCharger,
	updateConnectorStatus,
	updateConnectorStatusById,
	updateActiveChargerPointStatusQueryData,
	getInitialEnableState,
	toggleEnableScheduler,
	updateEnableStateById,
	toggleOwnership,
	updateChargePointsOwnershipById,
	removeIdtagFromCharger,
	addIdtagFromCharger
};

export default ChargePointActionsService;
