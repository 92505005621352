import EstonianTranslations from "@app/i18n/ee";
import EnglishTranslations from "@app/i18n/en";
import { setLanguage } from "@reducers/appReducer";
import { store } from "@app/store";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
	en: {
		translation: EnglishTranslations
	},
	ee: {
		translation: EstonianTranslations
	}
};

export const initTranslations = () => {
	const dispatch = store.dispatch;
	i18n.on("languageChanged", (language) => {
		dispatch(setLanguage(language));
	});
	return i18n.use(initReactI18next).init({
		resources,
		lng: store.getState().app.language
	});
};

export default i18n;
