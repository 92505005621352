import { BackendUser, User, UserPayload } from "@interface/userInterface";
import i18n from "@app/i18n";
import { Group } from "@interface/groupInterface";

export const parseUser = (user: BackendUser): User => {
	return {
		id: user.id,
		firstName: user.firstName,
		lastName: user.lastName,
		fullName: getFullName(user),
		status: user.status,
		email: user.email,
		creationDate: "1 April 2022",
		groupLabel: user.userGroup?.name || i18n.t("groups.ungrouped"),
		group: user.userGroup
	};
};
export const parseUsers = (users: BackendUser[]): User[] => {
	return users.map(parseUser);
};

export const getFullName = (user) => {
	return `${user.firstName} ${user.lastName}`;
};

export const buildUpdateUserPayload = (user: User, group: Group): UserPayload => {
	return {
		id: user.id,
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		userGroup: group
	};
};
