import i18n from "i18next";
import { Measurement } from "@interface/chargersInterface";
import { CHARGE_POINT_MAIN_CONTROLLER } from "@model/chargersModel";
import ElintaHomeBoxSlimImage from "@assets/images/Elinta_HomeBox_Slim.png";
import ElintaCitychargeMiniImage from "@assets/images/Elinta_Citycharge_Mini_2.png";
import ElintaCitychargeImage from "@assets/images/Elinta_Citycharge_V2.png";
import AbbTerraImage from "@assets/images/ABB_Terra_AC.png";

export const getCombinedChargedValue = (measurements: Measurement[]) => {
	if (measurements.length === 0 || (measurements.length === 1 && measurements[0].connectorId === Number(CHARGE_POINT_MAIN_CONTROLLER))) {
		return i18n.t("common.noData");
	}

	const rawValueSum = measurements.reduce((sum: number, item: Measurement) => {
		const value = Number(item.meterValues.EnergyActiveImportRegisterOutlet?.value);

		return (value && item.connectorId !== Number(CHARGE_POINT_MAIN_CONTROLLER)) ? (sum + value) : sum;
	}, 0);

		return `${Number(rawValueSum) / 1000} kWh`;
};

export const getChargerImageByModel = (brand: string, model: string): string => {
	const defaultImage = ElintaHomeBoxSlimImage;
	const _brand = brand?.toLowerCase();
	const _model = model?.toLowerCase();

	const brandType = {
		ELINTA: "elinta charge",
		ABB: "abb"
	};

	const modelType = {
		HOMEBOX_SLIM: "homebox slim",
		CITYCHARGE_MINI: "citycharge mini 2",
		MINI: "mini 2",
		CITYCHARGE: "citycharge v2",
		TERRA: "terra"
	};

	const strategies = [
		{
			condition: _brand === brandType.ELINTA && _model === modelType.HOMEBOX_SLIM,
			image: ElintaHomeBoxSlimImage
		},
		{
			condition: _brand === brandType.ELINTA && (_model === modelType.CITYCHARGE_MINI || _model === modelType.MINI),
			image: ElintaCitychargeMiniImage
		},
		{
			condition: _brand === brandType.ELINTA && _model === modelType.CITYCHARGE,
			image: ElintaCitychargeImage
		},
		{
			condition: _brand === brandType.ABB && _model === modelType.TERRA,
			image: AbbTerraImage
		}
	];

	return strategies.find((item) => item.condition)?.image || defaultImage;
};
