import { FC } from "react";
import { UserInfo } from "@pages/UserDetailsPage/components/UserSummary/UserInfo";
import { UserActions } from "@pages/UserDetailsPage/components/UserSummary/UserActions";
import { User } from "@interface/userInterface";
import styles from "./UserSummary.module.scss";

interface UserSummaryProps {
	user: User;
}

export const UserSummary: FC<UserSummaryProps> = ({ user }) => {

	return (
		<div
			className={styles.summary}
			data-testid="user-summary"
		>
			<div className={styles.image} />

			<UserInfo user={user} />
			<UserActions user={user} />
		</div>
	);
};
