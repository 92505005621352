export const endpoints = {
	auth: {
		login: "/login",
		logout: "/logout"
	},
	assets: {
		chargePoints: "/chargepoints",
		chargePointById: (chargerId) => `/chargers/${chargerId}`,
		assignGroup: (chargerId, chargerGroupId) => `/chargers/${chargerId}/chargergroups/${chargerGroupId}`,
		unassignGroup: (chargerId) => `/chargers/${chargerId}/chargergroups`,
		setOwnership: (chargerId) => `/chargepoints/${chargerId}/ownership`,
		setAddress: (chargerId) => `/chargepoints/${chargerId}/address`,
		idtags: "/idtags",
		idtag: (externalId) => `/idtags/${externalId}`,
		updateIdTag: (externalId) => `/idtags/${externalId}`
	},
	centralSystem: {
		triggerAction: "/centralsystems",
		clearChargingProfile: "/centralsystems/clearchargingprofile"
	},
	scheduler: {
		scheduleSettings: "/scheduleSettings",
		deleteScheduleSettingsById: (chargerId, connectorId, schedulePreferenceType) =>
			`/chargepoints/${chargerId}/connectors/` +
			`${connectorId}/scheduleSettings?schedulePreferenceType=${schedulePreferenceType}`
	},
	chargers: {
		chargePointsStatus: "/chargepoints/chargePointStatus",
		chargePointStatusById: (chargerId) => `/chargepoints/${chargerId}/chargePointStatus`,
		metersById: (chargerId) => `/chargepoints/${chargerId}/meters`
	},
	groups: {
		chargersGroups: "/chargergroups",
		removeChargersGroup: (groupId) => `/chargergroups/${groupId}`,
		idtagsGroups: "/idtaggroups",
		removeidTagsGroup: (groupId) => `/idtaggroups/${groupId}`,
		userGroups: "/usergroups",
		removeUserGroup: (groupId) => `/usergroups/${groupId}`
	},
	tariffs: {
		chargingTariffs: "/chargingtariffs",
		chargingTariffsByYear: (year) => `/chargingtariffs?year=${year}`,
		updateChargingTariff: (id) => `/chargingtariffs/${id}`
	},
	users: {
		users: "/users",
		user: (userId) => `/users/${userId}`,
		assignUserGroup: (userId, userGroupId) => `/users/${userId}/usergroups/${userGroupId}`,
		unassignUserGroup: (userId) => `/users/${userId}/usergroups`
	},
	userChargers: {
		userChargers: "/customerchargers",
		userCharger: (userChargerId) => `/customerchargers/${userChargerId}`
	},
	localList: {
		resetLocalList: "/locallist",
		chargerLocalList: (chargerId, idtagId) => `/locallist/charger/${chargerId}/idtag/${idtagId}`
	},
	account: {
		activate: (link) => `/users/activation/${link}`
	},
	consumptionReport: {
		download: (month, year) => `/consumptionReport?month=${month}&year=${year}`,
		downloadForCustomer: (month, year, customerId) => `/consumptionReport/${customerId}?month=${month}&year=${year}`
	},
	customers: {
		get: (externalId) => `/customers/${externalId}`
	}
};
