import queryClient from "../../queryClient";
import { User, UserChargerUpdatePayload, UserChargerRole, UserChargerCreatePayload } from "@interface/userInterface";

const updateUserQuery = (user: User) => {
	queryClient.setQueryData<User[] | undefined>("users", (users) => {
		if (!users) {
			return users;
		}
		return updateUser(users, user);
	});
	queryClient.setQueryData<User | undefined>(["user", user.id], user);
};

const buildUserChargerUpdatePayload = (formValues): UserChargerUpdatePayload => {
	return {
		externalId: formValues?.externalId || "",
		customerExternalId: formValues?.customerExternalId || "",
		fullName: formValues?.fullName || "",
		email: formValues?.email || "",
		role: formValues?.isOwner ? UserChargerRole.OWNER : UserChargerRole.USER
	};
};

const buildUserChargerCreatePayload = (formValues): UserChargerCreatePayload => {
	return {
		externalId: formValues?.externalId || "",
		customerExternalId: formValues?.customerExternalId || "",
		fullName: formValues?.fullName || "",
		email: formValues?.email || ""
	};
};

const updateUser = (users: User[], newUser: User) => users.map((user) => user.group === newUser.group ? newUser : user);

const UserService = {
	updateUserQuery,
	buildUserChargerCreatePayload,
	buildUserChargerUpdatePayload
};

export default UserService;
