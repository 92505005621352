import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { Routing } from "./app/routing/Routing";
import { ScrollToTop } from "./app/routing/components/ScrollTop";

function App() {
	return (
		<Router>
			<ScrollToTop />
			<Routing />
			<ToastContainer />
			<ReactTooltip id="tooltip" place="top" type="dark" effect="solid" multiline={true} />
		</Router>
	);
}

export default App;
