import { FC } from "react";
import { chargerGroupsApi } from "@app/api/chargerGroupsApi";
import { ChargePoint } from "@app/interface/chargersInterface";
import { BackendGroup, GroupsModalProps, GroupTypes } from "@app/interface/groupInterface";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { GroupManagementModal } from "./GroupManagementModal";

export const ChargersGroupManagementModal: FC<GroupsModalProps> = ({ isOpen, onClose }) => {
	const queryClient = useQueryClient();
	const params: { chargerId: string } = useParams();

	const removeChargerpointGroup: (chargerPoint: ChargePoint, groupId?: string) => ChargePoint = (
		chargerPoint,
		groupId
	) => {
		return {
			...chargerPoint,
			chargerGroup: chargerPoint.chargerGroup?.id === groupId ? null : chargerPoint.chargerGroup,
			groupLabel: chargerPoint.chargerGroup?.id === groupId ? null : chargerPoint.groupLabel
		};
	};

	const updateChargerpointGroup: (chargerPoint: ChargePoint, group: BackendGroup) => ChargePoint = (
		chargerPoint,
		group
	) => {
		const { id, name } = group;
		return {
			...chargerPoint,
			chargerGroup: chargerPoint.chargerGroup?.id === id ? { id, name } : chargerPoint.chargerGroup,
			groupLabel: chargerPoint.chargerGroup?.id === id ? name : chargerPoint.groupLabel
		};
	};
	const onRemoved = (removedGroupId: string) => {
		if (params.chargerId) {
			queryClient.setQueryData<ChargePoint | undefined>(["activeCharger", params.chargerId], (data) => {
				if (!data) {
					return data;
				}
				return removeChargerpointGroup(data, removedGroupId);
			});
		}

		queryClient.setQueryData<ChargePoint[] | undefined>("chargePoints", (data) => {
			if (!data) {
				return data;
			}
			return data.map((chargePoint) => {
				return removeChargerpointGroup(chargePoint, removedGroupId);
			});
		});
	};
	const onUpdated = (group: BackendGroup) => {
		if (params.chargerId) {
			queryClient.setQueryData<ChargePoint | undefined>(["activeCharger", params.chargerId], (data) => {
				if (!data) {
					return data;
				}
				return updateChargerpointGroup(data, group);
			});
		}

		queryClient.setQueryData<ChargePoint[] | undefined>("chargePoints", (data) => {
			if (!data) {
				return data;
			}
			return data.map((chargePoint) => {
				return updateChargerpointGroup(chargePoint, group);
			});
		});
	};
	return (
		<GroupManagementModal
			isOpen={isOpen}
			onClose={onClose}
			groupKey={GroupTypes.CHARGER}
			getGroups={chargerGroupsApi.getGroups}
			updateGroup={chargerGroupsApi.updateGroups}
			removeGroup={chargerGroupsApi.removeGroups}
			addGroup={chargerGroupsApi.addGroups}
			onRemoved={onRemoved}
			onUpdated={onUpdated}
		/>
	);
};
