import React, { FC } from "react";
import cs from "classnames";
import styles from "./Select.module.scss";
import { ErrorLabel } from "@components/Form/ErrorLabel/ErrorLabel";
import ReactSelect from "react-select";
import { SelectProps } from "./Select";

export const SearchableSelect: FC<SelectProps> = ({ label, value, onChange, options, error }) =>
{
	const selectStyles = {
		control: (base) => ({
			...base,
			borderRadius: "4rem",
			padding: "0.5rem 0.5rem 0.5rem 2rem",
			borderColor: error ? "red" : "#ecf2f2",
			boxShadow: "0",
			"&:hover": {
				borderColor: error ? "red" : "#ecf2f2"
			}
		})
	};

	return (
		<>
			<div
				className={cs(styles.container, {
					[styles.error]: !!error
				})}
			>
				{label && <label>{label}</label>}
				<div className={styles.selectContainer} data-testid="searchable-select">
					<ReactSelect
						value={options ? options.find(o => o?.value === value) : []}
						onChange={onChange}
						options={options || []}
						styles={selectStyles}
					/>
				</div>

				{error && <ErrorLabel error={error.message} />}
			</div>
		</>
	);
};
