import { FC } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { chargePointsApi } from "@api/chargePointsApi";
import { Loader } from "@components/Loader/Loader";
import { getCombinedChargedValue } from "@services/chargerDetailsService";
import config from "@app/config/config";
import styles from "./ChargerDetails.module.scss";

export interface MetersComponentProps {
	chargerId: string;
}

export const MetersComponent: FC<MetersComponentProps> = ({ chargerId }) => {
	useTranslation();

	const { data: chargedValue, isLoading } = useQuery(
		["activeCharger", "meters"],
		() => chargePointsApi.getChargePointMetersById(chargerId),
		{
			select: (data) => getCombinedChargedValue(data),
			refetchInterval: config.refetchServerDataInterval
		}
	);

	return (
		<div className={cs(styles.value, styles.relative)}>
			{isLoading && (
				<div className={cs(styles.icon, styles.loader)}>
					<Loader />
				</div>
			)}

			{!isLoading && (
				<div data-testid="charged-value">{chargedValue}</div>
			)}
		</div>
	);
};
