import { FC } from "react";
import cs from "classnames";
import styles from "./Loader.module.scss";

interface LoaderProps {
	absolute?: boolean;
}

export const Loader: FC<LoaderProps> = ({ absolute = false }) => (
	<div
		className={cs(styles.container, {
			[styles.absolute]: absolute
		})}
		data-testid="loader"
	>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path
				d="M12 22.5A10.5 10.5 0 1122.5 12 10.51 10.51 0 0112 22.5zm0-20a9.5 9.5 0 109.5 9.5A9.51 9.51 0 0012 2.5z"
				opacity=".25"
			/>
			<path d="M22 12.5a.5.5 0 01-.5-.5A9.51 9.51 0 0012 2.5a.5.5 0 010-1A10.51 10.51 0 0122.5 12a.5.5 0 01-.5.5z" />
		</svg>
	</div>
);
