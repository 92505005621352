import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usersApi } from "@api/usersApi";
import { Container } from "@components/Container/Container";
import { Layout } from "@components/Layout/Layout";
import { Loader } from "@components/Loader/Loader";
import { UserNavigation } from "@pages/UserDetailsPage/components/UserNavigation/UserNavigation";
import { UserSummary } from "@pages/UserDetailsPage/components/UserSummary/UserSummary";
import NotificationService from "@services/notificationService";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { parseUser } from "@model/userModel";
import styles from "@pages/ChargingCardDetailsPage/components/ChargingCardSummary/ChargingCardSummary.module.scss";

export const UserDetailsPage = () => {
	const history = useHistory();
	const param: { userId: string } = useParams();
	const { t } = useTranslation();

	const { data: user, isLoading } = useQuery(["user", param.userId], () => usersApi.getUserById(param.userId), {
		select: parseUser,
		onError: () => {
			NotificationService.displayError(t("messages.serverConnectionError"));
			history.push(Route.USERS);
		}
	});

	const showLoader = () => {
		return (
			<div className={styles.loadingTableContainer}>
				<Loader />
			</div>
		);
	};

	return (
		<Layout>
			<Container>
				{isLoading && showLoader()}
				{user &&
					<>
						<UserNavigation />
						<UserSummary user={user} />
					</>
				}
			</Container>
		</Layout>
	);
};
