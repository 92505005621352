import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CellProps } from "react-table";
import config from "@app/config/config";
import { ChargePoint } from "@interface/chargersInterface";
import { parseChargePoints } from "@model/chargersModel";
import StatusService, { ActionsCellStatus } from "@services/statusService";
import { chargePointsApi } from "@api/chargePointsApi";
import { schedulerApi } from "@api/schedulerApi";
import { Container } from "@components/Container/Container";
import { ScheduleChargingModal } from "@modals/ScheduleChargingModal/ScheduleChargingModal";
import { ScheduleModalState } from "@modals/ScheduleChargingModal/scheduleChargingModalInterface";
import { useModal } from "@components/Modal/Modal";
import { SelectFilter } from "@components/Table/components/SelectFilter/SelectFilter";
import { Table } from "@components/Table/Table";
import { GroupRow, SingleRow } from "@components/Table/TableRows";
import { Legend } from "@components/Table/components/Legend/Legend";
import { SettingIcon, Warning } from "@components/Icons/Icons";
import { StatusDot } from "@components/StatusDot/StatusDot";
import { nameSort } from "@components/Table/utils";
import { ConnectorsRows } from "./ChargerTableRows";
import { ActionsCell, GroupCell, MaxChargingCell, NameCell, StatusCell } from "./ChargerTableCells";
import { renderRow } from "./utils";
import styles from "./ChargersTable.module.scss";
import { TableLoader } from "@app/components/Table/components/TableLoader/TableLoader";
import { Button } from "@components/Button/Button";
import { TariffManagementModal } from "@modals/TariffManagementModal/TariffManagementModal";
import { TariffManagementModalState } from "@modals/TariffManagementModal/TariffManagementInterface";

export const ChargersTable = () => {
	const { t } = useTranslation();
	const { openModal, onClose: closeModal, ...groupModalProps } = useModal();

	const [modalState, setModalState] = useState<ScheduleModalState | undefined>(undefined);
	const [tariffManagementModalState, setTariffManagementModalState] = useState<TariffManagementModalState | undefined>(undefined);

	const { data: statusData } = useQuery("chargePointsStatus", chargePointsApi.getChargePointsStatus, {
		refetchInterval: config.refetchServerDataInterval
	});

	const { data: scheduleData } = useQuery("scheduleData", schedulerApi.getScheduleSettings, {
		refetchInterval: config.refetchServerDataInterval
	});

	const { data } = useQuery("chargePoints", chargePointsApi.getChargePoints, {
		select: (_data) => parseChargePoints(_data, statusData, scheduleData),
		enabled: !!statusData && !!scheduleData
	});

	const _nameSort = useMemo(nameSort, []);

	const openScheduleModal = (charger, connectorId) => {
		setModalState({ charger, connectorId });
		openModal();
	};
	const closeScheduleModal = () => {
		setModalState(undefined);
		closeModal();
	};

	const openTariffManagementModal = () => {
		setTariffManagementModalState({ active: true });
		openModal();
	};
	const closeTariffManagementModal = () => {
		setTariffManagementModalState(undefined);
		closeModal();
	};

	const filterGroupCell = (cells) => cells.filter((cell) => cell.column.id !== "groupLabel");

	if (!data) {
		return (
			<TableLoader/>
		);
	}

	return (
		<div>
			<Table<ChargePoint>
				filtersStyles={styles.customFilters}
				data={data}
				HeaderComponent={
					<div className={styles.headerRight}>
						<div className={styles.newCardContainer}>
							<Button color="primary" buttonType="solid" onClick={openTariffManagementModal}
									data-testid="chargers-page-open-tariff-modal">
								<SettingIcon/>
								{t("chargingTariff.label")}
							</Button>
						</div>
					</div>
				}
				columns={[
					{
						Header: `${t("chargersTable.charger")}`,
						accessor: "externalId",
						filter: "includes",
						sortType: _nameSort,
						Cell: NameCell
					},
					{
						Header: `${t("chargersTable.maxChargingPower")}`,
						accessor: "maxChargingPowerKw",
						Cell: ({ cell: { value } }: CellProps<ChargePoint>) => (
							<MaxChargingCell chargingPower={value} />
						)
					},
					{
						Header: `${t("groups.chargerGroup")}`,
						accessor: "groupLabel",
						filter: "exact",
						Cell: ({ cell: { value } }: CellProps<ChargePoint>) => <GroupCell group={value} />,
						Filter: SelectFilter
					},
					{
						Header: `${t("chargersTable.status")}`,
						accessor: "connectorStatus",
						filter: "exact",
						Filter: SelectFilter,
						Cell: ({
							row: {
								original: { connectorStatus, connected }
							}
						}: CellProps<ChargePoint>) => (
							<StatusCell status={StatusService.getActionsCellStatus(connectorStatus, connected)} />
						)
					},
					{
						Header: `${t("chargersTable.activities")}`,
						id: "activities",
						disableSortBy: true,
						Cell: ({
							row: {
								original: charger
							}
						}: CellProps<ChargePoint>) => (
							<ActionsCell
								status={StatusService.getActionsCellStatus(charger.connectorStatus, charger.connected)}
								charger={charger}
								openScheduleModal={openScheduleModal}
							/>
						)
					}
				]}
				filters={["groupLabel", "connectorStatus"]}
				groupBy="groupLabel"
				CustomRowRenderer={({ page, prepareRow, sortBy }) => {
					let rowIndex = 0;
					return (
						<>
							{page.map((row) => {
								prepareRow(row);
								const { id, cells, isGrouped, original } = row;
								if (isGrouped) {
									rowIndex = 0;
								} else {
									rowIndex++;
								}

								return (
									<React.Fragment key={id}>
										{renderRow(
											isGrouped,
											<GroupRow row={row} cells={cells} sortBy={sortBy} rowIndex={rowIndex} />,
											<SingleRow
												row={row}
												cells={filterGroupCell(cells)}
												sortBy={sortBy}
												groupBy={"group"}
												rowIndex={rowIndex}
											/>,
											original && original.connectors.length > 0
												? ConnectorsRows({ chargePoint: original, rowIndex, openScheduleModal })
												: null
										)}
									</React.Fragment>
								);
							})}
						</>
					);
				}}
			/>

			{modalState && (
				<ScheduleChargingModal
					{...groupModalProps}
					onClose={closeScheduleModal}
					charger={modalState.charger}
					connectorId={modalState.connectorId}
				/>
			)}

			{tariffManagementModalState && (
				<TariffManagementModal
					{...groupModalProps}
					onClose={closeTariffManagementModal}
				/>
			)}

			<Container>
				<Legend
					elements={[
						{ Component: <StatusDot status={ActionsCellStatus.AVAILABLE} />, label: t("status.available") },
						{ Component: <StatusDot status={ActionsCellStatus.CHARGING} />, label: t("status.charging") },
						{ Component: <StatusDot status={ActionsCellStatus.OCCUPIED} />, label: t("status.occupied") },
						{ Component: <StatusDot status={ActionsCellStatus.UNAVAILABLE} />, label: t("status.notAvailable") },
						{ Component: <Warning />, label: t("status.warning") }
					]}
				/>
			</Container>
		</div>
	);
};
