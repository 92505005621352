import { DataError } from "@components/Table/components/DataError/DataError";
import { SelectFilter } from "@components/Table/components/SelectFilter/SelectFilter";
import { Table } from "@components/Table/Table";
import { nameSort } from "@components/Table/utils";
import NotificationService from "@services/notificationService";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Cell } from "react-table";
import { TableLoader } from "@app/components/Table/components/TableLoader/TableLoader";
import styles from "./UserChargersTable.module.scss";
import { userChargersApi } from "@api/userChargersApi";
import { UserCharger, UserChargerRole } from "@interface/userInterface";
import { useModal } from "@components/Modal/Modal";
import { PenIcon, PlusIcon } from "@components/Icons/Icons";
import { Button } from "@components/Button/Button";
import { chargePointsApi } from "@api/chargePointsApi";
import { UserChargersModal } from "@modals/UserChargersModal/UserChargersModal";
import moment from "moment";
import { endpoints } from "@api/endpoints";
import { DownloadReportDropdown, ReportType } from "@pages/UserChargersPage/components/DownloadReportDropdown/DownloadReportDropdown";

export const UserChargersTable = () => {
	const { t } = useTranslation();

	const refDate = moment().subtract(1, "month");
	const month = refDate.format("MMMM");
	const year = refDate.format("YYYY");

	const { openModal, onClose: closeModal, ...modalProps } = useModal();
	const [modalState, setModalState] = useState<UserCharger | undefined>(undefined);

	const openUserChargerModal = (userCharger) => {
		setModalState(userCharger);
		openModal();
	};

	const closeUserChargerModal = () => {
		setModalState(undefined);
		closeModal();
	};

	const { data, isLoading, isError, refetch } = useQuery("userChargers", userChargersApi.getUserChargers, {
		onError: () => {
			NotificationService.displayError(t("messages.serverConnectionError"));
		}
	});

	const { data: chargerOptions } = useQuery("chargePoints", chargePointsApi.getChargePoints, {
		select: (chargePoints) => chargePoints.map(cp => {
			return {
				value: cp.externalId,
				label: cp.externalId
			};
		})
	});

	const _nameSort = useMemo(nameSort, []);

	if (isLoading) {
		return (
			<TableLoader />
		);
	}

	if (isError) {
		return <DataError refetch={refetch} />;
	}

	return (
		<div>
			<Table<UserCharger>
				filtersStyles={styles.customFilters}
				data={data || []}
				globalFilterLabel={t("common.search")}
				HeaderComponent={
					<div className={styles.headerRight}>
						<div className={styles.newCardContainer}>

							<DownloadReportDropdown label={t("report.download")} reportTypes={[ReportType.PDF, ReportType.CSV]}
													endpoint={endpoints.consumptionReport.download(month, year)}/>

							<Button color="primary" buttonType="solid"
									onClick={() => openUserChargerModal({})}
									data-testid="user-charger-add-button">
								<PlusIcon />
								{t("common.add")}
							</Button>
						</div>
					</div>
				}
				columns={[
					{
						Header: <Trans t={t}>userChargers.charger</Trans>,
						accessor: "externalId",
						filter: "exact",
						Filter: SelectFilter,
						sortType: _nameSort
					},
					{
						Header: <Trans t={t}>userChargers.customerId</Trans>,
						accessor: "customerExternalId",
						sortType: _nameSort
					},
					{
						Header: <Trans t={t}>userChargers.role</Trans>,
						accessor: "role",
						sortType: _nameSort
					},
					{
						Header: <Trans t={t}>userChargers.customerFullName</Trans>,
						accessor: "fullName",
						sortType: _nameSort
					},
					{
						Header: <Trans t={t}>userChargers.customerEmail</Trans>,
						accessor: "email",
						sortType: _nameSort
					},
					{
						Header: <Trans t={t}>common.manage</Trans>,
						id: "manageButton",
						disableSortBy: true,
						Cell: ({ row }: Cell<UserCharger>) => (
							<div className={styles.primaryIcon}
								data-testid={`user-charger-edit-button-${row.original.id}`}
								onClick={() => openUserChargerModal(row.original)}>
								<PenIcon />
							</div>
						)
					},
					{
						Header: <Trans t={t}>report.download</Trans>,
						id: "downloadReport",
						disableSortBy: true,
						Cell: ({ row }: Cell<UserCharger>) =>
							row.original.role === UserChargerRole.OWNER &&
							(<DownloadReportDropdown endpoint={endpoints.consumptionReport.downloadForCustomer(month, year, row.original.customerExternalId)}
													reportTypes={[ReportType.PDF, ReportType.CSV]} />)
					}
				]}
				filters={["externalId"]}
			/>

			{modalState && (
				<UserChargersModal {...modalProps} userCharger={modalState} onClose={closeUserChargerModal}
									chargers={chargerOptions} refetchTableData={refetch}
									userChargers={data}/>
			)}
		</div>
	);
};
