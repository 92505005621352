import { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface TableLinkProps {
	to: string;
}

export const TableLink: FC<TableLinkProps> = ({ to, children }) => {

	const history = useHistory();
	const location = useLocation();

	const handleClick = () => {
		const searchParams = new URLSearchParams(location.search);

		if (window.scrollY > 0) {
			searchParams.set("scrollPos", String(window.scrollY));
		}

		const searchString = searchParams.toString();

		if (searchString !== "") {
			return history.push(`${to}?${searchString}`);
		}

		return history.push(to);
	};

	return (
		<a onClick={handleClick} data-testid="table-link">{children}</a>
	);
};
