import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
	TariffManagementForm,
	TariffManagementModalProps
} from "@modals/TariffManagementModal/TariffManagementInterface";
import { Modal } from "@components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { TextField } from "@components/Form/TextField/TextField";
import styles from "./TariffManagementModal.module.scss";
import { chargingTariffApi } from "@api/chargingTariffApi";
import {
	findCurrentChargingTariff, findHistoryChargingTariffs,
	getInitialTariffForm,
	updateTariffManagementForm
} from "@modals/TariffManagementModal/TariffManagementModalService";
import { Cell } from "react-table";
import { Table } from "@components/Table/Table";
import moment from "moment";
import { Loader } from "@components/Loader/Loader";
import cs from "classnames";
import { PriceCell } from "@modals/TariffManagementModal/TariffManagementModalHistoryTableCells";
import { EditActionIcons } from "@components/EditActionIcons/EditActionIcons";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { floatingPointNumberRegex } from "@services/inputValidation";
import i18n from "i18next";
import { Button } from "@components/Button/Button";

export const chargingTariffPriceSchema = yup
	.object({
		price: yup.string()
			.matches(floatingPointNumberRegex, () => i18n.t("chargingTariff.modal.validFloatingPointNumberError"))
			.test("properRange", () => i18n.t("chargingTariff.modal.properRangeError"), item => {
				if (item === undefined) {
					return false;
				}

				const formattedNumber = Number(item.replace(",", "."));
				if (!Number.isFinite(formattedNumber)) {
					return false;
				}

				return formattedNumber > 0 && formattedNumber <= 99999;
			})
	})
	.required();

export const TariffManagementModal: FC<TariffManagementModalProps> = (
	{
		isOpen,
		onClose
	}
) => {

	const { t } = useTranslation();

	const currentYear = new Date().getFullYear();
	const minYear = 2021;
	const yearsArray = Array.from(Array(currentYear - minYear + 1).keys()).map(i => i + minYear).reverse();

	const [tariffManagementForm, setTariffManagementForm] = useState<TariffManagementForm>(getInitialTariffForm(""));
	const [currentTariffPrice, setCurrentTariffPrice] = useState<string | undefined>(undefined);
	const [pricesFromYear, setPricesFromYear] = useState<TariffManagementForm[]>([]);
	const [selectedYear, setSelectedYear] = useState<number>(currentYear);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [lastHistoricalPriceSave, setLastHistoricalPriceSave] = useState<Date>(new Date());

	const refreshDataInModal = function (fetchResult, forceRefresh = false) {
		setPricesFromYear(findHistoryChargingTariffs(fetchResult));
		if (forceRefresh || (currentYear === selectedYear && !currentTariffPrice)) {
			const tariff = findCurrentChargingTariff(fetchResult);
			setCurrentTariffPrice(tariff);
			setTariffManagementForm(updateTariffManagementForm(tariffManagementForm, "price", tariff));
		}
	};

	useEffect(() => {
		chargingTariffApi.getTariffsByYear(selectedYear).then(result => {
			refreshDataInModal(result);
			setIsLoading(false);
		});
	}, [selectedYear, lastHistoricalPriceSave]);

	const setSavedTariffPrice = () => {
		setTariffManagementForm(updateTariffManagementForm(tariffManagementForm, "price", currentTariffPrice));
	};

	const submitTariff = (form) => {
		chargingTariffApi.submitTariff(form)
			.then(() => chargingTariffApi.getTariffsByYear(currentYear))
			.then(result => refreshDataInModal(result, true));
	};

	const priceCellRenderFunction = useCallback(({ row }: Cell<TariffManagementForm>) => (
		<PriceCell id={row.original.id} price={row.original.price}
				setLastHistoricalPriceSave={setLastHistoricalPriceSave}/>
	), []);

	const { control, reset, handleSubmit } = useForm({
		defaultValues: useMemo(() => tariffManagementForm, [tariffManagementForm]),
		resolver: yupResolver(chargingTariffPriceSchema)
	});

	useEffect(() => {
		reset(tariffManagementForm);
	}, [tariffManagementForm]);

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			Header={t("chargingTariff.modal.header")}
			Content={
				<div data-testid="tariff-management-modal">

					<form onSubmit={handleSubmit(submitTariff)}>
					<div className={styles.priceSection}>

						<Controller
							name="price"
							control={control}
							render={({ field: { onChange, value }, fieldState: { error, isDirty } }) => (
								<>
									{t("chargingTariff.modal.electricityPrice")}
									<TextField
										type="number"
										data-testid="tariff-price-input"
										value={value}
										error={error}
										onChange={onChange}
									/>
									{t("chargingTariff.modal.unit")}

									<EditActionIcons showIcons={isDirty}
													iconNameTestPrefix="tariff-price" save={handleSubmit(submitTariff)}
													rollback={setSavedTariffPrice}/>
								</>
							)}
						/>
					</div>
					</form>

					<div className={styles.hintSection}>
						{t("chargingTariff.modal.hint")}
					</div>

					<div className={styles.historySection} data-testid="tariff-price-history-section">
						<h3>{t("chargingTariff.modal.history")}</h3>

						<div>

							<nav className={styles.yearNav}>
								<ul className={styles.yearMenu}>
									{yearsArray.map(year => {
										return (<li key={year}
													data-testid={`tariff-price-history-year-item-${year}`}
													className={cs(styles.yearItem, year === selectedYear ? styles.activeYear : "")}>
											<a className={styles.yearLabel}
												data-testid={`tariff-price-history-year-link-${year}`}
												onClick={() => setSelectedYear(year)}>{year}</a>
										</li>);
									})}
								</ul>
							</nav>

							{isLoading ? <div className={styles.loaderContainer}><Loader/></div> :
								<Table<TariffManagementForm>
									key="chargingTariffHistory"
									data-testid="tariff-price-history-table"
									showGlobalSearch={false}
									filtersStyles={styles.displayNone}
									data={pricesFromYear}
									noInternalPadding={true}
									fixedPageSize={4}
									blockPageSizeChanging={true}
									disableUrlHandling={true}
									columns={[
										{
											Header: `${t("chargingTariff.modal.period")}`,
											accessor: "startDate",
											Cell: ({ row }: Cell<TariffManagementForm>) => {

												const inputDateFormat = "YYYY-MM-DD";
												const outputDateFormat = "DD.MM";

												const formattedStartDate = moment(row.original.startDate, inputDateFormat).format(outputDateFormat);
												const formattedEndDate = moment(row.original.endDate, inputDateFormat).format(outputDateFormat);

												return (
													<div data-testid={`tariff-price-history-date-${row.original.id}`}>
														{formattedStartDate} - {formattedEndDate}
													</div>
												);
											}
										},
										{
											Header: `${t("chargingTariff.modal.priceHeader")}`,
											accessor: "price",
											Cell: priceCellRenderFunction
										}
									]}
								/>
							}
						</div>

						<footer className={styles.footer}>
							<Button color="primary" buttonType="solid" onClick={onClose}>
								{t("common.close")}
							</Button>
						</footer>
					</div>
				</div>
			}/>
	);
};
