import React, { FC, useState } from "react";
import { Modal, useModal } from "@components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { TextField } from "@components/Form/TextField/TextField";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserCharger, UserChargerRole } from "@interface/userInterface";
import styles from "./UserChargersModal.module.scss";
import { Button } from "@components/Button/Button";
import { userChargersApi } from "@api/userChargersApi";
import NotificationService from "@services/notificationService";
import i18n from "i18next";
import { Switch } from "antd";
import UserService from "@services/userService";
import { customerApi } from "@api/customerApi";
import { ConfirmationModal } from "@components/ConfirmationModal/ConfirmationModal";
import { Customer } from "@interface/customerInterface";
import { SearchableSelect } from "@components/Form/Select/SearchableSelect";

const findRequiredErrorMessage = () => {
	return i18n.t("common.required");
};

const findMaxErrorMessage = (error) => {
	return i18n.t("common.maxError", { max: error.max });
};

export const userChargerSchema = yup
	.object({
		externalId: yup.string().required(findRequiredErrorMessage),
		customerExternalId: yup.string().max(100, findMaxErrorMessage).required(findRequiredErrorMessage),
		fullName: yup.string().max(255, findMaxErrorMessage).required(findRequiredErrorMessage),
		email: yup.string().max(255, findMaxErrorMessage)
			.email()
			.required(findRequiredErrorMessage)
	})
	.required();

interface UserChargersModalProps {
	userCharger: UserCharger;
	chargers: Array<{ value: string; label: string }>;
	isOpen: boolean;
	onClose: () => void;
	refetchTableData: () => void;
	userChargers: Array<UserCharger>;
}

export const UserChargersModal: FC<UserChargersModalProps> = (
	{
		userCharger,
		chargers,
		isOpen,
		onClose,
		refetchTableData,
		userChargers
	}
) => {

	const { t } = useTranslation();

	const { openModal, onClose: closeModal, ...modalProps } = useModal();
	const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null);
	const [fetchedCustomerData, setFetchedCustomerData] = useState<Customer | null>(null);

	const openConfirmationModal = (message, fetchedCustomer) => {
		setFetchedCustomerData(fetchedCustomer);
		setConfirmationMessage(message);
		openModal();
	};

	const closeConfirmationModal = () => {
		setFetchedCustomerData(null);
		setConfirmationMessage(null);
		closeModal();
	};

	const setCustomerData = () => {
		setValue("fullName", fetchedCustomerData?.fullName || "");
		setValue("email", fetchedCustomerData?.email || "");
	};

	const isRoleSwitchEnabled = () => userCharger?.role !== UserChargerRole.OWNER;

	const getHeader = (editMode) => {
		return editMode ? t("userChargers.editLink") : t("userChargers.addLink");
	};

	const getDeleteConfirmationMessage = () => {
		return userCharger?.role === UserChargerRole.OWNER ? t("userChargers.sureToDeleteOwner") : t("userChargers.sureToDelete");
	};

	const submitUserCharger = (form) => {
		const actionPromise = userCharger?.id ?
			userChargersApi.updateUserCharger(userCharger.id, UserService.buildUserChargerUpdatePayload(form)) :
			userChargersApi.createUserCharger(UserService.buildUserChargerCreatePayload(form));

		actionPromise.then(handleSuccess).catch(handleFailure);
	};

	const deleteUserCharger = () => {
		userChargersApi.deleteUserCharger(userCharger?.id).then(handleSuccess);
	};

	const handleSuccess = () => {
		NotificationService.displaySuccess(t("common.successSaveMessage"));
		refetchTableData();
		onClose();
	};

	const handleFailure = (response) => {
		if (response?.response?.data?.detail?.toString()?.includes("ixu_customer_charger_charger_id_tenant_role")) {
			NotificationService.displayError(t("userChargers.chargerOwnerErrorMessage"));
		} else if (response?.response?.data?.detail) {
			NotificationService.displayError(response?.response?.data?.detail?.toString());
		} else {
			NotificationService.displayError(t("userChargers.saveErrorMessage"));
		}
	};

	const checkCustomersData = (event) => {
		const enteredCustomerId = event.target.value;
		const existingCustomer = userChargers.find(existingUserCharger => existingUserCharger.customerExternalId === enteredCustomerId);

		if (existingCustomer) {
			handleExistingCustomerFound(existingCustomer);
		} else {
			customerApi.getCustomer(enteredCustomerId).then(data => {
				handleExistingCustomerFound(data);
			});
		}
	};

	const handleExistingCustomerFound = (customer) => {
		if (customer?.fullName || customer?.email) {
			const unknown = t("common.unknown");
			const message = t("userChargers.customerFound", {
				fullName: customer.fullName || unknown,
				email: customer.email || unknown
			});
			openConfirmationModal(message, customer);
		}
	};

	const { control, handleSubmit, setValue } = useForm({
		defaultValues: {
			externalId: userCharger.externalId || "",
			customerExternalId: userCharger.customerExternalId || "",
			fullName: userCharger.fullName || "",
			email: userCharger.email || "",
			isOwner: userCharger.role === UserChargerRole.OWNER
		},
		resolver: yupResolver(userChargerSchema)
	});

	return (
		<>
			<Modal
				onClose={onClose}
				isOpen={isOpen}
				Header={getHeader(!!userCharger.id)}
				Content={
					<>
						<div data-testid="user-charger-modal" className={styles.container}>
							<form onSubmit={handleSubmit(submitUserCharger)}>
								<div className={styles.formContainer}>
									<div className={styles.row}>
										<Controller
											name="externalId"
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<div data-testid="user-charger-external-id-select">
													<SearchableSelect
														label={t("userChargers.charger")}
														value={value}
														error={error}
														onChange={option => onChange(option?.value)}
														options={chargers}
													/>
												</div>
											)}
										/>
									</div>

									<div className={styles.row}>
										<Controller
											name="customerExternalId"
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<>
													<TextField
														type="text"
														label={t("userChargers.customerId")}
														data-testid="user-charger-customer-external-id-input"
														value={value}
														error={error}
														onChange={onChange}
														onBlur={checkCustomersData}
													/>
												</>
											)}
										/>
									</div>

									<div className={styles.row}>
										<Controller
											name="fullName"
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<>
													<TextField
														type="text"
														label={t("userChargers.customerFullName")}
														data-testid="user-charger-customer-full-name-input"
														value={value}
														error={error}
														onChange={onChange}
													/>
												</>
											)}
										/>
									</div>

									<div className={styles.row}>
										<Controller
											name="email"
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<>
													<TextField
														type="text"
														label={t("userChargers.customerEmail")}
														data-testid="user-charger-customer-email-input"
														value={value}
														error={error}
														onChange={onChange}
													/>
												</>
											)}
										/>
									</div>

									{userCharger.id && (<div className={styles.row}>
											<div>
												<label>{t("userChargers.owner")}</label>
												<div>
													<Controller
														name="isOwner"
														control={control}
														render={({ field: { onChange, value } }) => (
															<Switch checked={value} onChange={onChange}
																	disabled={!isRoleSwitchEnabled()}
																	data-testid="user-charger-role" />
														)}
													/>
												</div>
											</div>
										</div>
									)}
								</div>
							</form>
						</div>
					</>
				}
				Footer={
					<>
						{userCharger.id && (
							<Button data-testid="user-charger-delete-button"
									confirmationMessage={getDeleteConfirmationMessage()}
									buttonType="outline"
									onClick={deleteUserCharger}>
								{t("common.delete")}
							</Button>
						)}
						<Button data-testid="user-charger-save-button" color="primary" buttonType="solid"
								onClick={handleSubmit(submitUserCharger)}>
							{t("common.save")}
						</Button>
					</>
				}
			/>
			{confirmationMessage
				&& <ConfirmationModal {...modalProps} confirmationMessage={confirmationMessage}
									onClose={closeConfirmationModal}
									action={setCustomerData} />}
		</>
	);
};
