import { Select } from "@components/Form/Select/Select";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnInstance } from "react-table";
import { omitUnderscore } from "@components/Table/utils";

interface SelectFilterProps {
	column: ColumnInstance;
	labelParser?: (value: string) => string;
}

export const SelectFilter: FC<SelectFilterProps> = ({
	column: { filterValue, setFilter, preFilteredRows, id, Header },
	labelParser = (value) => value
}) => {
	const { t } = useTranslation();

	const handleChange = useCallback((option) => {
		setFilter(option?.value || undefined);
	}, []);

	const options = useMemo(() => {
		const _options = new Set<string>();
		preFilteredRows.forEach((row: { values: { [x: string]: string } }) => {
			_options.add(row.values[id]);
		});

		return [..._options.values()].map((option) => ({
			value: option,
			label: labelParser(option)
		}));
	}, [id, preFilteredRows]);

	return (
		<Select
			label={Header ? <>{Header}</> : undefined}
			labelParser={omitUnderscore}
			value={filterValue}
			theme="dark"
			onChange={handleChange}
			placeholder={t("chargersTable.all")}
			options={options}
		></Select>
	);
};
