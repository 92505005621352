import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@app/components/Container/Container";
import { Layout } from "@components/Layout/Layout";

export const HomePage: FC = () => {
	const { t } = useTranslation();

	return (
		<Layout>
			<Container>
				<h1>{t("navigation.dashboard")}</h1>
			</Container>
		</Layout>
	);
};
