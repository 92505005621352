import { TextField } from "@app/components/Form/TextField/TextField";
import { SearchIcon } from "@app/components/Icons/Icons";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAsyncDebounce } from "react-table";

export const GlobalFilter: FC<{
	preGlobalFilteredRows;
	globalFilter;
	setGlobalFilter: (filterValue: string | undefined) => void;
	globalFilterLabel?: string;
}> = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, globalFilterLabel }) => {
	const { t } = useTranslation();
	const DEBOUNCE_TIME = 200;

	const isMounted = useRef(true);
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = useState(globalFilter);

	const onChange = useAsyncDebounce((_value) => {
		if (isMounted.current) {
			setGlobalFilter(_value || undefined);
		}
	}, DEBOUNCE_TIME);
	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const getPlaceholder = () => {
		if (globalFilterLabel) {
			return globalFilterLabel;
		}
		return t("chargersTable.searchAmount", { count });
	};
	return (
		<TextField
			value={value || ""}
			onChange={(e) => {
				setValue(e.currentTarget.value);
				onChange(e.currentTarget.value);
			}}
			placeholder={getPlaceholder()}
			data-testid="table-search"
			Icon={SearchIcon}
		/>
	);
};
