import { endpoints } from "@api/endpoints";
import { GATEWAY_API } from "@api/http-common";
import { ActivationPayload } from "@app/interface/accountInterface";

export const accountApi = {
	login: ({ username, password }: { username: string; password: string }) => {
		return GATEWAY_API.post(endpoints.auth.login, null, {
			auth: {
				username,
				password
			}
		}).then((response) => {
			return response.data;
		});
	},
	checkActivationToken: (activationToken: string) => {
		return GATEWAY_API.get(endpoints.account.activate(activationToken)).then((response) => response.data);
	},
	activate: (activationToken: string) => (activationPayload: ActivationPayload) => {
		return GATEWAY_API.patch(endpoints.account.activate(activationToken), activationPayload).then((response) => response.data);
	},
	logout: () => GATEWAY_API.post(endpoints.auth.logout)
};
