import { FC, useState } from "react";
import { ChargePoint } from "@interface/chargersInterface";
import StatusService from "@services/statusService";
import { ChargerInfo } from "@pages/ChargerDetailsPage/components/ChargerSummary/ChargerInfo";
import { ChargerActions } from "@pages/ChargerDetailsPage/components/ChargerSummary/ChargerActions";
import { ConnectorStatus } from "@app/interface/chargerStatusInterface";
import styles from "./ChargerSummary.module.scss";

interface ChargerSummaryProps {
	charger: ChargePoint;
}

export const ChargerSummary: FC<ChargerSummaryProps> = ({ charger }) => {
	const [activeConnectorIndex, setActiveConnectorIndex] = useState<number>(0);

	const handleConnectorSocketClick = (connectorId: string) => {
		const _activeConnectorIndex = charger.connectors.findIndex(connector => connector.connectorId === connectorId);

		setActiveConnectorIndex(_activeConnectorIndex);
	};

	const getChargerStatus = (activeConnector) => {
		return StatusService.getActionsCellStatus(activeConnector?.status || ConnectorStatus.UNAVAILABLE, charger.connected);
	};

	return (
		<div
			className={styles.summary}
			data-testid="charger-summary"
		>
			<div className={styles.image}>
				<img src={charger.image} alt="charger-image" />
			</div>
			<ChargerInfo
				charger={charger}
				activeConnectorId={charger.connectors[activeConnectorIndex]?.connectorId}
				handleConnectorSocketClick={handleConnectorSocketClick}
			/>

			{charger.connectors.length > 0 &&
				<ChargerActions
					status={getChargerStatus(charger.connectors[activeConnectorIndex])}
					charger={charger}
					connector={charger.connectors[activeConnectorIndex]}
				/>
			}
		</div>
	);
};
