import { endpoints } from "./endpoints";
import { GATEWAY_API } from "./http-common";

export const localListApi = {
	assignCharger: ({ chargerId, chargingCardId }) => {
		return GATEWAY_API.put(endpoints.localList.chargerLocalList(chargerId, chargingCardId))
			.then((response) => response.data);
	},
	removeCharger: ({ chargerId, chargingCardId }) => {
		return GATEWAY_API.delete(endpoints.localList.chargerLocalList(chargerId, chargingCardId))
			.then((response) => response.data);
	}
};
