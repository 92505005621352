import { FC } from "react";
import styles from "./Dropdown.module.scss";

export const Dropdown: FC = ({ children }) => {
	return (
		<div className={styles.submenuContainer} data-testid="nav-dropdown">
			<ul data-testid="dropdown-list">{children}</ul>
		</div>
	);
};
