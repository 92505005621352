export const migrations = {
	2: (state) => {
		return {
			...state, ...{
				chargerPoints: {
					...state.chargerPoints,
					...{
						progressingConnectors: []
					}
				}
			}
		};
	}
};
