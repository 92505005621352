import { parseUsers } from "@app/model/userModel";
import { Container } from "@components/Container/Container";
import { CircleIcon } from "@components/Icons/Icons";
import { DataError } from "@components/Table/components/DataError/DataError";
import { Legend } from "@components/Table/components/Legend/Legend";
import { SelectFilter } from "@components/Table/components/SelectFilter/SelectFilter";
import { Table } from "@components/Table/Table";
import { nameSort, omitUnderscore } from "@components/Table/utils";
import { User } from "@interface/userInterface";
import NotificationService from "@services/notificationService";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CellProps, ColumnInstance } from "react-table";
import { NameCell, StatusCell } from "./UsersTableCells";
import { TableLoader } from "@app/components/Table/components/TableLoader/TableLoader";
import { usersApi } from "@app/api/usersApi";
import { Button } from "@app/components/Button/Button";
import { AddUserModal } from "@modals/AddUserModal/AddUserModal";
import { useModal } from "@app/components/Modal/Modal";
import styles from "./UsersTable.module.scss";
import { UsersGroupManagementModal } from "@modals/GroupManagementModal/UsersGroupManagementModal";

export const UsersTable = () => {
	const { t } = useTranslation();

	const { data, isLoading, isError, refetch } = useQuery("users", usersApi.getUsers, {
		select: parseUsers,
		onError: () => {
			NotificationService.displayError(t("messages.serverConnectionError"));
		}
	});
	const { openModal, ...addUsersProps } = useModal();
	const { openModal: openGroupsModal, ...groupModalProps } = useModal();

	const openAddUserModal = () => {
		openModal();
	};

	const _nameSort = useMemo(nameSort, []);

	if (isLoading) {
		return (
			<TableLoader />
		);
	}

	if (isError) {
		return <DataError refetch={refetch} />;
	}

	return (
		<div>
			<Table<User>
				filtersStyles={styles.customFilters}
				data={data || []}
				globalFilterLabel={t("users.searchPlaceholder")}
				HeaderComponent={
					<div className={styles.headerRight}>
						<div className={styles.newGroupContainer}>
							<Button color="primary" buttonType="outline" onClick={openGroupsModal} >
								{t("usersTable.groups")}
							</Button>
						</div>
						<div className={styles.newAccountContainer}>
							<Button color="primary" buttonType="solid" onClick={openAddUserModal} data-testid="users-page-open-add-modal">
								{t("usersTable.createAccount")}
							</Button>
						</div>
					</div>
				}
				columns={[
					{
						Header: <Trans t={t}>users.userGroup</Trans>,
						accessor: "groupLabel",
						filter: "exact",
						Filter: SelectFilter,
						Cell: ({ value }) => value
					},
					{
						Header: <Trans t={t}>users.name</Trans>,
						accessor: "fullName",
						filter: "includes",
						sortType: _nameSort,
						Cell: ({
							row: {
								original: { id, fullName }
							}
						}: CellProps<User>) => (<NameCell fullName={fullName} id={id} />)
					},
					{
						Header: <Trans t={t}>users.email</Trans>,
						accessor: "email",
						filter: "includes",
						sortType: _nameSort
					},

					{
						Header: <Trans t={t}>users.status</Trans>,
						accessor: "status",
						filter: "exact",
						Filter: ({ column }: { column: ColumnInstance }) => {
							return (
								<SelectFilter
									column={{ ...column, Header: <Trans t={t}>users.status</Trans> }}
									labelParser={omitUnderscore}
								/>
							);
						},
						Cell: ({ cell: { value } }: CellProps<User>) => <StatusCell status={value} />
					}
				]}
				filters={["groupLabel", "status"]}
				groupBy="groupLabel"
			/>
			<Container>
				<Legend
					elements={[
						{ Component: <CircleIcon className={styles.primaryIcon} />, label: t("users.active") },
						{ Component: <CircleIcon className={styles.dangerIcon} />, label: t("users.inactive") },
						{ Component: <CircleIcon className={styles.pendingIcon} />, label: t("users.pending") }
					]}
				/>
			</Container>
			<AddUserModal {...addUsersProps} />
			<UsersGroupManagementModal {...groupModalProps} />

		</div>
	);
};
