import { GATEWAY_API } from "@api/http-common";
import { endpoints } from "@api/endpoints";
import { User, UserPayloadWrapper } from "@interface/userInterface";
import NotificationService from "@services/notificationService";

export const usersApi = {
	getUsers: () => {
		return GATEWAY_API.get(endpoints.users.users).then((response) => response.data);
	},
	getUserById: (user: User["id"]) => {
		return GATEWAY_API.get(endpoints.users.user(user)).then((response) => response.data);
	},
	createUser: (createUserPayload: UserPayloadWrapper) => {
		return GATEWAY_API.post(endpoints.users.users, createUserPayload).then((response) => response.data);
	},
	assignUserGroup: ({ userId, userGroupId }) => {
		return GATEWAY_API.put(endpoints.users.assignUserGroup(userId, userGroupId)).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	},
	unassignUserGroup: ({ userId }) => {
		return GATEWAY_API.delete(endpoints.users.unassignUserGroup(userId)).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	}
};
