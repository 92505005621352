import { FC } from "react";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { Button } from "@components/Button/Button";
import { RotateIcon } from "@app/components/Icons/Icons";

import { Loader } from "@components/Loader/Loader";
import styles from "./ChargerRestart.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { resetChargerPoint, isChargepointRestarting } from "@reducers/chargerPointsReducer";
import ChargePointActionsService from "@app/services/chargePointActionsService";
import { CHARGE_POINT_MAIN_CONTROLLER } from "@app/model/chargersModel";
import NotificationService from "@app/services/notificationService";

interface ChargerRestartProps {
	chargerId: string;
}

export const ChargerRestart: FC<ChargerRestartProps> = ({ chargerId }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isRestarting = useSelector(isChargepointRestarting(chargerId));

	const restartCharger = () => {
		ChargePointActionsService.resetCharger(chargerId, Number(CHARGE_POINT_MAIN_CONTROLLER)).then((_response) => {
			if (_response?.status) {
				onChargerRestartSuccess();
			} else {
				NotificationService.displayError(t("messages.hardResetError"));
			}
		});
	};

	const onChargerRestartSuccess = () => {
		dispatch(resetChargerPoint(chargerId));
	};

	const renderLoader = () => {
		return (
			<>
				<div className={cs(styles.icon, styles.loader)} data-testid="reset-button-loader">
					<Loader />
				</div>
				<span>{t("chargerActions.restarting")}</span>
			</>
		);
	};

	const renderButton = () => {
		return (
			<>
				<RotateIcon />
				<span>{t("chargerActions.chargerRestart")}</span>
			</>
		);
	};

	return (
		<div className={styles.restart}>
			{chargerId && (
				<div>
					<Button
						color="default"
						buttonType="outline"
						onClick={restartCharger}
						disabled={isRestarting}
						data-testid="reset-button"
					>
						{isRestarting ? renderLoader() : renderButton()}
					</Button>
				</div>
			)}
		</div>
	);
};
