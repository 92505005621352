import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import queryClient from "./queryClient";
import { persistor, store } from "./app/store";
import * as serviceWorker from "./serviceWorker";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { initTranslations } from "@app/i18n";
import { Loader } from "@app/components/Loader/Loader";
import "@styles/main.scss";
import "react-toastify/dist/ReactToastify.css";
import { initResetingChargerpoints } from "@reducers/chargerPointsReducer";

const onBeforeLift = async () => {
	await initTranslations();
	store.dispatch(initResetingChargerpoints());
};

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<PersistGate loading={<Loader />} persistor={persistor} onBeforeLift={onBeforeLift}>
					<App />
				</PersistGate>
			</Provider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
