import { FC, HTMLProps } from "react";
import { FieldError } from "react-hook-form";
import cs from "classnames";
import { ErrorLabel } from "../ErrorLabel/ErrorLabel";
import styles from "./TextField.module.scss";

interface TextFieldProps extends HTMLProps<HTMLInputElement> {
	label?: string;
	Icon?: FC<React.SVGProps<SVGSVGElement>>;
	error?: FieldError;
	inputType?: "rounded" | "solid";
}

export const TextField: FC<TextFieldProps> = ({ label, Icon, error, inputType = "rounded", ...props }) => (
	<div
		className={cs(styles.container, {
			[styles.error]: !!error
		})}
	>
		{label && (
			<div className={styles.label}>
				<label>{label}</label>
			</div>
		)}
		<input
			className={cs(styles.input, {
				[styles.solid]: inputType === "solid"
			})}
			{...props}
		/>
		{Icon && (
			<div className={styles.icon}>
				<Icon />
			</div>
		)}
		{error && <ErrorLabel error={error.message} />}
	</div>
);
