import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Switch } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@components/Button/Button";
import { TextField } from "@components/Form/TextField/TextField";
import { GroupSelect } from "@components/GroupSelect/GroupSelect";
import { useTranslation } from "react-i18next";
import { IdTag, IdTagPayloadWrapper, IdTagStatus } from "@interface/idTagsInterface";
import { GroupTypes } from "@interface/groupInterface";
import { ErrorLabel } from "@components/Form/ErrorLabel/ErrorLabel";
import IdTagService from "@services/idTagService";
import { rfIdCardIdFieldRegex } from "@services/inputValidation";
import { IdTagsGroupManagementModal } from "@modals/GroupManagementModal/IdTagsGroupManagementModal";
import styles from "./AddCardModal.module.scss";
import i18n from "i18next";

export const AddCardForm: FC<{
	action: (data: IdTagPayloadWrapper) => void;
	chargingCard?: IdTag;
}> = ({ action, chargingCard }) => {
	const { t } = useTranslation();

	const findMaxErrorMessage = (error) => {
		return i18n.t("common.maxError", { max: error.max });
	};

	const findMinErrorMessage = (error) => {
		return i18n.t("common.minError", { min: error.min });
	};

	const findRequiredErrorMessage = () => {
		return i18n.t("common.required");
	};

	const schema = yup
		.object({
			token: yup
				.string()
				.matches(rfIdCardIdFieldRegex, () => i18n.t("idtagsAddModal.alphanumericalAllowed"))
				.trim()
				.min(3, findMinErrorMessage)
				.max(20, findMaxErrorMessage)
				.required(findRequiredErrorMessage),
			externalId: yup.string()
				.trim()
				.min(3, findMinErrorMessage)
				.max(100, findMaxErrorMessage)
				.required(findRequiredErrorMessage)
		}).required();

	const { handleSubmit, control } = useForm({
		defaultValues: {
			externalId: chargingCard?.externalId || "",
			token: chargingCard?.token || "",
			status: (chargingCard?.status ?? IdTagStatus.ACTIVE) === IdTagStatus.ACTIVE,
			group: chargingCard?.group || null
		},
		resolver: yupResolver(schema),
		mode: "onSubmit"
	});

	const onSubmit = (values) => {
		action(IdTagService.buildIdTagPayload(values, chargingCard?.externalId));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} data-testid="add-card-form">
			<div className={styles.container}>
				<div className={styles.row}>
					<Controller
						name="token"
						control={control}
						render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
							<>
								<TextField
									label={t("idtagsAddModal.labelCardId")}
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									inputType="solid"
									autoFocus
									data-testid="card-id-input"
								/>
								{error && <div className={styles.errorContainer}><ErrorLabel error={error.message} /></div>}
							</>
						)}
					/>
				</div>
				<div className={styles.row}>
					<Controller
						name="externalId"
						control={control}
						render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
							<>
								<TextField
									label={t("idtagsAddModal.labelCardNr")}
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									inputType="solid"
									data-testid="card-nr-input"
								/>
								{error && <div className={styles.errorContainer}><ErrorLabel error={error.message} /></div>}
							</>
						)}
					/>
				</div>
				<div className={styles.row}>
					<div>
						<label>{t("idtagsAddModal.labelGroup")}:</label>
						<Controller
							name="group"
							control={control}
							render={({ field: { onChange, value } }) => (
								<GroupSelect
									type={GroupTypes.RFID}
									value={value}
									onChange={onChange}
									GroupsManagementComponent={IdTagsGroupManagementModal}
								/>
							)}
						/>
					</div>
				</div>
				<div className={styles.row}>
					<div>
						<label>{t("idtagsAddModal.labelStatus")}</label>
						<div>
							<Controller
								name="status"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Switch checked={value} onChange={onChange} disabled={!chargingCard} data-testid="card-status" />
								)}
							/>
						</div>
					</div>
				</div>
				<div className={styles.footer}>
					<Button color="primary" data-testid="card-add-button">
						{chargingCard ? t("common.save") : t("common.add")}
					</Button>
				</div>
			</div>
		</form>
	);
};
