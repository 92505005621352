import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { OnOutsideClick } from "@components/OnOutsideClick/OnOutsideClick";
import { Dropdown } from "@components/Dropdown/Dropdown";
import { CaretDownIcon } from "@app/components/Icons/Icons";
import styles from "./Navigation.module.scss";

export enum Route {
	HOME = "/",
	CHARGERS = "/chargers",
	CHARGER_DETAILS = "/charger-details",
	CHARGING_CARDS = "/charging-cards",
	CHARGING_CARD_DETAILS = "/charging-card-details",
	USERS = "/users",
	USER_DETAILS = "/user-details",
	USER_CHARGERS = "/user-chargers",
	PASSWORD_RESET = "#",
	LOGIN = "/login",
	CONFIRM_ACCOUNT = "/confirm-account/:activationToken"
}

interface Props {
	menu: Array<{ Component: ReactNode; href: string; subMenu?: Array<{ Component: ReactNode; href: string }> }>
}

export const Navigation: FC<Props> = ({ menu }) => {
	const [activeItem, setActiveItem] = useState<number | null>(null);
	const { i18n } = useTranslation();

	const MENU = useMemo<Props["menu"]>(() => menu, [i18n.language]);

	const setActiveDropdown = useCallback(
		(index: number | null) => () => {
			setActiveItem(index);
		},
		[]
	);

	return (
		<nav className={styles.nav} data-testid="layout-navigation">
			<OnOutsideClick onOutsideClick={setActiveDropdown(null)} className={styles.clickOutsideContainer} active={activeItem !== null}>
				<ul className={styles.menu}>
					{MENU.map((menu, index) => {
						return (
							<NavLink
								key={menu.href}
								to={menu.href}
								activeClassName={styles.activeNav}
								component={({ href, className }) => (
									<li
										onMouseEnter={setActiveDropdown(index)}
										className={cs(styles.menuItem, styles.navItem, className, {
											[styles.active]: activeItem === index
										})}
										data-testid={menu.subMenu ? "nav-dropdown-element" : ""}>
										<Link to={href} className={styles.navItem}>
											<strong>{menu.Component}</strong>
										</Link>
										{menu.subMenu && (
											<div className={styles.svgArrow}>
												<CaretDownIcon />
											</div>
										)}
										{activeItem === index && (
											<>
												{menu.subMenu && (

													<Dropdown>
														{menu.subMenu.map((submenu) => (
															<NavLink
																key={submenu.href}
																to={submenu.href}
																exact
																activeClassName={styles.activeNav}
																onMouseLeave={setActiveDropdown(null)}
																component={({ href, className }) => (
																	<li className={cs(className)}
																		data-testid="nav-submenu-container">
																		<Link to={href}>
																			<span>{submenu.Component}</span>
																		</Link>
																	</li>
																)}
															/>
														))}
													</Dropdown>
												)}
											</>
										)}
									</li>
								)}
							/>
						);
					})}
				</ul>
			</OnOutsideClick>
		</nav>
	);
};
