import {
	Denomination,
	TariffManagementForm,
	UnitOfPower
} from "@modals/TariffManagementModal/TariffManagementInterface";
import moment from "moment";

export const getInitialTariffForm = (currentTariff) => {
	return {
		price: currentTariff,
		unitOfPower: UnitOfPower.KW,
		denomination: Denomination.EUROCENT
	};
};

export const updateTariffManagementForm = (tariffManagementForm: TariffManagementForm, key, value) => {
	return {
		...tariffManagementForm,
		[key]: value
	};
};

export const findCurrentChargingTariffObject = (_data: TariffManagementForm[]) => {
	const now = moment();

	return _data.filter(tariff => moment(tariff.startDate) <= now && moment(tariff.endDate) >= now)[0];
};

export const findCurrentChargingTariff = (_data: TariffManagementForm[]) => {
	return findCurrentChargingTariffObject(_data).price;
};

export const findHistoryChargingTariffs = (_data: TariffManagementForm[]) => {
	const currentTariffObject: TariffManagementForm = findCurrentChargingTariffObject(_data);
	return _data.filter(tariff => tariff.id !== currentTariffObject?.id);
};
