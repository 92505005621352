import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { IconPosition, Link } from "@components/Link/Link";
import { CaretRightIcon } from "@app/components/Icons/Icons";
import styles from "@components//Layout/Layout.module.scss";

export const ChargerNavigation = () => {
	const { t } = useTranslation();
	const location = useLocation();
	return (
		<div
			className={styles.detailsNavigation}
			data-testid="charger-navigation"
		>
			<Link
				to={Route.CHARGERS + location.search}
				label={t("navigation.backToChargerList")}
				icon={
					{
						element: CaretRightIcon,
						position: IconPosition.START,
						inverted: true
					}
				}
			/>
		</div>
	);
};
