import { Row } from "react-table";

export const defaultExpanded = (groupBy, data) => () => {
	if (!groupBy || typeof data !== "object" || !data) {
		return {};
	}
	const groups = new Set();
	data.forEach((_data) => {
		groups.add(_data[groupBy]);
	});

	return [...groups.values()].reduce<Record<string, boolean>>((a, b) => {
		return { ...a, ...{ [`${groupBy}:${b}`]: true } };
	}, {});
};

export const getFilters = (filters, _columns) => () => {
	if (!filters) {
		return null;
	}
	return filters.map((accessor) => {
		return _columns.find(({ id }) => id === accessor);
	});
};

export const onSortChange = (groupBy, sortBy, _columns, tableGroupBy, toggleGroupBy) => () => {
	if (groupBy === undefined) {
		return;
	}
	if (sortBy.length !== 0) {
		toggleGroupBy(groupBy, false);
	} else if (tableGroupBy.length === 0) {
		toggleGroupBy(groupBy, true);
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nameSort = () => (rowA: Row<any>, rowB: Row<any>, columnId: string) => {
	if (!rowA.original || !rowB.original) {
		return 0;
	}

	if (!rowA.original[columnId] || !rowB.original[columnId]) {
		return rowA.original[columnId] ? 1 : -1;
	}

	const a: string = rowA.original[columnId].toLowerCase();
	const b: string = rowB.original[columnId].toLowerCase();
	return a.localeCompare(b);
};

export const parseUrlFilters = (filters: string[] | undefined, searchParams: URLSearchParams) => {
	return filters?.reduce<{ id: string, value: string }[]>((a, filter) => {
		const urlFilters = searchParams?.get(`filters[${filter}]`);
		if (urlFilters) {
			return [...a, { id: filter, value: urlFilters }];
		}
		return a;
	}, []);
};
export const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const omitUnderscore = (str: string): string => capitalizeFirstLetter(str.replace("_", " "));
