export interface BackendSchedule {
	chargerId: string;
	connectorId: number;
	schedulePreferenceType: SchedulePreferenceType;
	daysOfWeek: string[];
	startTime: string;
	endTime: string;
	enabled: boolean;
	batteryCapacity: number;
	percentageToCharge: number;
}

export interface SchedulePayload {
	chargerId: string;
	connectorId: number;
	schedulePreferenceType: SchedulePreferenceType.DAILY,
	startTime: string;
	endTime: string;
	enabled: boolean;
}

export enum SchedulePreferenceType {
	DAILY = "DAILY",
	SMART = "SMART",
}

export interface ConnectorSchedule {
	chargerId: string;
	connectorId: string;
	startTime: string;
	endTime: string;
	enabled: boolean;
}
