import { ReactElement } from "react";

export const renderRow = (
	isGrouped: boolean,
	GroupRow: ReactElement<unknown>,
	SingleRow: ReactElement<unknown>,
	ConnectorsRows: ReactElement<unknown>[] | null
) => {
	if (isGrouped) {
		return GroupRow;
	}
	if (ConnectorsRows) {
		return ConnectorsRows;
	}
	return SingleRow;
};
