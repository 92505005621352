import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cs from "classnames";
import { ChargePoint, Connector } from "@interface/chargersInterface";
import { ConnectorStatus } from "@app/interface/chargerStatusInterface";
import ChargePointActionsService from "@services/chargePointActionsService";
import { ActionsCellStatus } from "@services/statusService";
import { addProgressingConnector, isChargepointRestarting, isConnectorProgressing } from "@reducers/chargerPointsReducer";
import { ChargeIcon, ClockAltIcon, ShutDownIcon } from "@components/Icons/Icons";
import { Button } from "@components/Button/Button";
import { Loader } from "@components/Loader/Loader";
import { useModal } from "@components/Modal/Modal";
import { StatusDot } from "@components/StatusDot/StatusDot";
import { ScheduleChargingModal } from "@modals/ScheduleChargingModal/ScheduleChargingModal";
import { ScheduleModalState } from "@modals/ScheduleChargingModal/scheduleChargingModalInterface";
import styles from "./ChargerSummary.module.scss";

interface ChargerSummaryProps {
	status: ActionsCellStatus;
	charger: ChargePoint;
	connector: Connector;
}

export const ChargerActions: FC<ChargerSummaryProps> = ({ status, charger, connector }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { openModal, onClose: closeModal, ...groupModalProps } = useModal();
	const [modalState, setModalState] = useState<ScheduleModalState | undefined>(undefined);
	const isRestarting = useSelector(isChargepointRestarting(charger.externalId));
	const isProgressing = useSelector(isConnectorProgressing(charger.externalId, connector.connectorId));
	const mockedIndicator = "TODO";

	const openScheduleModal = (_charger, _connectorId) => {
		setModalState({
			charger: _charger,
			connectorId: _connectorId

		});
		openModal();
	};

	const closeScheduleModal = () => {
		setModalState(undefined);
		closeModal();
	};

	const handleStart = useCallback(() => {
		dispatch(
			addProgressingConnector({
				chargerpoint: charger,
				connector: connector,
				successStatus: [ConnectorStatus.CHARGING, ConnectorStatus.SUSPENDEDEV]
			})
		);

		ChargePointActionsService.startTransaction({ chargerId: charger.externalId, connectorId: Number(connector.connectorId) })
			.then(() => {
				ChargePointActionsService.updateActiveChargerPointStatusQueryData(Number(connector.connectorId));
			});
	}, [status]);

	const handleStop = useCallback(() => {
		dispatch(
			addProgressingConnector({
				chargerpoint: charger,
				connector: connector,
				successStatus: [ConnectorStatus.AVAILABLE, ConnectorStatus.PREPARING]
			})
		);
		ChargePointActionsService.stopTransaction({ chargerId: charger.externalId, connectorId: Number(connector.connectorId) })
			.then(() => {
				ChargePointActionsService.updateActiveChargerPointStatusQueryData(Number(connector.connectorId));
			});
	}, [status]);

	const showSchedule = () => {
		openScheduleModal(charger, connector.connectorId);
	};

	const renderButton = () => {
		if (isProgressing || status === ActionsCellStatus.LOADING) {
			return <Button color="primary" buttonType="solid" disabled data-testid="action-button-loading">
				<div className={cs(styles.icon, styles.loader)}>
					<Loader />
				</div>
			</Button>;
		}

		if (status === ActionsCellStatus.CHARGING || status === ActionsCellStatus.SUSPENDEDEV) {
			return <Button
				color="secondary"
				buttonType="solid"
				onClick={handleStop}
				disabled={isRestarting}
				data-testid="action-button-charging"
			>
				<ShutDownIcon />
				<span>{t("chargerActions.stopCharging")}</span>
			</Button>;
		}

		if (status === ActionsCellStatus.AVAILABLE) {
			return <Button
				color="primary"
				buttonType="solid"
				onClick={handleStart}
				disabled={isRestarting}
				data-testid="action-button-available"
			>
				<ChargeIcon />
				<span>{t("chargerActions.startCharging")}</span>
			</Button>;
		}

		return <Button color="primary" buttonType="solid" disabled data-testid="action-button-unavailable">
			<ChargeIcon />
			<span>{t("chargerActions.startCharging")}</span>
		</Button>;
	};

	return (
		<div className={styles.actions}>
			<div className={styles.indicators}>
				<div className={cs(styles.indicator, styles.price)}>{mockedIndicator}</div>
				<div className={cs(styles.indicator, styles.status)}>
					<StatusDot status={status} />
					{status}
				</div>
			</div>
			<div className={styles.buttons}>
				<div className={styles.startStop}>
					{renderButton()}
				</div>
				<div className={styles.schedule}>
					<Button
						color="default"
						buttonType="outline"
						onClick={showSchedule}
						disabled={status === ActionsCellStatus.UNAVAILABLE || isRestarting}
						data-testid="show-schedule-button"
					>
						<ClockAltIcon />
						<span>{t("chargerActions.chargingSchedule")}</span>
					</Button>
				</div>
			</div>

			{modalState && (
				<ScheduleChargingModal
					{...groupModalProps}
					onClose={closeScheduleModal}
					charger={modalState.charger}
					connectorId={modalState.connectorId}
				/>
			)}
		</div>
	);
};
