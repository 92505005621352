import { FC } from "react";
import { User } from "@interface/userInterface";
import styles from "./UserSummary.module.scss";
import { GroupSelect } from "@components/GroupSelect/GroupSelect";
import { GroupTypes } from "@interface/groupInterface";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { usersApi } from "@api/usersApi";
import { UsersGroupManagementModal } from "@modals/GroupManagementModal/UsersGroupManagementModal";
import NotificationService from "@services/notificationService";
import queryClient from "../../../../../queryClient";

interface UserInfoProps {
	user: User;
}

export const UserInfo: FC<UserInfoProps> = ({ user }) => {
	const { t } = useTranslation();

	const { mutateAsync: assignUserGroup, isLoading: assignGroupLoading } = useMutation(
		usersApi.assignUserGroup,
		{
			onSuccess: (updatedUser) => {
				NotificationService.displaySuccess(t("messages.userGroupAssigned"));
				queryClient.setQueryData(["user", user.id], () => updatedUser);
				updateUserData(updatedUser);
			}
		}
	);

	const { mutateAsync: unassignUserGroup } = useMutation(usersApi.unassignUserGroup, {
		onSuccess: (updatedUser) => {
			NotificationService.displaySuccess(t("messages.userGroupUnassigned"));
			queryClient.setQueryData(["user", user.id], () => updatedUser);
			updateUserData(updatedUser);
		}
	});

	const updateUserData = (updatedUser: User) => {
		queryClient.setQueryData<User[] | undefined>("users", (data) => {
			if (data) {
				return data.map((user) => {
					if (user.id === user.id) {
						return updatedUser;
					}
					return user;
				});
			}
			return data;
		});
	};

	const onGroupChange = (el) => {
		if (el === null) {
			unassignUserGroup({ userId: user.id });
		} else {
			assignUserGroup({ userId: user.id, userGroupId: el.id });
		}
	};

	return (
		<div className={styles.info}>
			<div className={styles.title}>
				<div className={styles.label}>{user.fullName}</div>
			</div>
			<div className={styles.chargerGroup}>
				<div className={styles.label}>{t("users.userGroup")}:</div>
				<div>
					<GroupSelect
						type={GroupTypes.USER}
						value={user.group}
						onChange={onGroupChange}
						isLoading={assignGroupLoading}
						GroupsManagementComponent={UsersGroupManagementModal}
					/>
				</div>
			</div>
		</div>
	);
};
