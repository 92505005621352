import { FC } from "react";
import { Header } from "@components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Navigation, Route } from "./components/Navigation/Navigation";
import styles from "./Layout.module.scss";
import { useTranslation } from "react-i18next";

export const Layout: FC = ({ children }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container} data-testid="layout">
			<Header />
			<Navigation menu={[
				{
					Component: t("navigation.chargers"),
					href: Route.CHARGERS
				},
				{
					Component: t("navigation.chargersCards"),
					href: Route.CHARGING_CARDS
				},
				{
					Component: t("navigation.users"),
					href: Route.USERS,
					subMenu: [
						{
							Component: t("navigation.userChargers"),
							href: Route.USER_CHARGERS
						}
					]
				}
			]} />
			<div className={styles.pageContainer}>{children}</div>
			<Footer />
		</div>
	);
};
