import { FC } from "react";
import * as yup from "yup";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { ActivationPayload } from "@interface/accountInterface";
import { TextField } from "@components/Form/TextField/TextField";
import { Button } from "@components/Button/Button";
import styles from "@pages/ConfirmAccountPage/ConfirmAccount.module.scss";

interface ConfirmAccountFormProps {
	onSubmit: (fields: ActivationPayload) => void;
	isLoading: boolean
}

const schema = yup
	.object({
		email: yup
			.string()
			.email(() => i18n.t("loginPage.incorrectEmail"))
			.required(() => i18n.t("loginPage.incorrectEmail")),
		password: yup
			.string()
			.required(() => i18n.t("confirmationPage.incorrectPassword"))
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, () => i18n.t("confirmationPage.incorrectPassword")),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password"), null], () => i18n.t("confirmationPage.incorrectPasswordConfirmation"))
	})
	.required();

export const ConfirmAccountForm: FC<ConfirmAccountFormProps> = ({ onSubmit, isLoading }) => {

	const { t } = useTranslation();

	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: "",
			password: "",
			confirmPassword: ""
		},
		resolver: yupResolver(schema),
		mode: "onChange"
	});

	const _onSubmit = ({ email, password }) => {
		onSubmit({ email, password });
	};
	return (
		<>
			<form onSubmit={handleSubmit(_onSubmit)} data-testid="create-account-form">
				<div className={styles.formContainer}>
					<header className={styles.header}>
						<h2>
							<strong>{t("loginPage.activateAccount")}</strong>
						</h2>
					</header>
					<div className={styles.inputRow}>
						<Controller
							name="email"
							control={control}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<TextField
									type="text"
									label={t("confirmationPage.confirmEmail")}
									placeholder={t("confirmationPage.confirmEmail")}
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									error={error}
									autoFocus
									data-testid="create-account-email"
								/>
							)}
						/>
					</div>
					<div className={styles.inputRow}>
						<Controller
							name="password"
							control={control}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<TextField
									type="password"
									label={t("loginPage.password")}
									placeholder={t("loginPage.password")}
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									error={error}
									data-testid="create-account-password"
								/>
							)}
						/>
					</div>
					<div className={styles.inputRow}>
						<Controller
							name="confirmPassword"
							control={control}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<TextField
									type="password"
									label={t("loginPage.confirmPassword")}
									placeholder={t("loginPage.confirmPassword")}
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									error={error}
									data-testid="create-account-confirm-password"
								/>
							)}
						/>
					</div>

					<footer className={styles.footer}>
						<Button color="primary" buttonType="solid" disabled={isLoading}>
							{t("loginPage.activate")}
						</Button>
					</footer>
				</div>
			</form>
		</>
	);
};
