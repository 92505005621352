import i18n from "@app/i18n";
import { BackendIdTag, IdTag, IdTagPayload, IdTagStatus } from "@interface/idTagsInterface";
import { Group } from "@interface/groupInterface";

export const parseIdTagCard = (backendIdTag: BackendIdTag): IdTag => {
	return {
		token: backendIdTag.token,
		externalId: backendIdTag.externalId,
		status: backendIdTag.status || IdTagStatus.DEACTIVATED,
		totalCharged: 0,
		group: backendIdTag.idtagGroup,
		groupLabel: backendIdTag.idtagGroup?.name || i18n.t("groups.ungrouped")
	};
};
export const parseIdTagsCards = (backendIdTags: BackendIdTag[]): IdTag[] => {
	return backendIdTags.map(parseIdTagCard);
};

export const getChargingCardById = (param, idCards?: IdTag[]): IdTag | undefined => {
	return idCards?.find((card) => card.token === param.cardId);
};

export const buildUpdateIdTagPayload = (idTag: IdTag, group: Group): IdTagPayload => {
	return {
		externalId: idTag.externalId,
		token: idTag.token,
		status: idTag.status,
		idtagGroup: group
	};
};
