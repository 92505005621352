import { BackendChargePoint } from "@interface/chargersInterface";
import { IdTag, IdTagPayloadWrapper, IdTagStatus } from "@interface/idTagsInterface";
import queryClient from "../../queryClient";

const addIdTagQuery = (idTag: IdTag) => {
	queryClient.setQueryData<IdTag[] | undefined>("idtags", (idtags) => {
		if (!idtags) {
			return [idTag];
		}
		return [...idtags, idTag];
	});
};

const updateIdTagQuery = (idTag: IdTag) => {
	queryClient.setQueryData<IdTag[] | undefined>("idtags", (idtags) => {
		if (!idtags) {
			return idtags;
		}
		return updateIdTag(idtags, idTag);
	});
	queryClient.setQueryData<IdTag | undefined>(["idtag", idTag.externalId], idTag);
};

const updateIdTag = (idTags: IdTag[], newIdTag: IdTag) => idTags.map((idTag) => idTag.token === newIdTag.token ? newIdTag : idTag);

const buildIdTagPayload = (formValues, oldExternalId): IdTagPayloadWrapper => {
	return {
		idTagPayload: {
			externalId: formValues.externalId,
			token: formValues.token,
			idtagGroup: formValues.group,
			status: formValues.status ? IdTagStatus.ACTIVE : IdTagStatus.DEACTIVATED
		},
		currentExternalId: oldExternalId
	};
};

const parseIdtagChargers = (cardExternalId: IdTag["externalId"], backendChargers: BackendChargePoint[]) => backendChargers
	.filter(chargepoint => chargepoint.idtags
		.find(idtag => idtag.externalId === cardExternalId))
	.map(chargepoint => ({
		label: chargepoint.externalId,
		value: chargepoint.externalId
	}));

const parseIdtagChargersOptions = (backendChargers: BackendChargePoint[]) =>
	backendChargers.map(
		chargePoint => ({ label: chargePoint.externalId, value: chargePoint.externalId })
	);

const IdTagService = {
	addIdTagQuery,
	updateIdTagQuery,
	buildIdTagPayload,
	parseIdtagChargers,
	parseIdtagChargersOptions
};

export default IdTagService;
