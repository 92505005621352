import { FC, ReactNode, useState } from "react";
import { CloseIcon } from "../Icons/Icons";
import { Loader } from "../Loader/Loader";
import styles from "./Modal.module.scss";

interface ModalProps {
	Header?: ReactNode;
	Content?: ReactNode;
	Footer?: ReactNode;
	onClose?: () => void;
	showLoader?: boolean;
	isOpen: boolean;
}

export const useModal = () => {
	const [isOpen, setOpen] = useState(false);

	return {
		openModal: () => setOpen(true),
		onClose: () => setOpen(false),
		isOpen
	};
};

export const Modal: FC<ModalProps> = ({ Header, Content, Footer, onClose, showLoader, isOpen }) => {
	if (!isOpen) {
		return null;
	}
	return (
		<div className={styles.container} data-testid="modal">
			<div className={styles.modalContainer}>
				<div className={styles.content}>
					{showLoader && (
						<div className={styles.loaderContainer}>
							<Loader />
						</div>
					)}
					<div className={styles.header}>
						<div>{Header}</div>
						{onClose && (
							<div>
								<div className={styles.closeBtn} onClick={onClose} data-testid="modal-close-button">
									<CloseIcon />
								</div>
							</div>
						)}
					</div>
					{Content && <div className={styles.body}>{Content}</div>}
					{Footer && <div className={styles.footer}>{Footer}</div>}
				</div>
			</div>
		</div>
	);
};
