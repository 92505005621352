import { FC } from "react";
import { Container } from "@components/Container/Container";
import { Logo } from "@components/Icons/Icons";
import styles from "./Footer.module.scss";

export const Footer: FC = () => (
	<footer className={styles.footer} data-testid="layout-footer">
		<Container>
			<div className={styles.logoContainer}>
				<Logo />
			</div>
			<div className={styles.copy}>© 2020 Enefit Volt. All rights reserved</div>
		</Container>
	</footer>
);
