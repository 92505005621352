import { FC } from "react";
import { Layout } from "@components/Layout/Layout";
import { ChargersTable } from "./components/ChargersTable/ChargersTable";

export const ChargersPage: FC = () => {
	return (
		<Layout>
			<ChargersTable />
		</Layout>
	);
};
