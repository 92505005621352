import i18n from "i18next";
import { ChargePoint } from "@interface/chargersInterface";
import { ChargerDetailsTileType } from "@pages/ChargerDetailsPage/components/ChargerDetails/ChargerDetailsTile";

export const tilesBuilder = (charger: ChargePoint) => [
	{
		type: ChargerDetailsTileType.PARAMETER,
		label: i18n.t("chargerDetails.manufacturer"),
		value: charger.brand
	},
	{
		type: ChargerDetailsTileType.PARAMETER,
		label: i18n.t("chargerDetails.chargerModel"),
		value: charger.model
	},
	{
		type: ChargerDetailsTileType.PARAMETER,
		label: i18n.t("chargerDetails.chargerId"),
		value: charger.externalId
	},
	{
		type: ChargerDetailsTileType.TOGGLE,
		label: i18n.t("chargerDetails.accessByCard"),
		value: {
			chargerId: charger.externalId,
			ownership: charger.ownership
		}
	},
	{
		type: ChargerDetailsTileType.PARAMETER,
		label: i18n.t("chargerDetails.maxChargingPower"),
		value: `${charger.maxChargingPowerKw} kW`
	},
	{
		type: ChargerDetailsTileType.CHARGED_METER,
		label: i18n.t("chargerDetails.charged"),
		value: {
			chargerId: charger.externalId
		}
	},
	{
		type: ChargerDetailsTileType.LINK,
		label: i18n.t("chargerDetails.chargerSessionReport"),
		value: i18n.t("chargerDetails.chargerUsageReport")
	}
];
