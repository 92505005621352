import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import { chargePointsApi } from "@api/chargePointsApi";
import { CHARGE_POINT_MAIN_CONTROLLER } from "@app/model/chargersModel";
import { isChargepointRestarting, resetChargerPoint } from "@reducers/chargerPointsReducer";
import { ChargePointOwnership, OwnershipPayload } from "@interface/chargersInterface";
import ChargePointActionsService from "@services/chargePointActionsService";
import NotificationService from "@services/notificationService";
import styles from "./ChargerDetails.module.scss";

export interface OwnershipSwitchProps {
	chargerId: string;
	ownership: ChargePointOwnership;
}

export const OwnershipSwitch: FC<OwnershipSwitchProps> = ({ chargerId, ownership }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isRestarting = useSelector(isChargepointRestarting(chargerId));

	const { mutateAsync: setOwnership, isLoading: setOwnershipLoading } = useMutation(
		chargePointsApi.setChargePointOwnership,
		{
			onSuccess: (response) => {
				if (response) {
					ChargePointActionsService.toggleOwnership(response);
					ChargePointActionsService.resetCharger(chargerId, Number(CHARGE_POINT_MAIN_CONTROLLER)).then(
						(_response) => {
							if (_response?.status) {
								showTooltip();
							} else {
								NotificationService.displayError(t("messages.hardResetError"));
							}
						}
					);
				}
			}
		}
	);

	const showTooltip = () => {
		NotificationService.displayInfo(t("messages.hardResetOngoing"));
		dispatch(resetChargerPoint(chargerId));
	};

	const toggleOwnership = () => {
		const payload: OwnershipPayload = {
			ownership: isSwitchSemiPrivate(ownership)
				? ChargePointOwnership.PRIVATE
				: ChargePointOwnership.SEMI_PRIVATE
		};
		setOwnership({ chargerId, payload });
	};

	const isSwitchSemiPrivate = (_ownership) => {
		return _ownership === ChargePointOwnership.SEMI_PRIVATE;
	};

	return (
		<div className={styles.ownershipSwitch}>
			<div>{t("common.no")}</div>
			<Switch
				loading={setOwnershipLoading || isRestarting}
				checked={isSwitchSemiPrivate(ownership)}
				onChange={toggleOwnership}
				data-testid="ownership-switch"
			/>
			<div>{t("common.yes")}</div>
		</div>
	);
};
