import i18n from "i18next";
import { IdTag } from "@interface/idTagsInterface";
import { ChargingCardDetailsTileType } from "@pages/ChargingCardDetailsPage/components/ChargingCardDetails/ChargingCardDetailsTile";

export const tilesBuilder = (chargingCard: IdTag) => [
	{
		type: ChargingCardDetailsTileType.PARAMETER,
		label:  i18n.t("chargingCardDetails.user"),
		value: "TODO"
	},
	{
		type: ChargingCardDetailsTileType.PARAMETER,
		label: i18n.t("chargingCardDetails.group"),
		value: chargingCard.groupLabel
	},
	{
		type: ChargingCardDetailsTileType.PARAMETER,
		label: i18n.t("chargingCardDetails.charged"),
		value: "TODO"
	},
	{
		type: ChargingCardDetailsTileType.PARAMETER,
		label: i18n.t("chargingCardDetails.added"),
		value: "TODO"
	},
	{
		type: ChargingCardDetailsTileType.LINK,
		label: i18n.t("chargingCardDetails.cardUsageHistory"),
		value: undefined
	}
];
