/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropsWithChildren, ReactElement } from "react";
import { Cell, Row, SortingRule } from "react-table";
import cs from "classnames";
import { CaretDownIcon, CaretUpIcon } from "../Icons/Icons";
import styles from "./Table.module.scss";

interface RowProps<T extends Record<any, any>> {
	cells: Cell<T>[];
	sortBy: SortingRule<T>[];
	rowIndex: number;
	row: Row<T>;
	groupBy?: string;
}

export function GroupRow<T extends Record<any, any>>({ cells, row }: PropsWithChildren<RowProps<T>>): ReactElement {
	return (
		<tr data-testid="group-row" className={cs(styles.rowGrouped)}>
			{cells.map((cell, index) => {
				if (index > 0 || cell.isPlaceholder || cell.isAggregated) {
					return null;
				}
				const { key } = cell.getCellProps();

				return (
					<td
						key={key}
						colSpan={cells.length}
						className={cs(styles.groupTd, {
							[styles.expanded]: row.isExpanded
						})}
					>
						<div {...row.getToggleRowExpandedProps()}>
							<div data-testid="expand-title">
								<strong>{cell.render("Cell")}</strong>
							</div>
							{row.isExpanded ? <CaretDownIcon /> : <CaretUpIcon />}
						</div>
					</td>
				);
			})}
		</tr>
	);
}
export function SingleRow<T extends Record<any, any>>({
	cells,
	rowIndex,
	sortBy,
	groupBy
}: PropsWithChildren<RowProps<T>>): ReactElement {
	const renderCell = (cell) => {
		const { key } = cell.getCellProps();
		if (cell.isPlaceholder) {
			return null;
		}
		return (
			<td key={key}>
				{cell.render("Cell")}
			</td>
		);
	};
	return (
		<tr
			data-testid="data-row"
			className={cs({
				[styles.odd]: rowIndex % 2 === 0
			})}
		>
			{cells.filter((cell) => !(sortBy.length > 0 && cell.column.id === groupBy)).map(renderCell)}
		</tr>
	);
}
