export enum GroupTypes {
	RFID = "RFID",
	CHARGER = "CHARGER",
	USER = "USER"
}

export interface GroupsModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export interface BackendGroup {
	id: string;
	name: string;
}

export interface Group {
	id?: string;
	name: string;
}
