import { FC, useCallback, useState } from "react";
import { Dropdown } from "@components/Dropdown/Dropdown";
import { CaretDownIcon } from "../Icons/Icons";
import styles from "./LanguageSwitcher.module.scss";
import { Language, LANGUAGES } from "@interface/languageInterface";
import { useTranslation } from "react-i18next";
import { OnOutsideClick } from "@components/OnOutsideClick/OnOutsideClick";

export const AVAILABLE_LANGUAGES: Language[] = [
	{ code: LANGUAGES.ENGLISH, name: "English" },
	{ code: LANGUAGES.ESTONIAN, name: "Estonian" }
];

export const LanguageSwitcher: FC = () => {
	const { i18n } = useTranslation();
	const [isOpen, setOpen] = useState<boolean>(false);

	const setOpenValue = useCallback(
		(value: boolean) => () => {
			setOpen(value);
		},
		[]
	);

	const setLanguage = useCallback(
		(language: Language) => () => {
			i18n.changeLanguage(language.code);
			setOpen(false);
		},
		[]
	);

	return (
		<OnOutsideClick
			onOutsideClick={() => {
				setOpen(false);
			}}
			active={isOpen}
		>
			<div className={styles.container}>
				<div className={styles.languageButton} onClick={setOpenValue(true)} data-testid="language-switcher">
					<strong>{i18n.language}</strong>
					<div className={styles.icon}>
						<CaretDownIcon />
					</div>
				</div>
				{isOpen && (
					<Dropdown>
						{AVAILABLE_LANGUAGES.map((language) => (
							<li className={styles.language} key={language.code} onClick={setLanguage(language)}>
								<span>{language.name}</span>
							</li>
						))}
					</Dropdown>
				)}
			</div>
		</OnOutsideClick>
	);
};
