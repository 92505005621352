import { FC, useCallback } from "react";
import { CellProps } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { BackendGroup } from "@interface/groupInterface";
import { ChargePoint, Connector } from "@interface/chargersInterface";
import { ConnectorStatus } from "@app/interface/chargerStatusInterface";
import { ConnectorSchedule } from "@interface/chargerScheduleInterface";
import ChargePointActionsService from "@services/chargePointActionsService";
import { ActionsCellStatus } from "@services/statusService";
import { ClockIcon, PlayIcon, StopIcon, Warning } from "@components/Icons/Icons";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { StatusDot } from "@components/StatusDot/StatusDot";
import { Loader } from "@components/Loader/Loader";
import { Tooltip } from "@components/Tooltip/Tooltip";
import { TableLink } from "@components/Table/components/TableLink/TableLink";
import { addProgressingConnector, isConnectorProgressing } from "@app/reducers/chargerPointsReducer";
import styles from "./ChargersTable.module.scss";

export const NameCell: FC<CellProps<ChargePoint>> = ({ cell: { value } }) => {
	return (
		<div className={styles.chargerId} data-testid="charger-name-col">
			<TableLink to={`${Route.CHARGER_DETAILS}/${value}`}>{value}</TableLink>
		</div>
	);
};

interface MaxChargingPowerCellProps {
	chargingPower?: number;
}

export const MaxChargingCell: FC<MaxChargingPowerCellProps> = ({ chargingPower }) => {
	if (!chargingPower) {
		return (
			<div>
				<div>-</div>
			</div>
		);
	}
	return (
		<div>
			<div>
				<div>{`${chargingPower} kW`}</div>
			</div>
		</div>
	);
};

interface GroupCellProps {
	group?: BackendGroup | null;
}

export const GroupCell: FC<GroupCellProps> = ({ group }) => {
	return <div>{group}</div>;
};

interface StatusCellProps {
	status: ActionsCellStatus;
}

export const StatusCell: FC<StatusCellProps> = ({ status }) => {
	const { t } = useTranslation();
	if (status === ActionsCellStatus.SUSPENDEDEV) {
		return <Tooltip title={t("status.suspendedEVWarning")}>
			<Warning />
		</Tooltip>;
	}
	return (
		<Tooltip title={t(status)}>
			<StatusDot status={status} />
		</Tooltip>
	);
};

interface ActionsCellProps {
	status: ActionsCellStatus;
	charger: ChargePoint;
	connectorId?: string;
	schedule?: ConnectorSchedule;
	openScheduleModal: (charger, connectorId) => void;
}

export const ActionsCell: FC<ActionsCellProps> = ({ charger, status, connectorId, schedule, openScheduleModal }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isProgressing = useSelector(isConnectorProgressing(charger.externalId, connectorId));
	const startLabel = t("chargerActions.startCharging");

	const getConnector = (): Connector => {
		return charger.connectors.find(connector => connector.connectorId === connectorId) as Connector;
	};

	const handleStart = useCallback(() => {
		const connector = getConnector();

		if (connector) {
			dispatch(
				addProgressingConnector({
					chargerpoint: charger,
					connector: connector,
					successStatus: [ConnectorStatus.CHARGING, ConnectorStatus.SUSPENDEDEV]
				})
			);
			ChargePointActionsService.startTransaction({ chargerId: charger.externalId, connectorId: Number(connectorId) });
		}

	}, [status]);

	const handleStop = useCallback(() => {
		const connector = charger.connectors.find(_connector => _connector.connectorId === connectorId);
		if (connector) {
			dispatch(
				addProgressingConnector({
					chargerpoint: charger,
					connector: connector,
					successStatus: [ConnectorStatus.AVAILABLE, ConnectorStatus.PREPARING]
				})
			);
			ChargePointActionsService.stopTransaction({ chargerId: charger.externalId, connectorId: Number(connectorId) });
		}

	}, [status]);

	const handleOnClick = () => {
		if (status === ActionsCellStatus.UNAVAILABLE) {
			return;
		}

		openScheduleModal(charger, connectorId);
	};

	const isConnectorLoading = () =>
		isProgressing || status === ActionsCellStatus.LOADING;

	const renderButton = () => {
		if (isConnectorLoading()) {
			return <div className={cs(styles.icon, styles.loader)}>
				<Loader />
			</div>;
		}

		if (status === ActionsCellStatus.CHARGING || status === ActionsCellStatus.SUSPENDEDEV) {
			return <Tooltip title={t("chargerActions.stopCharging")}>
				<div
					className={cs(styles.icon, styles.stop)}
					data-testid="stop-charging-button"
					onClick={handleStop}
				>
					<StopIcon width={40} height={40} />
				</div>
			</Tooltip>;
		}

		if (status === ActionsCellStatus.AVAILABLE) {
			return <Tooltip title={startLabel}>
				<div
					className={cs(styles.icon, {
						[styles.scheduled]: schedule?.startTime
					})}
					data-testid="start-charging-button"
					onClick={handleStart}
				>
					<PlayIcon width={40} height={40} />
				</div>
			</Tooltip>;
		}
		return <Tooltip title={startLabel}>
			<div className={cs(styles.icon, styles.disabled)}>
				<PlayIcon width={40} height={40} />
			</div>
		</Tooltip>;
	};

	return (
		<>
			<div>
				<div className={styles.actionsContainer}>
					{renderButton()}
					<Tooltip
						title={
							schedule?.startTime
								? t("chargerActions.chargingScheduled")
								: t("chargerActions.scheduleCharging")
						}
					>
						<div
							className={cs(styles.icon, {
								[styles.scheduled]: schedule?.enabled && status !== ActionsCellStatus.UNAVAILABLE,
								[styles.disabled]: status === ActionsCellStatus.UNAVAILABLE
							})}
							data-testid="schedule-charging-button"
							onClick={handleOnClick}
						>
							<ClockIcon width={40} height={40} />
						</div>
					</Tooltip>
				</div>
			</div>
		</>
	);
};
