import { FC } from "react";
import { Link } from "react-router-dom";
import { OwnershipSwitch, OwnershipSwitchProps } from "@pages/ChargerDetailsPage/components/ChargerDetails/OwnershipSwitch";
import { MetersComponent, MetersComponentProps } from "@pages/ChargerDetailsPage/components/ChargerDetails/MetersComponent";
import { Tooltip } from "@components/Tooltip/Tooltip";
import { TooltipIcon } from "@components/Icons/Icons";
import styles from "./ChargerDetails.module.scss";

export enum ChargerDetailsTileType {
	PARAMETER = "label",
	TOGGLE = "toggle",
	CHARGED_METER = "chargedMeter",
	LINK = "link",
}

interface ChargerDetailsTileProps {
	type: ChargerDetailsTileType;
	label: string;
	value?: string | OwnershipSwitchProps | MetersComponentProps;
}

export const ChargerDetailsTile: FC<ChargerDetailsTileProps> = ({ type, label, value }) => {
	const renderTileByType = (_type: ChargerDetailsTileType) => {
		switch (_type) {
			case ChargerDetailsTileType.TOGGLE:
				return renderToggleOwnershipTile(value as OwnershipSwitchProps);
			case ChargerDetailsTileType.CHARGED_METER:
				return renderChargedMeterTile(value as MetersComponentProps);
			case ChargerDetailsTileType.LINK:
				return renderLinkTile();
			case ChargerDetailsTileType.PARAMETER:
			default:
				return renderParameterTile();
		}
	};

	const renderParameterTile = () => {
		return (
			<>
				<div className={styles.label}>{label}</div>
				<div className={styles.value}>{value}</div>
			</>
		);
	};

	const renderToggleOwnershipTile = (_value: OwnershipSwitchProps) => {
		return (
			<>
				<div className={styles.label}>
					<Tooltip
						title={label}
					>
						<TooltipIcon className={styles.tooltip} />
					</Tooltip>
					{label}
				</div>
				<div className={styles.value}>
					<OwnershipSwitch chargerId={_value?.chargerId} ownership={_value?.ownership} />
				</div>
			</>
		);
	};

	const renderChargedMeterTile = (_value: MetersComponentProps) => {
		return (
			<>
				<div className={styles.label}>{label}</div>
				<MetersComponent chargerId={_value?.chargerId} />
			</>
		);
	};

	const renderLinkTile = () => {
		return (
			<>
				<div className={styles.label}>{label}</div>
				<div className={styles.value}>
					<Link to="#">{value}</Link>
				</div>
			</>
		);
	};

	return (
		<div className={styles.tile} data-testid={"charger-details-tile"}>
			{renderTileByType(type)}
		</div>
	);
};
