import { TextField } from "@app/components/Form/TextField/TextField";
import { SearchIcon } from "@app/components/Icons/Icons";
import { FC } from "react";
import { ColumnInstance } from "react-table";

interface DefaultFilterProps {
	column: ColumnInstance;
}

export const DefaultFilter: FC<DefaultFilterProps> = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
	const count = preFilteredRows.length;

	return (
		<TextField
			data-testid="column-search"
			value={filterValue || ""}
			onChange={(e) => {
				setFilter(e.currentTarget.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
			Icon={SearchIcon}
		/>
	);
};
