import { FC } from "react";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { IdTag } from "@interface/idTagsInterface";
import StatusService from "@services/statusService";
import { PenIcon } from "@components/Icons/Icons";
import { Button } from "@components/Button/Button";
import { useModal } from "@components/Modal/Modal";
import { StatusDot } from "@components/StatusDot/StatusDot";
import styles from "./ChargingCardSummary.module.scss";
import { AddCardModal } from "@modals/AddCardModal/AddCardModal";
import { ManageRfidChargersModal } from "@modals/ManageRfidChargersModal/ManageRfidChargersModal";

interface ChargerSummaryProps {
	chargingCard: IdTag;
}

export const ChargingCardActions: FC<ChargerSummaryProps> = ({ chargingCard }) => {
	const { t } = useTranslation();
	const { openModal, ...editModalProps } = useModal();
	const { openModal: openManageRfidChargersModal, ...manageRfidChargersModalProps } = useModal();

	const handleManageCard = () => {
		openModal();
	};

	return (
		<>
			<div className={styles.actions}>
				<div className={styles.indicators}>
					<div className={cs(styles.indicator, styles.status)}>
						<StatusDot status={StatusService.getChargingCardStatus(chargingCard.status)} />
						{chargingCard.status}
					</div>
				</div>
				<div className={styles.buttons}>
					<div className={styles.manageCard}>
						<Button color="primary" buttonType="solid" onClick={handleManageCard} data-testid="charging-card-action-manage-modal-btn">
							<PenIcon />
							<span>{t("chargingCardDetails.manageCard")}</span>
						</Button>
					</div>
					<div className={styles.manageCard}>
						<Button color="primary" buttonType="solid" onClick={openManageRfidChargersModal}>
							<PenIcon />
							<span>{t("chargingCardDetails.manageChargers")}</span>
						</Button>
					</div>
				</div>

			</div>
			<AddCardModal {...editModalProps} chargingCard={chargingCard} />
			<ManageRfidChargersModal {...manageRfidChargersModalProps} chargingCard={chargingCard} />
		</>
	);
};
