import { Layout } from "@components/Layout/Layout";
import { UserChargersTable } from "@pages/UserChargersPage/components/UserChargersTable/UserChargersTable";

export const UserChargersPage = () => {
	return (
		<Layout>
			<UserChargersTable />
		</Layout>
	);
};
