import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import cs from "classnames";
import styles from "./Link.module.scss";

export enum IconPosition {
	START = "start",
	END = "end",
}

interface LinkProps {
	icon?: {
		element: FC;
		position?: IconPosition;
		inverted?: boolean;
	};
	to: string;
	label: string;
}

export const Link: FC<LinkProps> = ({ icon, to, label }) => {
	return (
		<div className={cs(styles.linkContainer)}>
			<RouterLink
				className={cs(styles.link, { [styles.iconEnd]: icon && icon.position === IconPosition.END })}
				to={to}
				data-testid="link"
			>
				{icon && (
					<div className={cs(styles.icon, { [styles.inverted]: icon.inverted })}>
						<icon.element />
					</div>
				)}
				<div className={cs(styles.label)}>{label}</div>
			</RouterLink>
		</div>
	);
};
