import { FC } from "react";
import cs from "classnames";
import { ActionsCellStatus, ChargingCardStatus, UserStatus } from "@services/statusService";
import styles from "./StatusDot.module.scss";

interface StatusDotProps {
	status: ActionsCellStatus | ChargingCardStatus | UserStatus;
}

export const StatusDot: FC<StatusDotProps> = ({ status }) => {
	return (
		<div
			className={cs(styles.statusDot, {
				[styles.available]: status === ActionsCellStatus.AVAILABLE || status === ChargingCardStatus.ACTIVE || status === UserStatus.ACTIVE,
				[styles.charging]: status === ActionsCellStatus.CHARGING,
				[styles.unavailable]: status === ActionsCellStatus.UNAVAILABLE || status === ChargingCardStatus.DEACTIVATED || status === UserStatus.INACTIVE,
				[styles.occupied]: status === ActionsCellStatus.OCCUPIED,
				[styles.pending]: status === UserStatus.ACTIVATION_PENDING
			})}
		/>
	);
};
