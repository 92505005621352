export enum URL_SEARCH_PARAMS {
	SORT_BY = "sortBy",
	SORT_DIR = "sortDir",
	SORT_DIR_DESC = "desc",
	SORT_DIR_ASC = "asc",
	GLOBAL_SEARCH = "globalSearch",
	SCROLL_POS = "scrollPos",
	PAGE_INDEX = "pageIndex",
	PAGE_SIZE = "pageSize",
	COLLAPSED = "collapsed"
}
