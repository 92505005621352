import { ChargerAddressForm, OwnershipPayload } from "@interface/chargersInterface";
import NotificationService from "@services/notificationService";
import { endpoints } from "./endpoints";
import { GATEWAY_API } from "./http-common";

export const chargePointsApi = {
	getChargePoints: () =>
		GATEWAY_API.get(endpoints.assets.chargePoints).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		),
	getChargePointById: (chargerId) => {
		return GATEWAY_API.get(endpoints.assets.chargePointById(chargerId)).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	},
	getChargePointsStatus: () => {
		return GATEWAY_API.get(endpoints.chargers.chargePointsStatus).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	},
	getChargePointStatusById: (chargerId) => {
		return GATEWAY_API.get(endpoints.chargers.chargePointStatusById(chargerId)).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	},
	assignChargePointGroup: ({ chargerId, chargerGroupId }) => {
		return GATEWAY_API.put(endpoints.assets.assignGroup(chargerId, chargerGroupId)).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	},
	unassignChargePointGroup: ({ chargerId }) => {
		return GATEWAY_API.delete(endpoints.assets.unassignGroup(chargerId)).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	},
	setChargePointOwnership: ({ chargerId, payload }: { chargerId: string, payload: OwnershipPayload }) => {
		return GATEWAY_API.patch(endpoints.assets.setOwnership(chargerId), payload).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	},
	setChargePointAddress: ({ chargerId, payload }: { chargerId: string, payload: ChargerAddressForm }) => {
		return GATEWAY_API.patch(endpoints.assets.setAddress(chargerId), payload).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	},
	getChargePointMetersById: (chargerId) => {
		return GATEWAY_API.get(endpoints.chargers.metersById(chargerId)).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	}
};
