import { endpoints } from "./endpoints";
import { GATEWAY_API } from "./http-common";
import { IdTag, IdTagPayloadWrapper } from "@interface/idTagsInterface";

export const idTagsApi = {
	getIdTags: () => {
		return GATEWAY_API.get(endpoints.assets.idtags).then((response) => response.data);
	},
	getIdTag: (externalId: IdTag["externalId"]) => {
		return GATEWAY_API.get(endpoints.assets.idtag(externalId)).then((response) => response.data);
	},
	createIdTag: ({ idTagPayload }: IdTagPayloadWrapper) => {
		return GATEWAY_API.post(endpoints.assets.idtags, idTagPayload).then((response) => response.data);
	},
	updateIdTag: ({ idTagPayload, currentExternalId }: IdTagPayloadWrapper) => {
		return GATEWAY_API.patch(endpoints.assets.updateIdTag(currentExternalId), idTagPayload).then((response) => response.data);
	}
};
