import { FC } from "react";
import { RfIdIcon } from "@components/Icons/Icons";
import { IdTag } from "@interface/idTagsInterface";
import { ChargingCardInfo } from "@pages/ChargingCardDetailsPage/components/ChargingCardSummary/ChargingCardInfo";
import { ChargingCardActions } from "@pages/ChargingCardDetailsPage/components/ChargingCardSummary/ChargingCardActions";
import styles from "./ChargingCardSummary.module.scss";

interface ChargingCardSummaryProps {
	chargingCard: IdTag;
}

export const ChargingCardSummary: FC<ChargingCardSummaryProps> = ({ chargingCard }) => {

	return (
		<div
			className={styles.summary}
			data-testid="charging-card-summary"
		>
			<div className={styles.image}>
				<RfIdIcon />
			</div>

			<ChargingCardInfo chargingCard={chargingCard} />
			<ChargingCardActions chargingCard={chargingCard} />
		</div>
	);
};
