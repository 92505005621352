import cs from "classnames";
import { FC } from "react";
import { ColumnInstance } from "react-table";
import { CaretDownIcon, CaretUpIcon } from "@app/components/Icons/Icons";
import styles from "./Sort.module.scss";

interface SortProps {
	column: ColumnInstance;
}

export const Sort: FC<SortProps> = ({ column }) => {
	if (column.disableSortBy) {
		return null;
	}
	return (
		<div
			className={cs(styles.Container, {
				[styles.asc]: column.isSorted && !column.isSortedDesc,
				[styles.desc]: column.isSorted && column.isSortedDesc
			})}
		>
			<CaretUpIcon/>
			<CaretDownIcon />
		</div>
	);
};
