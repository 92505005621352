import { FC } from "react";
import { Route, RouteProps } from "react-router-dom";
import { NotAuthorised } from "@pages/NotAuthorised/NotAuthorised";
import { useSelector } from "react-redux";
import { getUser } from "@reducers/userReducer";

export const PrivateRoute: FC<RouteProps> = (props) => {
	const user = useSelector(getUser);
	if (!user) {
		return <Route component={NotAuthorised} />;
	}
	return <Route {...props} />;
};
