import { FC } from "react";
import { Modal } from "@components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { CreateAccount } from "@modals/AddUserModal/CreateAccount";
import { useMutation } from "react-query";
import { usersApi } from "@app/api/usersApi";
import NotificationService from "@app/services/notificationService";
import queryClient from "../../../queryClient";

interface AddUserModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddUserModal: FC<AddUserModalProps> = ({ isOpen, onClose }) => {
	const { t } = useTranslation();

	const { mutate, isLoading } = useMutation(usersApi.createUser, {
		onSuccess: () => {
			NotificationService.displaySuccess("User added");
			queryClient.refetchQueries("users");
			onClose();
		},
		onError: () => {
			NotificationService.displayError("Something goes wrong");
		}
	});
	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			Header={t("usersTable.createAccount")}
			showLoader={isLoading}
			Content={
				<CreateAccount sendForm={mutate} />
			}
		/>
	);
};
