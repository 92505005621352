import { FC, ReactElement } from "react";
import styles from "./Legend.module.scss";

interface LegendElement {
	label: string;
	Component: ReactElement;
}
interface LegendProps {
	elements: LegendElement[];
}

export const Legend: FC<LegendProps> = ({ elements }) => {
	return (
		<ul className={styles.container}>
			{elements.map(({ label, Component }, index) => (
				<li key={index} className={styles.listElement}>
					{Component}
					{label}
				</li>
			))}
		</ul>
	);
};
