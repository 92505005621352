import { UserPayloadWrapper } from "@app/interface/userInterface";
import { Button } from "@components/Button/Button";
import { ErrorLabel } from "@components/Form/ErrorLabel/ErrorLabel";
import { TextField } from "@components/Form/TextField/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from "i18next";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import styles from "./AddUserModal.module.scss";

const findRequiredErrorMessage = () => {
	return i18n.t("common.required");
};

const schema = yup
	.object({
		email: yup.string().email(() => i18n.t("loginPage.incorrectEmail")).required(findRequiredErrorMessage),
		firstName: yup.string().required(findRequiredErrorMessage),
		lastName: yup.string().required(findRequiredErrorMessage)
	})
	.required();

export const CreateAccount: FC<{ sendForm: (createUserPayload: UserPayloadWrapper) => void }> = ({ sendForm }) => {
	const { t } = useTranslation();

	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: "",
			firstName: "",
			lastName: ""
		},
		resolver: yupResolver(schema)
	});

	const isLoading = false;

	const onSubmit = (values) => {
		sendForm(values);
	};

	return (
		<div className={styles.container}>
			<form onSubmit={handleSubmit(onSubmit)} data-testid="create-user-form">
				<div className={styles.formContainer}>
					<div className={styles.row}>
						<Controller
							name="email"
							control={control}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<>
									<TextField
										type="text"
										label={t("loginPage.email")}
										placeholder={t("loginPage.email")}
										onChange={onChange}
										onBlur={onBlur}
										value={value}
										autoFocus
										data-testid="email-field"
									/>
									{error && <div className={styles.errorContainer}><ErrorLabel error={error.message} /></div>}
								</>
							)}
						/>
					</div>
					<div className={styles.row}>
						<Controller
							name="firstName"
							control={control}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<>
									<TextField
										label={t("loginPage.firstName")}
										placeholder={t("loginPage.firstName")}
										onChange={onChange}
										onBlur={onBlur}
										value={value}
										data-testid="firstname-field"
									/>
									{error && <div className={styles.errorContainer}><ErrorLabel error={error.message} /></div>}
								</>
							)}
						/>
					</div>
					<div className={styles.row}>
						<Controller
							name="lastName"
							control={control}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<>
									<TextField
										label={t("loginPage.lastName")}
										placeholder={t("loginPage.lastName")}
										onChange={onChange}
										onBlur={onBlur}
										value={value}
										data-testid="lastname-field"
									/>
									{error && <div className={styles.errorContainer}><ErrorLabel error={error.message} /></div>}
								</>
							)}
						/>
					</div>

					<footer className={styles.footer}>
						<Button color="primary" buttonType="solid" disabled={isLoading}>
							{t("loginPage.createAccount")}
						</Button>
					</footer>
				</div>
			</form>
		</div>
	);
};
