import { FC, useEffect, useRef } from "react";
import cs from "classnames";
import { KEY_CODES } from "@interface/keyCodesInterface";
import { CloseIcon, PenIcon, TickIcon } from "@app/components/Icons/Icons";
import styles from "../GroupTags.module.scss";

interface GroupInputProps {
	value: string;
	isEditing?: boolean;
	isDisabled?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	groupAccept?: () => void;
	handleEdit: () => void;
	handleRemove: () => void;
}
const ICONS_SIZE = 15;

export const GroupInput: FC<GroupInputProps> = ({
	value,
	isEditing,
	isDisabled,
	onChange,
	groupAccept,
	handleEdit,
	handleRemove
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [isEditing]);

	return (
		<>
			<input
				value={value}
				size={value?.length || 1}
				onChange={onChange}
				disabled={!isEditing}
				onKeyDown={(e) => e.key === KEY_CODES.ENTER && groupAccept && groupAccept()}
				ref={inputRef}
				data-testid="group-input"
				maxLength={15}
			/>
			<div className={styles.actions}>
				{isEditing && (
					<>
						<div
							className={styles.action}
							data-testid="approve-group"
							onClick={() => groupAccept && groupAccept()}
						>
							<TickIcon width={ICONS_SIZE} height={ICONS_SIZE} />
						</div>
						<div className={cs(styles.action)} onClick={handleRemove} data-testid="remove-group-icon">
							<CloseIcon width={ICONS_SIZE} height={ICONS_SIZE} />
						</div>
					</>
				)}
				{!isEditing && (
					<>
						<div
							className={cs(styles.action, {
								[styles.actionDisabled]: isDisabled
							})}
							onClick={() => !isDisabled && handleEdit()}
							data-testid="edit-group-icon"
						>
							<PenIcon width={ICONS_SIZE} height={ICONS_SIZE} />
						</div>
						<div
							className={cs(styles.action, {
								[styles.actionDisabled]: isDisabled
							})}
							onClick={() => !isDisabled && handleRemove()}
							data-testid="remove-group-icon"
						>
							<CloseIcon width={ICONS_SIZE} height={ICONS_SIZE} />
						</div>
					</>
				)}
			</div>
		</>
	);
};
