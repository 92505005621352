import { FC } from "react";
import { Layout } from "@components/Layout/Layout";
import { ChargingCardsTable } from "./components/ChargingCardsTable/ChargingCardsTable";

export const ChargingCardsPage: FC = () => {
	return (
		<Layout>
			<ChargingCardsTable />
		</Layout>
	);
};
