import { QueryObserver } from "react-query";
import i18n from "i18next";
import { chargePointsApi } from "@app/api/chargePointsApi";
import config from "@app/config/config";
import { ChargePoint, Connector } from "@app/interface/chargersInterface";
import { BackendChargeStatus, ConnectorStatus } from "@app/interface/chargerStatusInterface";
import NotificationService from "@app/services/notificationService";
import { RootState, store } from "@app/store";
import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import queryClient from "../../queryClient";

export interface AddProgressingChargerpointPayload {
	timestamp: number;
	chargerPointId: ChargePoint["externalId"];
	chargerPointStatus: ChargePoint["status"];
	connectorId: Connector["connectorId"];
	connectorStatus: Connector["status"]
	successStatus: (ConnectorStatus | undefined)[];
}

export interface RestartingObject {
	id: ChargePoint["id"];
	timestamp: number;
}
export interface ChargerPointsStatus {
	restarting: RestartingObject[];
	progressingConnectors: AddProgressingChargerpointPayload[];
}

const initialState: ChargerPointsStatus = {
	restarting: [],
	progressingConnectors: []
};
export const observer = new QueryObserver<BackendChargeStatus[]>(queryClient, {
	queryKey: "chargePointsStatus", queryFn: () => chargePointsApi.getChargePointsStatus(),
	refetchInterval: config.refetchServerDataInterval
});

export const syncListeners = (dispatch: Dispatch) => {
	if (!observer.hasListeners()) {
		const unsubscribe = observer.subscribe((result) => {
			const { data } = result;
			if (store.getState().chargerPoints.progressingConnectors.length === 0) {
				setTimeout(() => unsubscribe());
			}
			store.getState().chargerPoints.progressingConnectors.forEach(progressingConnector => {
				const _connector = data?.find(statuses => statuses.chargerId === progressingConnector.chargerPointId)?.connectorStatuses
					?.find(connectorStatus => connectorStatus.connectorId === progressingConnector.connectorId);
				if (Date.now() - progressingConnector.timestamp > config.chargerpointStatusUpdateTimeout) {
					if (!progressingConnector.successStatus.includes(_connector?.status)) {
						NotificationService.displayError(i18n.t("messages.errorStatusChange"));
					}
					dispatch(removeProgressingChargepoint({
						chargerPointId: progressingConnector.chargerPointId,
						connectorId: progressingConnector.connectorId
					}));
				}
			});

		});
	}
};

export const resettingChargerpointCleanup = (dispatch: Dispatch, chargerPointId: ChargePoint["id"]) => {
	dispatch(removeRestartingChargepoint(chargerPointId));
	queryClient.refetchQueries("activeChargerPointStatus");
	queryClient.refetchQueries(["activeCharger", chargerPointId]);
};

export const resetChargerPoint = createAsyncThunk(
	"chargerPointsReducer/addRestartingChargerpoint",
	async (chargerPointId: string, { dispatch }) => {
		dispatch(addRestartingChargerpoint(chargerPointId));
		setTimeout(() => resettingChargerpointCleanup(dispatch, chargerPointId), config.resetingChargerpointTimeout);
	}
);
export const initResetingChargerpoints = createAsyncThunk(
	"chargerPointsReducer/init",
	async (_, { dispatch, getState }) => {
		const state = getState() as RootState;

		state.chargerPoints.restarting.forEach(({ id, timestamp }) => {
			const diff = Date.now() - timestamp;
			const time = config.resetingChargerpointTimeout;
			if (diff < time) {
				setTimeout(() => resettingChargerpointCleanup(dispatch, id), time - diff);
			} else {
				dispatch(removeRestartingChargepoint(id));
			}
		});

		if (state.chargerPoints.progressingConnectors.length > 0) {
			syncListeners(dispatch);
		}

	}
);
export const addProgressingConnector = createAsyncThunk(
	"chargerPointsReducer/init",
	async (params: { chargerpoint: ChargePoint, connector: Connector, successStatus: ConnectorStatus[] }, { dispatch }) => {

		const { chargerpoint, connector, successStatus } = params;

		const progressingConnector = {
			timestamp: Date.now(),
			chargerPointId: chargerpoint.externalId,
			chargerPointStatus: chargerpoint.status,
			connectorId: connector.connectorId,
			connectorStatus: connector.status,
			successStatus

		};
		dispatch(addProgressingChargerpoint(progressingConnector));
		syncListeners(dispatch);
	}
);

export const chargerPointsSlice = createSlice({
	name: "chargerPointsReducer",
	initialState,
	reducers: {
		addRestartingChargerpoint: (state, action: PayloadAction<ChargePoint["id"]>) => {
			state.restarting = [
				...state.restarting,
				{
					id: action.payload,
					timestamp: Date.now()
				}
			];
		},
		removeRestartingChargepoint: (state, action: PayloadAction<ChargePoint["id"]>) => {
			state.restarting = state.restarting.filter((chargerpoint) => chargerpoint.id !== action.payload);
		},

		addProgressingChargerpoint: (state, action: PayloadAction<AddProgressingChargerpointPayload>) => {

			const newData = action.payload;
			state.progressingConnectors = [
				...state.progressingConnectors,
				newData
			];
		},
		removeProgressingChargepoint: (state, action: PayloadAction<{ connectorId: Connector["connectorId"], chargerPointId: ChargePoint["id"] }>) => {
			state.progressingConnectors = state.progressingConnectors.filter(
				connector => (connector.chargerPointId === action.payload.chargerPointId && connector.connectorId === action.payload.connectorId) === false
			);
		}
	}
});

export const { addRestartingChargerpoint, removeRestartingChargepoint, addProgressingChargerpoint, removeProgressingChargepoint } = chargerPointsSlice.actions;

export const isChargepointRestarting = (id) => (state: RootState) =>
	!!state.chargerPoints.restarting?.find((chargePoint) => chargePoint.id === id);
export const isConnectorProgressing = (chargerPointId: ChargePoint["id"], connectorId: Connector["connectorId"] | undefined) => (state: RootState) =>
	!!state.chargerPoints.progressingConnectors
		?.find((chargerPoint) => chargerPoint.chargerPointId === chargerPointId && chargerPoint.connectorId === connectorId);

export default chargerPointsSlice.reducer;
