import { FC, HTMLProps } from "react";
import { TickIcon } from "@app/components/Icons/Icons";
import styles from "./Checkbox.module.scss";

interface CheckboxProps extends HTMLInputElement {
	label?: string;
}

export const Checkbox: FC<HTMLProps<CheckboxProps>> = ({ label, ...props }) => (
	<label className={styles.container}>
		<div className={styles.inputContainer}>
			<input className={styles.input} {...props} type="checkbox" data-testid="checkbox-input" />
			<TickIcon />
		</div>
		{label && (
			<div className={styles.label}>
				<span>{label}</span>
			</div>
		)}
	</label>
);
