import { FC } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { idTagsApi } from "@api/idTagsApi";
import { IdTag } from "@interface/idTagsInterface";
import { GroupTypes } from "@interface/groupInterface";
import { GroupSelect } from "@components/GroupSelect/GroupSelect";
import { buildUpdateIdTagPayload } from "@model/idTagsModel";
import IdTagService from "@services/idTagService";
import { IdTagsGroupManagementModal } from "@modals/GroupManagementModal/IdTagsGroupManagementModal";
import styles from "./ChargingCardSummary.module.scss";

interface ChargerInfoProps {
	chargingCard: IdTag;
}

export const ChargingCardInfo: FC<ChargerInfoProps> = ({ chargingCard }) => {
	const { t } = useTranslation();

	const { mutateAsync: updateIdTag, isLoading: isAddChargingCardLoading } = useMutation(idTagsApi.updateIdTag, {
		onSuccess: (updatedIdTag) => {
			IdTagService.updateIdTagQuery(updatedIdTag);
		}
	});

	const onChange = (group) => {
		updateIdTag({
			idTagPayload: buildUpdateIdTagPayload(chargingCard, group),
			currentExternalId: chargingCard.externalId
		});
	};
	return (
		<div className={styles.info}>
			<div className={styles.title}>
				<div className={styles.label}>{chargingCard.externalId}</div>
			</div>
			<div className={styles.chargerGroup}>
				<div className={styles.label}>{t("chargingCardDetails.chargerCardGroup")}:</div>
				<div>
					<GroupSelect
						type={GroupTypes.RFID}
						value={chargingCard.group}
						onChange={onChange}
						isLoading={isAddChargingCardLoading}
						GroupsManagementComponent={IdTagsGroupManagementModal}
					/>
				</div>
			</div>
		</div>
	);
};
