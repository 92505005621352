import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tilesBuilder } from "@pages/ChargingCardDetailsPage/components/ChargingCardDetails/chargingCardTilesBuilder";
import { ChargingCardDetailsTile } from "@pages/ChargingCardDetailsPage/components/ChargingCardDetails/ChargingCardDetailsTile";
import { IdTag } from "@interface/idTagsInterface";
import styles from "./ChargingCardDetails.module.scss";

interface ChargerDetailsProps {
	chargingCard: IdTag;
}

export const ChargingCardDetails: FC<ChargerDetailsProps> = ({ chargingCard }) => {
	const { t } = useTranslation();

	return (
		<div
			className={styles.details}
			data-testid="charging-card-details"
		>
			<>
				<div className={styles.header}><div className={styles.header}>{t("chargingCardDetails.chargingCardDetails")}</div></div>
				<div className={styles.tilesGrid}>
					{tilesBuilder(chargingCard).map((tile, index) => {
						return (
							<ChargingCardDetailsTile key={index} {...tile} />
						);
					})}
				</div>
			</>
		</div>
	);
};
