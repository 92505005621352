import { GATEWAY_API } from "./http-common";
import { CentralSystemAction } from "@interface/centralSystemInterface";
import { endpoints } from "./endpoints";

export const centralSystemApi = {
	triggerCentralSystemAction: (chargerId, action: CentralSystemAction, connectorId) =>
		GATEWAY_API.post(endpoints.centralSystem.triggerAction, { chargerId, action, connectorId })
			.then((response) => response.data),

	clearChargingProfile: ({ chargerId, action: CentralSystemAction, connectorId }) =>
		GATEWAY_API.post(endpoints.centralSystem.clearChargingProfile,
			{ chargerId, action: CentralSystemAction, connectorId, chargingPurposeType: "TX_PROFILE" })
			.then((response) => response.data)
};
