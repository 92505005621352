import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { chargePointsApi } from "@api/chargePointsApi";
import config from "@app/config/config";
import { Container } from "@components/Container/Container";
import { Layout } from "@components/Layout/Layout";
import { parseChargePoint } from "@model/chargersModel";
import { ChargerDetails } from "@pages/ChargerDetailsPage/components/ChargerDetails/ChargerDetails";
import { ChargerNavigation } from "@pages/ChargerDetailsPage/components/ChargerNavigation/ChargerNavigation";
import { ChargerSummary } from "@pages/ChargerDetailsPage/components/ChargerSummary/ChargerSummary";
import { TableLoader } from "@app/components/Table/components/TableLoader/TableLoader";

export const ChargerDetailsPage = () => {
	const param: { chargerId: string } = useParams();

	const { data: statusData, isLoading: isStatusLoading } = useQuery(
		["chargePointsStatus", param.chargerId],
		() => chargePointsApi.getChargePointStatusById(param.chargerId),
		{
			refetchInterval: config.refetchServerDataInterval
		}
	);

	const { data: charger, isLoading } = useQuery(
		["activeCharger", param.chargerId],
		() => chargePointsApi.getChargePointById(param.chargerId),
		{
			select: (_data) => parseChargePoint(_data, statusData),
			enabled: !!param.chargerId && !!statusData
		}
	);

	const showLoader = () => {
		return (
			<TableLoader />
		);
	};

	return (
		<Layout>
			<Container>
				{isLoading || (isStatusLoading && showLoader())}
				{charger && (
					<div>
						<ChargerNavigation />
						<ChargerSummary charger={charger} />
						<ChargerDetails charger={charger} />
					</div>
				)}
			</Container>
		</Layout>
	);
};
