import { LANGUAGES } from "@app/interface/languageInterface";
import { RootState } from "@app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
	language: string;
}

const initialState: AppState = {
	language: LANGUAGES.ENGLISH
};

export const appSlice = createSlice({
	name: "appReducer",
	initialState,
	reducers: {
		setLanguage: (state, action: PayloadAction<string>) => {
			state.language = action.payload;
		}
	}
});

export const { setLanguage } = appSlice.actions;

export const getLanguage = (state: RootState) => state.app.language;

export default appSlice.reducer;
