import { FC } from "react";
import cs from "classnames";
import { ChargePoint } from "@interface/chargersInterface";
import { ChargerRestart } from "@pages/ChargerDetailsPage/components/ChargerRestart/ChargerRestart";
import { tilesBuilder } from "@pages/ChargerDetailsPage/components/ChargerDetails/chargerTilesBuilder";
import { ChargerDetailsTile } from "@pages/ChargerDetailsPage/components/ChargerDetails/ChargerDetailsTile";
import styles from "./ChargerDetails.module.scss";

interface ChargerDetailsProps {
	charger: ChargePoint;
}

export const ChargerDetails: FC<ChargerDetailsProps> = ({ charger }) => {

	return (
		<div className={styles.details} data-testid="charger-details">
			{charger && (
				<>
					<div className={styles.header}>Charger details</div>
					<div className={styles.tilesGrid}>
						{tilesBuilder(charger).map((tile, index) => {
							return <ChargerDetailsTile key={index} {...tile} />;
						})}
						<div className={cs(styles.tile, styles.button)}>
							<ChargerRestart
								chargerId={charger.externalId}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
