import { Layout } from "@components/Layout/Layout";
import { UsersTable } from "./components/UsersTable/UsersTable";

export const UsersPage = () => {
	return (
		<Layout>
			<UsersTable />
		</Layout>
	);
};
