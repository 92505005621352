import { GATEWAY_API } from "./http-common";
import { endpoints } from "./endpoints";
import NotificationService from "@services/notificationService";
import { SchedulePayload, SchedulePreferenceType } from "@interface/chargerScheduleInterface";

export const schedulerApi = {
	getScheduleSettings: () =>
		GATEWAY_API.get(endpoints.scheduler.scheduleSettings).then(
			(response) => response.data.filter(schedule => schedule.schedulePreferenceType === SchedulePreferenceType.DAILY),
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		),
	postScheduleSettings: (payload: SchedulePayload) => GATEWAY_API.post(endpoints.scheduler.scheduleSettings, payload),
	deleteScheduleSettingsById: ({ chargerId, connectorId }) => {
		return GATEWAY_API.delete(endpoints.scheduler.deleteScheduleSettingsById(chargerId, connectorId, SchedulePreferenceType.DAILY)).then(
			(response) => response.data,
			(error: Error) => {
				NotificationService.displayError(error.message);
			}
		);
	}
};
