import { FC } from "react";
import { Route as PublicRoute, Switch } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import { NotFoundPage } from "@pages/NotFoundPage/NotFoundPage";
import { ConfirmAccountPage } from "@pages/ConfirmAccountPage/ConfirmAccount";
import { LoginPage } from "@pages/LoginPage/LoginPage";
import { ChargersPage } from "@pages/ChargersPage/ChargersPage";
import { ChargingCardsPage } from "@pages/ChargingCards/ChargingCardsPage";
import { HomePage } from "@pages/HomePage/HomePage";
import { ChargerDetailsPage } from "@pages/ChargerDetailsPage/ChargerDetailsPage";
import { ChargingCardDetailsPage } from "@pages/ChargingCardDetailsPage/ChargingCardDetailsPage";
import { UsersPage } from "@pages/UsersPage/UsersPage";
import { UserDetailsPage } from "@pages/UserDetailsPage/UserDetailsPage";
import { Route } from "@components/Layout/components/Navigation/Navigation";
import { UserChargersPage } from "@pages/UserChargersPage/UserChargersPage";

export const Routing: FC = () => (
	<Switch>
		<PublicRoute path={Route.CONFIRM_ACCOUNT} component={ConfirmAccountPage} />
		<PublicRoute path={Route.LOGIN} component={LoginPage} />
		<PrivateRoute path={Route.CHARGERS} component={ChargersPage} />
		<PrivateRoute path={`${Route.CHARGER_DETAILS}/:chargerId`} component={ChargerDetailsPage} />
		<PrivateRoute path={Route.CHARGING_CARDS} component={ChargingCardsPage} />
		<PrivateRoute path={`${Route.CHARGING_CARD_DETAILS}/:cardId`} component={ChargingCardDetailsPage} />
		<PrivateRoute path={Route.USERS} component={UsersPage} />
		<PrivateRoute path={`${Route.USER_DETAILS}/:userId`} component={UserDetailsPage} />
		<PrivateRoute path={Route.USER_CHARGERS} component={UserChargersPage} />
		<PrivateRoute path={Route.HOME} component={HomePage} exact />
		<PublicRoute path="*" component={NotFoundPage} />
	</Switch>
);
